/*! Pagination */

.Pagination {
  display: flex;
  justify-content: center;
}

.Pagination-item {
  width: rem-calc(40);
  min-width: rem-calc(40);

  &--previous,
  &--next {
    border-radius: 50%;
    overflow: hidden;

    .Pagination-link {
      @include sprite('arrow-small', (fill: $color-white));
      background-color: map-get($color-main, 'darkblue');
      background-size: rem-calc(11 9);
      transition: background-color .3s ease(out-quint);

      @include breakpoint(large) {
        &:hover, #{$keyboard} &:focus {
          background-color: map-get($color-main, 'brick');
        }
      }
    }
  }

  &--previous {
    margin-right: rem-calc(15);

    .Pagination-link {
      transform: rotate(180deg);
    }
  }

  &--next {
    margin-left: rem-calc(15);
  }

  &--inactive {
    .Pagination-link {
      background-color: rgba(map-get($color-main, 'darkblue'), .2) !important;
      cursor: default;
    }
  }

  &--current {
    .Pagination-page {
      font-weight: 800;
      color: map-get($color-main, 'brick');
      box-shadow: inset rem-calc(0 -5 0 0) map-get($color-main, 'brick');
    }
  }
}

.Pagination-link,
.Pagination-page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem-calc(40);
  height: rem-calc(40);
  color: map-get($color-main, 'darkblue');
  transition: color .2s ease(out-quint);
}

.Pagination-link.Pagination-link {
  @include breakpoint(large) {
    &:hover, #{$keyboard} &:focus {
      color: map-get($color-main, 'brick')
    }
  }
}
