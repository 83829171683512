$sprites: (
    'angle': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 145.35 94.22'%3e%3cpath fill='___fill___' d='M73.46 94.22L0 21.63 21.38 0 73.2 51.23 123.73.13l21.62 21.37-71.89 72.72z'/%3e%3c/svg%3e",
    'arrow-small': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 217.34 169.26'%3e%3cpath fill='___fill___' d='M211.16 70L147.57 6.18A20.71 20.71 0 00118.28 6l-.22.22a20.71 20.71 0 00-.22 29.28l.22.22 28.13 28.15H20.82a20.82 20.82 0 100 41.63h125.37l-28.13 28.11a20.93 20.93 0 0014.64 35.69 22.3 22.3 0 0014.87-6.16l63.59-63.82a20.44 20.44 0 00.38-28.91z'/%3e%3c/svg%3e",
    'arrow': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 189.53 161.92'%3e%3cpath fill='___fill___' d='M189.52 80.92a13.63 13.63 0 00-3.28-8.36l-64.8-68.84a13.9 13.9 0 00-17.72-1 12.66 12.66 0 00.13 17.72l45.56 48.35H12.15a12.15 12.15 0 100 24.29H149.4l-45.56 48.35a13.37 13.37 0 00-.13 17.72 13.73 13.73 0 0017.72-1l64.85-68.92a11.71 11.71 0 003.24-8.31z'/%3e%3c/svg%3e",
    'check': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 426.67 324.47'%3e%3cpath fill='___fill___' d='M421.88 5.21a17.07 17.07 0 00-24.14-.42q-.21.2-.42.42L119.26 283.28 28.92 192.9a17.077 17.077 0 00-24.13 24.17l102.4 102.4a17.05 17.05 0 0024.13 0L421.46 29.34a17.07 17.07 0 00.42-24.13z'/%3e%3c/svg%3e",
    'cross-thin': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17.93 17.95'%3e%3cpath fill='___fill___' d='M10.49 9l7.14-7.13A1.08 1.08 0 0016.11.31L9 7.45 1.84.32A1.07 1.07 0 00.32 1.84L7.45 9 .32 16.11a1.07 1.07 0 000 1.52 1.07 1.07 0 001.52 0L9 10.49l7.14 7.14a1.08 1.08 0 001.52-1.52z'/%3e%3c/svg%3e",
    'cross': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13.95 13.95'%3e%3cpath fill='___fill___' d='M14 11.82L9.1 7 14 2.13 11.82 0 7 4.85 2.13 0 0 2.13 4.85 7 0 11.82 2.13 14 7 9.1l4.82 4.9z'/%3e%3c/svg%3e",
    'external-link': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cg fill='___fill___'%3e%3cpath d='M12.8 7.99a.8.8 0 00-.8.8v4.8a.8.8 0 01-.8.8H2.4a.8.8 0 01-.8-.8V4.8a.8.8 0 01.8-.8h4.8a.8.8 0 000-1.6H2.4A2.41 2.41 0 00-.01 4.8v8.8a2.41 2.41 0 002.41 2.39h8.8a2.41 2.41 0 002.4-2.4V8.8a.8.8 0 00-.8-.81z'/%3e%3cpath d='M15.99.49a.78.78 0 00-.16-.24.78.78 0 00-.24-.16.85.85 0 00-.39-.1h-4.8a.8.8 0 000 1.6h2.87l-7.44 7.4a.81.81 0 000 1.14.85.85 0 00.57.23.83.83 0 00.59-.19l7.43-7.44V5.6a.8.8 0 101.6 0V.8a.85.85 0 00-.03-.31z'/%3e%3c/g%3e%3c/svg%3e",
    'faq': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.46 23.46'%3e%3cg fill='___fill___'%3e%3cpath d='M11.73 17a1 1 0 101 1 1 1 0 00-1-1z'/%3e%3cpath d='M11.73 0a11.73 11.73 0 000 23.46 11.73 11.73 0 000-23.46zm10.41 11.73A10.42 10.42 0 1111.73 1.31a10.43 10.43 0 0110.41 10.42z'/%3e%3cpath d='M11.83 4.92h-.1a4 4 0 00-4 3.64.63.63 0 00.11.44.59.59 0 00.42.21h.1a.7.7 0 00.64-.6 2.73 2.73 0 014.88-1.3 2.76 2.76 0 01.54 2 2.83 2.83 0 01-.78 1.58l-.27.23-.08.07A5.08 5.08 0 0011.08 15a.61.61 0 00.16.45.58.58 0 00.43.2h.08a.65.65 0 00.64-.61 3.83 3.83 0 011.73-2.82c.16-.13.31-.26.47-.41a4.06 4.06 0 00-2.76-6.91z'/%3e%3c/g%3e%3c/svg%3e",
    'file-default': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18.03 24'%3e%3cg fill='___fill___'%3e%3cpath d='M13.75 5.5h3.6A37.88 37.88 0 0013 .52v4.07a.86.86 0 00.79.91'/%3e%3cpath d='M18 6.78h-4.25a2.05 2.05 0 01-1.91-2.19V0H1.36A1.46 1.46 0 000 1.55v20.96A1.46 1.46 0 001.36 24h15.32A1.46 1.46 0 0018 22.45zm-8.16 13.9h-6.9v-1.34h6.9zm5.25-2.94H2.94V16.4h12.15zm0-2.94H2.94v-1.35h12.15zm0-3H2.94v-1.29h12.15z'/%3e%3c/g%3e%3c/svg%3e",
    'file-doc': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20.7 24'%3e%3cg fill='___fill___'%3e%3cpath d='M.47 13.19a9.83 9.83 0 011.53-.1 3.25 3.25 0 012.05.53 2.22 2.22 0 01.84 1.88 2.56 2.56 0 01-.83 2 3.58 3.58 0 01-2.31.64 9.15 9.15 0 01-1.28-.04zm1.14 4.08a1.7 1.7 0 00.39 0 1.55 1.55 0 001.7-1.37 1.45 1.45 0 000-.36A1.42 1.42 0 002.42 14a1 1 0 00-.3 0 3 3 0 00-.52 0zM10.21 15.57a2.38 2.38 0 01-2.12 2.62 1.82 1.82 0 01-.33 0 2.33 2.33 0 01-2.37-2.3 1.93 1.93 0 010-.24 2.42 2.42 0 012.22-2.61h.22a2.33 2.33 0 012.38 2.28 2.09 2.09 0 010 .25m-3.61.07c0 1 .46 1.67 1.22 1.67S9 16.58 9 15.61s-.43-1.67-1.21-1.67-1.21.73-1.21 1.7M14.62 18a3.29 3.29 0 01-1.28.22 2.39 2.39 0 01-2.61-2.12 2.44 2.44 0 010-.38A2.55 2.55 0 0113.15 13h.3a2.89 2.89 0 011.23.23l-.23.9a2.37 2.37 0 00-.95-.13 1.65 1.65 0 000 3.29 2.74 2.74 0 00.94-.17zM13 4.59a.86.86 0 00.79.91h3.6A37.88 37.88 0 0013 .52'/%3e%3cpath d='M18 9.88v-3.1h-4.25a2.06 2.06 0 01-1.91-2.2V0H1.36A1.47 1.47 0 000 1.55v9.32h19.7v9.31H0v2.33A1.46 1.46 0 001.36 24h15.32A1.46 1.46 0 0018 22.45v-1.28h2.7V9.88z'/%3e%3c/g%3e%3c/svg%3e",
    'file-image': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20.7 15.45'%3e%3cpath fill='___fill___' d='M18.73 0H2a2 2 0 00-2 2v11.48a2 2 0 002 2h16.73a2 2 0 002-2V2a2 2 0 00-2-2zm-14 2.86A2.15 2.15 0 112.56 5a2.15 2.15 0 012.15-2.14zM2.89 13.77l5-4.87a.29.29 0 01.35 0l2 1.25-3.47 3.62zM19 13.48a.29.29 0 01-.28.29H9.09l5.3-5.55a.28.28 0 01.4 0L19 12.15z'/%3e%3c/svg%3e",
    'file-pdf': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20.7 24'%3e%3cg fill='___fill___'%3e%3cpath d='M.47 13.19a8.74 8.74 0 011.53-.1 2.44 2.44 0 011.53.4 1.47 1.47 0 01.53 1.16 1.57 1.57 0 01-.46 1.18 2.29 2.29 0 01-1.6.53 2.55 2.55 0 01-.39 0v1.79H.47zm1.12 2.27a2.34 2.34 0 00.38 0c.6 0 1-.3 1-.82s-.32-.73-.88-.73a2.42 2.42 0 00-.47 0zM4.78 13.19a9.83 9.83 0 011.53-.1 3.25 3.25 0 012.05.53 2.22 2.22 0 01.84 1.89 2.54 2.54 0 01-.83 2 3.59 3.59 0 01-2.31.65 9.17 9.17 0 01-1.28-.08v-4.89zm1.14 4.08a1.7 1.7 0 00.39 0A1.55 1.55 0 008 15.9a1.45 1.45 0 000-.36A1.42 1.42 0 006.73 14a1 1 0 00-.3 0 2.24 2.24 0 00-.52.05zM9.94 13.12H13v.93h-1.92v1.14h1.79v.92h-1.79v2H9.94v-5zM13.75 5.49h3.6A38.44 38.44 0 0013 .52v4.07a.84.84 0 00.75.9z'/%3e%3cpath d='M18 9.88v-3.1h-4.25a2.07 2.07 0 01-1.92-2.2V0H1.36A1.47 1.47 0 000 1.55v9.32h19.7v9.31H0v2.33A1.44 1.44 0 001.36 24h15.32A1.46 1.46 0 0018 22.45v-1.28h2.7V9.88z'/%3e%3c/g%3e%3c/svg%3e",
    'file-ppt': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20.7 24'%3e%3cg fill='___fill___'%3e%3cpath d='M.47 13.19a9.83 9.83 0 011.53-.1 2.44 2.44 0 011.53.4 1.47 1.47 0 01.53 1.16 1.61 1.61 0 01-.46 1.19 2.32 2.32 0 01-1.6.52 2.55 2.55 0 01-.39 0v1.79H.47zm1.12 2.27a2.34 2.34 0 00.38 0c.6 0 1-.3 1-.81s-.32-.74-.88-.74a1.83 1.83 0 00-.47.05zM4.78 13.19a9.83 9.83 0 011.53-.1 2.44 2.44 0 011.53.4 1.47 1.47 0 01.53 1.16 1.61 1.61 0 01-.46 1.19 2.32 2.32 0 01-1.62.52 2.55 2.55 0 01-.39 0v1.79H4.78zm1.12 2.27a2.34 2.34 0 00.38 0c.6 0 1-.3 1-.81s-.32-.74-.88-.74a1.83 1.83 0 00-.5.09zM10.05 14.07H8.71v-1h3.84v1h-1.36v4.05h-1.14zM13.75 5.5h3.6A37.88 37.88 0 0013 .52v4.07a.86.86 0 00.79.91'/%3e%3cpath d='M18 9.88v-3.1h-4.25a2.05 2.05 0 01-1.91-2.2V0H1.36A1.47 1.47 0 000 1.55v9.32h19.7v9.31H0v2.33A1.46 1.46 0 001.36 24h15.32A1.46 1.46 0 0018 22.45v-1.28h2.7V9.88z'/%3e%3c/g%3e%3c/svg%3e",
    'file-video': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 21.17 17.58'%3e%3cpath fill='___fill___' d='M19.05 0H2.12A2.12 2.12 0 000 2.12v13.34a2.12 2.12 0 002.12 2.12h16.93a2.12 2.12 0 002.12-2.12V2.12A2.12 2.12 0 0019.05 0zM7.31 4A.71.71 0 018 3.29a.74.74 0 01.35.1L14 6.65a.7.7 0 01.26 1 .65.65 0 01-.26.26l-5.64 3.23a.7.7 0 01-1-.26.73.73 0 01-.09-.35zm12.45 11.46a.71.71 0 01-.71.71H2.12a.71.71 0 01-.71-.71v-1h18.35z'/%3e%3c/svg%3e",
    'file-xls': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20.7 24'%3e%3cg fill='___fill___'%3e%3cpath d='M3.1 18.12l-.45-.9c-.19-.35-.3-.61-.45-.9-.1.29-.23.55-.38.9l-.42.9H.1l1.45-2.53-1.4-2.47h1.3l.44.92c.15.3.26.54.38.83.12-.32.22-.54.34-.83l.43-.92h1.29l-1.4 2.44 1.48 2.56zM5 13.12h1.15v4.05h2v.95H5zM8.85 17a2.82 2.82 0 001.25.31c.52 0 .79-.22.79-.54s-.23-.49-.83-.7c-.84-.29-1.38-.75-1.38-1.48s.72-1.5 1.89-1.5a3 3 0 011.28.25l-.25.91a2.41 2.41 0 00-1-.24c-.49 0-.72.22-.72.48s.28.46.92.7c.88.33 1.3.79 1.3 1.5s-.64 1.55-2 1.55a3.43 3.43 0 01-1.41-.3zM15.44 18.12l-.45-.9c-.18-.35-.3-.61-.44-.9-.1.29-.23.55-.38.9l-.42.9h-1.31l1.45-2.53-1.39-2.47h1.3l.43.92c.15.3.26.54.38.83.11-.32.21-.54.34-.83l.42-.92h1.29l-1.41 2.44 1.48 2.56zM13.75 5.5h3.6A37.88 37.88 0 0013 .52v4.07a.86.86 0 00.79.91'/%3e%3cpath d='M18 9.88v-3.1h-4.25a2.05 2.05 0 01-1.91-2.2V0H1.36A1.47 1.47 0 000 1.55v9.32h19.7v9.31H0v2.33A1.46 1.46 0 001.36 24h15.32A1.46 1.46 0 0018 22.45v-1.28h2.7V9.88z'/%3e%3c/g%3e%3c/svg%3e",
    'gauge': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 385 385'%3e%3ccircle cx='192.5' cy='192.5' r='180' fill='none' stroke='currentColor' stroke-miterlimit='10' stroke-width='25'/%3e%3c/svg%3e",
    'glossary': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22.45 23.92'%3e%3cpath fill='___fill___' d='M21.88 4.43H19.4a6.66 6.66 0 01-2-.36 10.51 10.51 0 00-3.06-.53 6.57 6.57 0 00-3.14 1.27 11.83 11.83 0 00-2.75-3.4A12.54 12.54 0 003.8 0a.58.58 0 00-.62.5v4H.57a.57.57 0 00-.57.61v17.4a.57.57 0 00.57.56 18.53 18.53 0 005.56-.61 6.3 6.3 0 012.77-.06 5.05 5.05 0 011.6 1.21.61.61 0 00.53.31.59.59 0 00.4-.16 5.07 5.07 0 012.91-1.39 15.11 15.11 0 012.77.36 13.41 13.41 0 002.29.3h2.24a.57.57 0 00.57-.56L22.45 5a.57.57 0 00-.57-.57zM11.6 5.93a6.62 6.62 0 012.73-1.26 9.49 9.49 0 012.74.47 7.78 7.78 0 002.3.41h1.93l-.21 16.35h-1.67a13.22 13.22 0 01-2.07-.29 16.75 16.75 0 00-3-.38 5.34 5.34 0 00-2.75.94zm2.75-1.38zM3.65 18.39a13.61 13.61 0 013.79 1.25 7 7 0 011.91 1.73 6.79 6.79 0 00-3.55 0 16.94 16.94 0 01-4.64.55V5.66h2v12.16a.57.57 0 00.49.57zM10.47 5.8V21A8.41 8.41 0 008 18.65a14 14 0 00-3.7-1.32V1.22a10 10 0 013.53 1.14 12.63 12.63 0 012.64 3.44z'/%3e%3c/svg%3e",
    'phone': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 26.14'%3e%3cpath fill='___fill___' d='M7 15.28c-2-3.51-2.9-5.48-.24-6.62 2.35-1 3-2.21 1.72-5.58C7.27 0 6.7-.83 4.1.88c-5.52 3.63-5.17 8.77-.74 16.45S12.12 28 18 25c2.77-1.39 2.36-2.3.27-4.9-2.25-2.81-3.63-2.83-5.68-1.29-2.31 1.73-3.57 0-5.6-3.54z'/%3e%3c/svg%3e",
    'play': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 217.63 254.61'%3e%3cpath fill='___fill___' d='M217.63 127.31L0 0v254.61l217.63-127.3z'/%3e%3c/svg%3e",
    'playlist': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27.94 21.06'%3e%3cg fill='___fill___'%3e%3cpath d='M21.29 21.06H1.5a1.5 1.5 0 01-1.5-1.5V6.65a1.5 1.5 0 011.5-1.5h19.79a1.5 1.5 0 011.5 1.5v12.91a1.5 1.5 0 01-1.5 1.5zM1.5 6.15a.5.5 0 00-.5.5v12.91a.5.5 0 00.5.5h19.79a.5.5 0 00.5-.5V6.65a.5.5 0 00-.5-.5z'/%3e%3cpath d='M24.87 17.85a.5.5 0 01-.5-.5V3.71a.13.13 0 00-.14-.13H3.71a.5.5 0 01-.5-.5.5.5 0 01.5-.5h20.52a1.13 1.13 0 011.14 1.13v13.64a.5.5 0 01-.5.5z'/%3e%3cpath d='M27.44 15.28a.51.51 0 01-.5-.5V1.13a.13.13 0 00-.13-.13H6.28a.5.5 0 010-1h20.53a1.14 1.14 0 011.13 1.13v13.65a.5.5 0 01-.5.5z'/%3e%3cpath data-name='Trac%c3%a9 41006' d='M9.14 13.1v-2.18a.37.37 0 01.37-.36.29.29 0 01.18 0l1.89 1.09 1.88 1.08a.38.38 0 01.14.51.35.35 0 01-.14.13l-1.88 1.09-1.89 1.14a.37.37 0 01-.5-.13.41.41 0 010-.19z'/%3e%3c/g%3e%3c/svg%3e",
    'podcasts': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 22.21'%3e%3cg fill='___fill___'%3e%3cpath d='M10.53 13.18a1.81 1.81 0 00-.52 0A2.09 2.09 0 007.69 15a1.51 1.51 0 000 .52c0 4.77.67 6.71 2.32 6.71s2.32-2.13 2.32-6.69a2.08 2.08 0 00-1.8-2.36zm-.09 7.12c-.15.37-.29.57-.43.57s-.26 0-.46-.54A15.41 15.41 0 019 15.5c0-.76.2-1 1-1s1 .21 1 1a14.87 14.87 0 01-.56 4.8z'/%3e%3cpath d='M13.17 15.3l.18-.11A6.17 6.17 0 0010 3.84a6.17 6.17 0 00-3.34 11.35l.18.11v-.21a3.24 3.24 0 01.3-1.09v-.09l-.08-.06a4.82 4.82 0 012.29-8.62 4.74 4.74 0 013.56.93 4.82 4.82 0 010 7.68l-.08.06.05.09a3.48 3.48 0 01.27 1.1z'/%3e%3cpath d='M19.51 6.92A10 10 0 106.9 19.52l.2.06v-.2c-.1-.38-.1-.73-.17-1.21v-.07h-.07a8.66 8.66 0 116.3 0h-.07v.07c0 .45-.09.85-.15 1.22v.2l.2-.06a10 10 0 006.37-12.61z'/%3e%3cpath d='M10 12.34A2.33 2.33 0 107.69 10 2.33 2.33 0 0010 12.34zM9 10a1 1 0 012 0 1 1 0 11-2 0z'/%3e%3c/g%3e%3c/svg%3e",
    'question-mark': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8.07 14.01'%3e%3cg fill='___fill___'%3e%3cpath d='M6.88 1.18A4.06 4.06 0 004.13 0H4a4 4 0 00-4 3.64.59.59 0 00.15.44.55.55 0 00.41.21h.11a.7.7 0 00.65-.61A2.73 2.73 0 016.2 2.39 2.72 2.72 0 016 6l-.27.24-.08.07a5.08 5.08 0 00-2.27 3.79.65.65 0 00.16.45.62.62 0 00.43.2h.08a.66.66 0 00.65-.61 3.8 3.8 0 011.72-2.82l.47-.41a4.07 4.07 0 00-.01-5.73zM4 12a1 1 0 101 1 1 1 0 00-1-1z'/%3e%3c/g%3e%3c/svg%3e",
    'quiz-badanswer': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 15'%3e%3cpath fill='___fill___' d='M.34.34A1.18 1.18 0 012 .34l5.5 5.51L13 .34a1.18 1.18 0 011.65 0 1.18 1.18 0 010 1.65L9.15 7.5l5.51 5.5a1.18 1.18 0 010 1.65 1.16 1.16 0 01-1.65 0L7.5 9.15 2 14.66A1.174 1.174 0 11.34 13l5.51-5.5L.34 2a1.16 1.16 0 010-1.66z'/%3e%3c/svg%3e",
    'quiz-goodanswer': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 15'%3e%3cpath fill='___fill___' d='M16.71 0a1.17 1.17 0 00-.86.45L6.64 11.51 2 7.22a1.11 1.11 0 00-1.57 0 1.08 1.08 0 00-.18.24 1.71 1.71 0 00.22 2.1L6 14.68a1.1 1.1 0 001.56 0l.09-.11 10-12A1.69 1.69 0 0017.9.92 1.25 1.25 0 0016.72 0z'/%3e%3c/svg%3e",
    'quiz-smileybad': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cg fill='___fill___'%3e%3cpath d='M15 30a15 15 0 1115-15 15 15 0 01-15 15zm0-27.5A12.5 12.5 0 1027.5 15 12.5 12.5 0 0015 2.5z'/%3e%3ccircle cx='10.65' cy='11.89' r='1.94'/%3e%3ccircle cx='19.35' cy='11.89' r='1.94'/%3e%3cpath d='M15 16.35a8.39 8.39 0 017.33 4.52 1.32 1.32 0 01-.44 1.76 1.17 1.17 0 01-1.61-.39.3.3 0 010-.08 5.86 5.86 0 00-10.48 0 1.17 1.17 0 01-1.56.52l-.09-.05a1.32 1.32 0 01-.44-1.76A8.39 8.39 0 0115 16.35z'/%3e%3c/g%3e%3c/svg%3e",
    'quiz-smileygood': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cg fill='___fill___'%3e%3cpath d='M15 30a15 15 0 1115-15 15 15 0 01-15 15zm0-27.5A12.5 12.5 0 1027.5 15 12.5 12.5 0 0015 2.5z'/%3e%3ccircle cx='10.65' cy='11.89' r='1.94'/%3e%3ccircle cx='19.35' cy='11.89' r='1.94'/%3e%3cpath d='M15 23.81a8.43 8.43 0 01-7.33-4.52 1.33 1.33 0 01.44-1.77 1.17 1.17 0 011.61.39v.08a5.86 5.86 0 0010.48 0 1.16 1.16 0 011.56-.52l.09.05a1.33 1.33 0 01.44 1.77A8.43 8.43 0 0115 23.81z'/%3e%3c/g%3e%3c/svg%3e",
    'quiz-smileyneutral': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cg fill='___fill___'%3e%3cpath d='M15 30a15 15 0 1115-15 15 15 0 01-15 15zm0-27.5A12.5 12.5 0 1027.5 15 12.5 12.5 0 0015 2.5z'/%3e%3ccircle cx='10.65' cy='11.89' r='1.94'/%3e%3ccircle cx='19.35' cy='11.89' r='1.94'/%3e%3cpath d='M9.5 18h11a1.5 1.5 0 011.5 1.5 1.5 1.5 0 01-1.5 1.5h-11A1.5 1.5 0 018 19.5 1.5 1.5 0 019.5 18z'/%3e%3c/g%3e%3c/svg%3e",
    'quotation-mark': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 31.84 28'%3e%3cpath fill='___fill___' d='M7.68 14L0 0h8.8l7.36 14L8.8 28H0zm15.76 0L15.76 0h8.72l7.36 14-7.36 14h-8.72z'/%3e%3c/svg%3e",
    'search': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22.05 22.04'%3e%3cpath fill='___fill___' d='M21.71 20.29L16 14.61A8.91 8.91 0 0018 9a9 9 0 10-9 9 8.91 8.91 0 005.61-2l5.68 5.69a1 1 0 101.53-1.29zM9 16a7 7 0 117-7 7 7 0 01-7 7z'/%3e%3c/svg%3e",
    'videos': "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27.32 20.16'%3e%3cpath fill='___fill___' d='M5.2 0A5.2 5.2 0 000 5.2V15a5.21 5.21 0 005.2 5.2h16.92a5.22 5.22 0 005.2-5.2V5.2a5.21 5.21 0 00-5.2-5.2zm0 1.3h16.92A3.88 3.88 0 0126 5.16V15a3.88 3.88 0 01-3.86 3.9H5.2A3.88 3.88 0 011.3 15V5.2a3.88 3.88 0 013.86-3.9zM11 5.2a.65.65 0 00-.6.65v8.46a.66.66 0 00.65.66.69.69 0 00.34-.09l7.15-4.23a.65.65 0 00.24-.89.69.69 0 00-.24-.24L11.39 5.3a.64.64 0 00-.39-.1zm.7 1.8l5.21 3.08-5.21 3.09z'/%3e%3c/svg%3e"
);

$sizes: (
    'angle': (
        'width': 145.35px,
        'height': 94.22px
    ),
    'arrow-small': (
        'width': 217.34px,
        'height': 169.26px
    ),
    'arrow': (
        'width': 189.53px,
        'height': 161.92px
    ),
    'check': (
        'width': 426.67px,
        'height': 324.47px
    ),
    'cross-thin': (
        'width': 17.93px,
        'height': 17.95px
    ),
    'cross': (
        'width': 13.95px,
        'height': 13.95px
    ),
    'external-link': (
        'width': 16px,
        'height': 16px
    ),
    'faq': (
        'width': 23.46px,
        'height': 23.46px
    ),
    'file-default': (
        'width': 18.03px,
        'height': 24px
    ),
    'file-doc': (
        'width': 20.7px,
        'height': 24px
    ),
    'file-image': (
        'width': 20.7px,
        'height': 15.45px
    ),
    'file-pdf': (
        'width': 20.7px,
        'height': 24px
    ),
    'file-ppt': (
        'width': 20.7px,
        'height': 24px
    ),
    'file-video': (
        'width': 21.17px,
        'height': 17.58px
    ),
    'file-xls': (
        'width': 20.7px,
        'height': 24px
    ),
    'gauge': (
        'width': 385px,
        'height': 385px
    ),
    'glossary': (
        'width': 22.45px,
        'height': 23.92px
    ),
    'phone': (
        'width': 20px,
        'height': 26.14px
    ),
    'play': (
        'width': 217.63px,
        'height': 254.61px
    ),
    'playlist': (
        'width': 27.94px,
        'height': 21.06px
    ),
    'podcasts': (
        'width': 20px,
        'height': 22.21px
    ),
    'question-mark': (
        'width': 8.07px,
        'height': 14.01px
    ),
    'quiz-badanswer': (
        'width': 15px,
        'height': 15px
    ),
    'quiz-goodanswer': (
        'width': 18px,
        'height': 15px
    ),
    'quiz-smileybad': (
        'width': 30px,
        'height': 30px
    ),
    'quiz-smileygood': (
        'width': 30px,
        'height': 30px
    ),
    'quiz-smileyneutral': (
        'width': 30px,
        'height': 30px
    ),
    'quotation-mark': (
        'width': 31.84px,
        'height': 28px
    ),
    'search': (
        'width': 22.05px,
        'height': 22.04px
    ),
    'videos': (
        'width': 27.32px,
        'height': 20.16px
    )
);

$variables: (
    'angle': (
        'fill': ''
    ),
    'arrow-small': (
        'fill': ''
    ),
    'arrow': (
        'fill': ''
    ),
    'check': (
        'fill': ''
    ),
    'cross-thin': (
        'fill': ''
    ),
    'cross': (
        'fill': ''
    ),
    'external-link': (
        'fill': ''
    ),
    'faq': (
        'fill': ''
    ),
    'file-default': (
        'fill': ''
    ),
    'file-doc': (
        'fill': ''
    ),
    'file-image': (
        'fill': ''
    ),
    'file-pdf': (
        'fill': ''
    ),
    'file-ppt': (
        'fill': ''
    ),
    'file-video': (
        'fill': ''
    ),
    'file-xls': (
        'fill': ''
    ),
    'glossary': (
        'fill': ''
    ),
    'phone': (
        'fill': ''
    ),
    'play': (
        'fill': ''
    ),
    'playlist': (
        'fill': ''
    ),
    'podcasts': (
        'fill': ''
    ),
    'question-mark': (
        'fill': ''
    ),
    'quiz-badanswer': (
        'fill': ''
    ),
    'quiz-goodanswer': (
        'fill': ''
    ),
    'quiz-smileybad': (
        'fill': ''
    ),
    'quiz-smileygood': (
        'fill': ''
    ),
    'quiz-smileyneutral': (
        'fill': ''
    ),
    'quotation-mark': (
        'fill': ''
    ),
    'search': (
        'fill': ''
    ),
    'videos': (
        'fill': ''
    )
);

// https://github.com/waldemarfm/sass-svg-uri/blob/v1.0.0/_svg-uri.scss
@function sprite-str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @if type-of($replace) == 'null' {
        $replace: '';
    }

    @if ( $index ) {
        @return str-slice($string, 1, $index - 1) + $replace + sprite-str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

@function sprite-svg-uri($value) {
    $encoded: '';
    $slice: 2000;
    $index: 0;

    @for $i from 1 through ceil(str-length($value) / $slice) {
        $chunk: str-slice($value, $index, $index + $slice - 1);
        $chunk: sprite-str-replace($chunk, '"', "'");
        $chunk: sprite-str-replace($chunk, '<', '%3C');
        $chunk: sprite-str-replace($chunk, '>', '%3E');
        $chunk: sprite-str-replace($chunk, '&', '%26');
        $chunk: sprite-str-replace($chunk, '#', '%23');
        $encoded: #{$encoded}#{$chunk};
        $index: $index + $slice;
    }

    @return $encoded;
}

@mixin sprite($name, $user-variables: (), $include-size: false) {
    $sprite: map-get($sprites, $name);

    // Inject variables
    $default-variables: map-get($variables, $name);
    @if type-of($default-variables) == 'map' {
        @each $key, $value in map-merge($default-variables, $user-variables) {
            @if ( not map-has-key($default-variables, $key) ) {
                @warn 'Sprite \'#{$name}\' does not support variable named \'#{$key}\'';
            }

            $sprite: sprite-str-replace($sprite, '___#{$key}___', sprite-svg-uri(quote(#{$value})));
        }
    } @else if type-of($user-variables) == 'map' {
        @warn 'Sprite \'#{$name}\' does not contain any variables';
    }

    background: url($sprite) center no-repeat;

    @if $include-size {
        $size: map-get($sizes, $name);
        background-size: map-get($size, width) map-get($size, height);
    }
}
