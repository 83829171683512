/*! Init fonts */

$font-marianne: "../fonts/marianne/";

$font-marianne-weights: (
  Medium: 500 normal,
  Bold: 700 normal,
  ExtraBold: 800 normal,
);

@each $variant-name, $variant-data in $font-marianne-weights {
  @font-face {
    font-family: 'Marianne';
    src: url('#{$font-marianne}Marianne-#{$variant-name}.woff2') format('woff2'),
        url('#{$font-marianne}Marianne-#{$variant-name}.woff') format('woff');
    font-weight: nth($variant-data, 1);
    font-style: nth($variant-data, 2);
  }
}
