/*! Menu button */

.MenuMobileTrigger {
  position: relative;
  align-self: center;
  padding-top: rem-calc(10);
  padding-bottom: rem-calc(10);
  outline: none;

  &::after {
    content: 'Menu';
    position: absolute;
    left: 50%;
    bottom: 0;
    display: block;
    font-size: rem-calc(9);
    font-weight: 700;
    color: map-get($color-main, 'darkblue');
    transform: translate(-50%, 4px);
  }

  body.menu-open--mobile & {
    &::after {
      content: 'Fermer';
      font-size: rem-calc(7);
      transform: translate(-50%, 2px);
    }

    .MenuMobileTrigger-stripe {
      transition: top .2s ease(in-quart), transform .2s ease(out-quart) .2s, opacity 0s ease(in-quart) .2s;

      &:first-child {
        top: rem-calc(7);
        transform: rotate(-45deg);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:last-child {
        top: rem-calc(-7);
        transform: rotate(45deg);
      }
    }
  }
}

.MenuMobileTrigger-content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  width: rem-calc(24);
  height: rem-calc(16);
}

.MenuMobileTrigger-stripe {
  position: relative;
  top: 0;
  width: 100%;
  height: 2px;
  background-color: map-get($color-main, 'darkblue');
  border-radius: 1px;
  transition: top .2s ease(out-quart) .2s, transform .2s ease(in-quart), opacity 0s ease(in-quart) .2s;

  &:nth-child(2) {
    width: 75%;
  }
}
