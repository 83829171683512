/*! CTA Bloc */

.ButtonsBloc {
  @include xy-grid;
  align-items: center;
  padding: rem-calc(40 30);

  @include breakpoint(xlarge) {
    padding: rem-calc(60 80);
  }

  @each $color, $value in $color-layer {
    &--#{$color} {
      background-color: $value;
    }
  }
}

.ButtonsBloc-heading {
  @include xy-cell(12, $gutter-type: none);
  text-align: center;

  @include breakpoint(medium) {
    @include xy-cell(4);
  }

  .icon {
    width: 100%;
    height: rem-calc(60);
    margin-bottom: rem-calc(15);
  }
}

.ButtonsBloc-title {
  color: map-get($color-main, 'darkblue');

  @include breakpoint(small only) {
    margin-bottom: rem-calc(30);
  }
}

.ButtonsBloc-content {
  @include xy-cell(12, $gutter-type: none);
  text-align: center;

  @include breakpoint(medium) {
    @include xy-cell(8);
  }

  @include breakpoint(xlarge) {
    @include xy-cell(7);
    @include xy-cell-offset(1);
  }
}

.ButtonsBloc-description {
  margin-bottom: rem-calc(25);
  color: map-get($color-main, 'darkblue');

  @include breakpoint(small only) {
    margin-top: rem-calc(-20);
  }
}

.ButtonsBloc-button {
  &:not(:last-child) {
    margin-bottom: rem-calc(15);
  }

  .Button {
    width: 100%;
  }
}
