/*! Form reset & global style */

input,
input[type="radio"],
input[type="checkbox"],
select,
textarea {
  display: inline-block;
  width: 100%;
	margin: 0;
  line-height: 1.2;
  border: none;
  border-radius: rem-calc(4);
	vertical-align: middle;
	white-space: normal;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type="radio"],
input[type="checkbox"] {
  @extend .sr-only;
}
