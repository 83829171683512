/*! Blockquote component */

.Blockquote {
  text-align: center;
  color: map-get($color-main, 'darkblue');

  @include breakpoint(medium) {
    padding-left: rem-calc(50);
    padding-right: rem-calc(50);
  }
}

.Blockquote-quote {
  position: relative;
  margin: 0;
  padding-top: rem-calc(35);
  padding-bottom: rem-calc(35);
  font-size: rem-calc(20);
  font-style: italic;

  @include breakpoint(medium) {
    padding-top: rem-calc(5);
    padding-bottom: rem-calc(5);
  }

  &::before,
  &::after {
    content: '';
    @include sprite('quotation-mark', (fill: map-get($color-main, 'brick')));
    position: absolute;
    width: rem-calc(30);
    height: rem-calc(30);

    @include breakpoint(medium) {
      width: rem-calc(35);
      height: rem-calc(40);
    }
  }

  &::before {
    top: 0;
    left: 0;
    transform: rotate(180deg);

    @include breakpoint(medium) {
      left: rem-calc(-50);
    }
  }

  &::after {
    bottom: 0;
    right: 0;

    @include breakpoint(medium) {
      right: rem-calc(-50);
    }
  }
}

.Blockquote-author {
  margin-top: rem-calc(10);
  font-size: rem-calc(16);
  font-weight: 800;
  font-style: normal;

  @include breakpoint(medium) {
    margin-top: rem-calc(20);
  }
}
