/*! Card Component */

// Global Card styles
.Card {
  position: relative;

  @include breakpoint(large) {
    &:hover {
      .Card-title {
        color: map-get($color-main, 'brick');
      }
    }
  }

  @each $color, $value in $color-layer {
    &--#{$color} {
      .Card-header {
        background-color: $value;
      }
    }
  }
}

.Card-title {
  display: inline-block;
  margin-bottom: rem-calc(5);
  color: map-get($color-main, 'darkblue');
  transition: color .2s ease(out-quint);
}


// primary style
.Card--primary {
  @include xy-grid;
  flex-wrap: nowrap;
  flex-flow: row-reverse;
  height: rem-calc(110);

  @include breakpoint(medium) {
    height: rem-calc(190);
  }

  @include breakpoint(large) {
    display: block;
    height: auto;

    &:hover {
      .Card-header {
        transform: scale(.95);
      }
    }
  }

  .Card-header {
    @include xy-cell(5, $gutter-type: none);
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    min-width: rem-calc(170);
    margin-bottom: rem-calc(15);
    will-change: transform;
    transform: translateX(rem-calc(20));
    overflow: hidden;
    transition: transform .8s ease(out-quint);

    @include breakpoint(medium down) {
      margin-bottom: 0;
    }

    @include breakpoint(medium) {
      @include xy-cell(4, $gutter-type: none);
      min-width: rem-calc(290);
    }

    @include breakpoint(large) {
      width: 100%;
      min-width: auto;
      transform: translateX(0);
      height: rem-calc(200);
    }
  }

  .Card-image {
    width: 100%;
    height: rem-calc(110);
    object-fit: cover;
    // object-fit polyfill for IE11
    font-family: 'object-fit: cover;';

    @include breakpoint(medium) {
      height: rem-calc(190);
    }

    @include breakpoint(large) {
      height: auto;
    }
  }

  .Card-content {
    @include xy-cell(auto, $gutter-type: none);
    overflow: hidden;

    @include breakpoint(large) {
      overflow: visible;
    }
  }

  .Card-title {
    @include breakpoint(large) {
      &:hover, #{$keyboard} &:focus {
        color: map-get($color-main, 'brick');
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &:not(.Card--inline) {
    .Card-description {
      @include breakpoint(large) {
        max-height: rem-calc(85);
        overflow: hidden;
      }
    }
  }


  // inline style
  &.Card--inline {
    @include xy-grid;
    flex-wrap: nowrap;

    @include breakpoint(large) {
      height: rem-calc(150);
    }

    .Card-header {
      min-width: rem-calc(140);
      margin-right: rem-calc(20);
      margin-bottom: 0;

      @include breakpoint(medium down) {
        transform: translateX(0);
      }

      @include breakpoint(medium) {
        @include xy-cell(4, $gutter-type: none);
        min-width: rem-calc(290);
        margin-right: rem-calc(30);
      }

      @include breakpoint(large) {
        min-width: auto;
        width: rem-calc(240);
        height: auto;
      }
    }

    .Card-image {
      height: rem-calc(110);
      object-fit: cover;
      // object-fit polyfill for IE11
      font-family: 'object-fit: cover;';
      object-position: bottom right;
      margin-bottom: 0;

      @include breakpoint(medium) {
        height: rem-calc(190);
      }

      @include breakpoint(large) {
        height: auto;
      }
    }

    .Card-content {
      overflow: hidden;
    }

    .Card-rubric {
      margin-bottom: rem-calc(10);
      padding-bottom: rem-calc(5);
      color: rgba(map-get($color-main, 'darkblue'), .7);
      border-bottom: 2px solid map-get($color-secondary, 'lavander');
    }
  }
}

// Secondary style
.Card--secondary {
  display: flex;
  flex-direction: column;
  height: 100%;

  .Card-header {
    position: relative;
    height: 0;
    padding-top: 70%;
    overflow: hidden;
  }

  .Card-image {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: auto;
  }

  .Card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: rem-calc(30 25);
    background-color: $color-white;
  }

  .Card-text {
    max-height: rem-calc(130);
    overflow: hidden;
  }

  .Card-button {
    margin-top: rem-calc(20);

    .Button {
      position: static;

      &::after {
        content: '';
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

// Tertiary style
.Card--tertiary {
  display: flex;
  flex-direction: column;
  height: 100%;

  @include breakpoint(large) {
    &:hover {
      .Card-header {
        transform: scale(.95);
      }
    }
  }

  .Card-header {
    position: relative;
    height: 0;
    padding-top: 70%;
    overflow: hidden;
    transition: transform .8s ease(out-quint);
  }

  .Card-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    // object-fit polyfill for IE11
    font-family: 'object-fit: cover;';
  }

  .Card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .Card-title {
    @include titleNet();
    margin-top: rem-calc(20);
    font-size: rem-calc(16);
    text-transform: uppercase;
  }

  .Card-description {
    max-height: rem-calc(85);
    overflow: hidden;
  }

  .Card-button {
    margin-top: rem-calc(20);

    .Button {
      position: static;

      &::after {
        content: '';
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
