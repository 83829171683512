/*! Tabs Component */
.Tabs-titles {
  @include breakpoint(medium down) {
    overflow: visible;
  }
}

.Tabs-title {
  @include xy-cell(shrink, $gutter-type: none);
  max-width: 100%;
  height: auto;
  padding-top: rem-calc(15);
  padding-bottom: rem-calc(15);
  padding-right: rem-calc(35);
  color: map-get($color-main, 'darkblue');
  box-shadow: inset rem-calc(0 -6 0 -1) rgba(map-get($color-main, 'darkblue'), .1);
  cursor: pointer;
  transition:
    box-shadow .3s ease(out-quint),
    color .3s ease(out-quint);

  @include breakpoint(large) {
    flex: 1 1 auto;

    &:not(.Tabs-title--active) {
      &:hover, #{$keyboard} &:focus {
        color: map-get($color-main, 'brick');
        box-shadow: inset rem-calc(0 -6 0 -1) map-get($color-main, 'brick');
      }
    }
  }

  &--active {
    font-weight: 800;
    box-shadow: inset rem-calc(0 -6 0 -1) map-get($color-main, 'darkblue');
  }
}

.Tabs-panel {
  display: none;
  padding-top: rem-calc(20);

  &--active {
    display: block;
  }
}
