/*! Subrubric component */

.SubRubric {
  @extend %container;
}

.SubRubric-content {
  @include breakpoint(large) {
    @include xy-grid;
    @include xy-gutters($negative: true);
  }
}

.SubRubric-item {
  margin-bottom: rem-calc(30);

  @include breakpoint(large) {
    @include xy-cell(4);
    margin-bottom: rem-calc(50);
  }

  @include breakpoint(xlarge) {
    @include xy-cell(3);
  }
}
