/*! Demo Component */
/* Simple styles for spacing demo content */

/* Container */
.Demo {
  margin: 0;
  padding-top: rem-calc(30);
  padding-bottom: rem-calc(30);
  color: $color-grey;
  background-color: white;

  @include breakpoint(medium) {
    padding-top: rem-calc(40);
    padding-bottom: rem-calc(40);
  }
}

.Demo--gray {
  color: black;
  background-color: #f0f0f0;
}

.Demo--red {
  color: #fff;
  background-color: #920;
  --button-color: #fff;
  --link-color: #aff;
  --highlight-color: #88f;
}

.Demo--blue {
  color: #fff;
  background-color: #1030a0;
  --button-color: #fff;
  --link-color: #aff;
  --highlight-color: #c00;
}

.Demo--green {
  color: #fff;
  background-color: #073;
  --button-color: #fff;
  --link-color: #aff;
  --highlight-color: #c00;
}

.Demo-container {
  @extend %container;

  h1 {
    color: #fff;

  }

  .h1-style,
  .h2-style {
    margin-bottom: rem-calc(15);
    color: map-get($color-main, 'darkblue');
  }
}

/* Margin between text elements */

.Demo summary {
  padding: .5em 0;
  cursor: pointer;
}

.Demo h1:not([class]):not(:last-child),
.Demo h2:not([class]):not(:last-child) {
  margin-bottom: 15px;
}

.Demo p:not([class]):not(:first-child) {
  margin-top: 15px;
}

/* Centered container */

.Demo-row {
  max-width: var(--max-width, 1200px);
  margin: 0 auto;
  text-align: left;
}

.Demo-row + .Demo-row {
  margin-top: 20px;
}

.Demo-row--medium {
  --max-width: 900px;
}

.Demo-row--small {
  --max-width: 600px;
}

/* Simple grids: auto columns or fixed column number */

.Demo-grid {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
}

.Demo-grid > * {
  margin: 0 !important;
}

.Demo-grid--2col {
  grid-template-columns: 1fr;
}

@media (min-width: 600px) {
  .Demo-grid--2col {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Blocks with borders */

.Demo-block {
  margin: 10px 0;
  padding: 10px;
  border: solid 2px rgba(0, 0, 0, 0.2);
}

.Demo-block--gray {
  border-color: #ccc;
  background-color: rgba(200, 200, 200, 0.2);
}

.Demo-block--yellow {
  border-color: #ed0;
  background-color: rgba(255, 255, 0, 0.16);
}

.Demo-block--cyan {
  border-color: #4de;
  background-color: rgba(0, 255, 255, 0.12);
}

.Demo-block--magenta {
  border-color: #eae;
  background-color: rgba(255, 0, 255, 0.08);
}

/* Link and button style (for temporary work only) */

.Demo-link {
  color: var(--link-color, #0000d0);
  box-shadow: 0 2px 0 currentColor;
  text-decoration: none;
}

.Demo-link:hover,
.Demo-link:focus {
  background-color: var(--highlight-color, lime);
}

.Demo-button {
  margin: 4px 2px;
  padding: 0.25em 0.5em;
  border-radius: 3px;
  border: solid 2px;
  color: var(--button-color, black);
  background-color: rgba(0, 0, 0, 0.08);
}

.Demo-button:hover,
.Demo-button:focus {
  background-color: var(--highlight-color, lime);
}

.Demo-button--big {
  font-size: 115%;
  padding: 0.5em 1em;
}
