/*! Steps component */

.Steps-container {
  @include xy-gutters($negative: true, $gutters: rem-calc(14));
  overflow: visible;

  @include breakpoint(medium down) {
    padding-bottom: rem-calc(40);
  }

  @include breakpoint(medium) {
    @include xy-gutters($negative: true);
  }

  @include breakpoint(large) {
    &:not(.swiper-container-initialized) {
      .Steps-list {
        flex-wrap: wrap;
      }

      .Steps-item--large {
        @include xy-cell(12);
        box-shadow: none;
      }

      .Steps-fraction,
      .Steps-pagination {
        display: none;
      }
    }
  }
}

.Steps-fraction {
  z-index: 1;
  position: absolute;
  top: rem-calc(10);
  right: rem-calc(5);
  width: rem-calc(55);
  padding-top: rem-calc(3);
  padding-bottom: rem-calc(3);
  font-weight: 700;
  text-align: center;
  color: $color-white;
  background-color: $color-black;
  border-radius: rem-calc(10);
  opacity: 0;
  transition: opacity .5s ease(in-quad);

  @include breakpoint(medium) {
    right: rem-calc(10);
  }

  &.is-visible {
    opacity: .5;
    transition: opacity .5s ease(out-quint);
  }
}

.Steps-pagination {
  z-index: 1;
  position: absolute;
  text-align: center;

  .swiper-pagination-bullet {
    width: rem-calc(10);
    height: rem-calc(10);
    background-color: map-get($color-main, 'darkblue');
    transform: scale(0);

    &.swiper-pagination-bullet-active-main {
      transform: scale(1);
    }

    &.swiper-pagination-bullet-active-prev,
    &.swiper-pagination-bullet-active-next {
      transform: scale(.66);
    }

    &.swiper-pagination-bullet-active-prev-prev,
    &.swiper-pagination-bullet-active-next-next {
      transform: scale(.33);
    }
  }

  .swiper-pagination-bullet-active-main.swiper-pagination-bullet-active {
    background-color: map-get($color-main, 'brick');
  }
}

.Steps-item {
  @include xy-cell(8, $gutters: rem-calc(14));
  height: auto;
  padding-bottom: rem-calc(15);
  border-radius: rem-calc(10);
  box-shadow: rem-calc(0 5 30 -10) $shadow-strong;
  overflow: hidden;

  @include breakpoint(medium) {
    @include xy-cell(3.85);
  }

  @include breakpoint(large) {
    @include xy-cell(4);
    margin-bottom: rem-calc(20);
    padding-top: rem-calc(15);
  }

  &--large {
    padding: rem-calc(15);
    text-align: center;
    background-color: map-get($color-secondary, 'lightgrey');

    @include breakpoint(large) {
      text-align: left;
    }
  }
}

.Steps-header {
  color: map-get($color-main, 'darkblue');

  @include breakpoint(large) {
    display: flex;
    align-items: center;
  }
}

.Steps-length {
  display: inline-block;
  font-size: rem-calc(75);
  font-weight: 800;
  line-height: 1;

  @include breakpoint(large) {
    margin-right: rem-calc(20);
  }
}

.Steps-heading {
  font-size: rem-calc(14);
  font-weight: bold;
}

.Steps-title {
  display: block;
  margin-bottom: rem-calc(8);
  text-transform: uppercase;

  &::after {
    content: '';
    display: block;
    width: rem-calc(30);
    height: rem-calc(3);
    margin: rem-calc(5) auto 0;
    background-color: map-get($color-main, 'brick');

    @include breakpoint(large) {
      margin: rem-calc(5 0 0);
    }
  }
}

.Steps-step {
  text-align: center;
}

.Steps-stepImage {
  width: auto;
  max-width: 100%;
  height: rem-calc(130);
  margin-bottom: rem-calc(10);
  object-fit: cover;
  // object-fit polyfill for IE11
  font-family: 'object-fit: cover;';

  @include breakpoint(large) {
    height: rem-calc(130);
  }
}

.Steps-stepTitle {
  display: block;
  font-size: rem-calc(13);
  font-weight: 700;
  line-height: 1.25;
  color: map-get($color-main, 'brick');
}

.Steps-stepDescription {
  font-size: rem-calc(12);
  font-weight: 700;
}

.Steps-stepTitle,
.Steps-stepDescription {
  padding-left: rem-calc(15);
  padding-right: rem-calc(15);
}
