/*! Header component */

.Header {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: top .3s ease(out-quart);

  &--extended {
    position: relative;

    &.Header--sticky {
      position: fixed;
    }
  }
}

.Header-top {
  z-index: 3;
  position: relative;
  background-color: $color-white;

  .Header--sticky & {
    display: none;
  }
}

.Header-wrapper {
  z-index: 2;
  position: relative;
  background-color: $color-lightgrey;
}

.Header-container {
  @extend %container;

  @include breakpoint(small only) {
    .Header-wrapper & {
      background-color: $color-lightgrey;
    }
  }
}

.Header-logos {
  @include xy-grid;
  @include xy-gutters($negative: true);
  align-items: center;
  height: rem-calc(75);

  @include breakpoint(medium down) {
    justify-content: space-between;
  }

  @include breakpoint(large) {
    height: rem-calc(100);
  }
}

.Header-logo {
  @include xy-cell('shrink');

  @include breakpoint(medium) {
    @include xy-cell('shrink');
  }

  &--RF {
    @include breakpoint(large) {
      position: relative;
      margin-right: rem-calc(50);

      &::after {
        content: '';
        position: absolute;
        top: rem-calc(-5);
        right: rem-calc(-40);
        width: 1px;
        height: calc(100% + #{rem-calc(10)});
        background-color: map-get($color-secondary, 'lightgrey');
      }
    }

    .logo-rf {
      width: rem-calc(75);

      @include breakpoint(medium down) {
        display: none;
      }
    }

    .logo-rf-small {
      width: rem-calc(65);

      @include breakpoint(large) {
        display: none;
      }
    }
  }

  &--SPF {
    .logo-spf {
      width: rem-calc(63);

      @include breakpoint(large) {
        width: rem-calc(105);
      }
    }
  }
}

.Header-content {
  @include xy-grid;
  @include xy-gutters($negative: true);
  align-items: center;
  height: rem-calc(55);

  @include breakpoint(small only) {
    justify-content: space-between;
  }

  @include breakpoint(medium) {
    height: rem-calc(100);
  }

  @include breakpoint(xxlarge) {
    @include xy-gutters($negative: true, $gutters: rem-calc(40));
  }
}

.Header-logo--QS {
  @include xy-cell('shrink');
  display: flex;
  align-items: center;

  @include breakpoint(medium) {
    @include xy-cell('shrink');
  }

  @include breakpoint(xxlarge) {
    @include xy-cell('shrink', $gutters: rem-calc(40));
  }

  .logo {
    width: rem-calc(140);

    @media screen and (min-width: 340px) {
      width: rem-calc(200);
    }

    @include breakpoint(medium) {
      width: rem-calc(245);
    }

    @include breakpoint(large) {
      width: rem-calc(320);
    }

    @include breakpoint(xlarge) {
      width: rem-calc(400);
    }
  }
}

.Header-nav {
  @include xy-cell('shrink');

  @include breakpoint(medium) {
    @include xy-cell(auto);
  }

  @include breakpoint(xxlarge) {
    @include xy-cell(auto, $gutters: rem-calc(40));
  }
}

.Header-navContent {
  @include xy-grid;
  @include xy-gutters($negative: true);

  @include breakpoint(xxlarge) {
    @include xy-gutters($negative: true, $gutters: rem-calc(40));
  }
}

.Header-search {
  @include xy-cell('shrink', $gutter-type: none);
  height: rem-calc(40);

  @include breakpoint(medium) {
    @include xy-cell(auto);
    height: auto;
  }

  @include breakpoint(xxlarge) {
    @include xy-cell(auto, $gutters: rem-calc(40));
  }
}

.Header-searchTrigger {
  @include sprite('search', (fill: map-get($color-main, 'darkblue')));
  display: inline-block;
  width: rem-calc(40);
  height: rem-calc(40);
  background-size: rem-calc(22 22);


  @include breakpoint(medium) {
    display: none;
  }

  body.search-open & {
    @include sprite('cross-thin', (fill: map-get($color-main, 'darkblue')));
    background-size: rem-calc(18 18);
  }
}

.Header-quickAccess {
  @include xy-cell('shrink');

  @include breakpoint(medium down) {
    display: none;
  }

  @include breakpoint(medium) {
    @include xy-cell('shrink');
  }

  @include breakpoint(xxlarge) {
    @include xy-cell('shrink', $gutters: rem-calc(40));
  }
}

.Header-menuMobileTrigger {
  @include xy-cell(shrink, $gutter-type: 'padding');

  @include breakpoint(medium) {
    @include xy-cell(shrink, $gutter-type: 'padding');
  }

  @include breakpoint(large) {
    display: none;
  }
}

.Header-menuDesktopTrigger {
  @include breakpoint(medium down) {
    display: none;
  }

  @include breakpoint(large) {
    @include xy-cell('shrink');
    display: flex;
    align-items: center;
    height: rem-calc(35);
    padding-left: rem-calc(15);
    padding-right: rem-calc(15);
    color: map-get($color-main, 'darkblue');
    background-color: $color-white;
    border: 1px solid map-get($color-main, 'darkblue');
    border-bottom-color: transparent;
    border-top-left-radius: rem-calc(5);
    border-top-right-radius: rem-calc(5);
    opacity: 0;
    transform-origin: bottom center;
    will-change: transform;
    transform: translateY(rem-calc(36)) scale(.85, 0);
    visibility: hidden;
    transition:
      opacity .3s ease(out-quint),
      color .3s ease(out-quint),
      background-color .3s ease(out-quint),
      transform .3s ease(out-quint),
      border .3s ease(out-quint),
      visibility 0s linear .3s;

    &:hover, #{$keyboard} &:focus {
      color: $color-white;
      background-color: map-get($color-main, 'brick');
      border: 1px solid map-get($color-main, 'brick');
    }

    body.menu-hidden & {
      transform: translateY(rem-calc(36)) scale(1, 1);
      opacity: 1;
      visibility: visible;
      transition:
        opacity .3s ease(out-quint),
        color .3s ease(out-quint),
        background-color .3s ease(out-quint),
        transform .3s ease(out-quint) .12s,
        border .3s ease(out-quint),
        visibility 0s linear;
    }
  }

  @include breakpoint(xxlarge) {
    @include xy-cell('shrink', $gutters: rem-calc(40));
  }
}

.Header-menuDesktopTrigger-label {
  display: block;
  margin-right: rem-calc(8);
  font-size: rem-calc(12);
  font-weight: 700;
  text-transform: uppercase;
}

.Header-menuDesktopTrigger-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: rem-calc(20);
  height: rem-calc(14);
}

.Header-menuDesktopTrigger-stripe {
  position: relative;
  top: 0;
  width: 100%;
  height: 2px;
  background-color: currentColor;
  border-radius: 1px;
  transition: top .2s ease(out-quart) .2s, transform .2s ease(in-quart), opacity 0s ease(in-quart) .2s;

  &:nth-child(2) {
    width: 75%;
  }
}
