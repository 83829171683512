/*! SearchResults component */


.SearchResults {
  margin-top: rem-calc(30);
  margin-bottom: rem-calc(30);

  @include breakpoint(medium) {
    margin-top: rem-calc(50);
    margin-bottom: rem-calc(50);
  }

  .Pagination {
    margin-top: rem-calc(30);
  }
}

.SearchResults-topQuestions {
  h3 {
    @extend .h3-style;
    margin-bottom: rem-calc(30);
    padding-bottom: rem-calc(4);
    border-bottom: rem-calc(4) solid rgba(map-get($color-main, 'brick'), .2);
  }
}

.SearchResults-menu {
  overflow: visible;
}

.SearchResults-menuItem {
  @include xy-cell(shrink, $gutter-type: none);
  max-width: 100%;
  height: auto;

  @include breakpoint(medium) {
    flex: 1 1 auto;
  }
}

.SearchResults-menuLink {
  display: block;
  padding-top: rem-calc(15);
  padding-bottom: rem-calc(15);
  color: map-get($color-main, 'darkblue');
  box-shadow: inset rem-calc(0 -6 0 -1) rgba(map-get($color-main, 'darkblue'), .1);
  transition:
    box-shadow .3s ease(out-quint),
    color .3s ease(out-quint);

  #{$mouse} &:focus {
    outline: none;
  }

  @include breakpoint(small only) {
    padding-right: rem-calc(35);
  }

  @include breakpoint(medium) {
    text-align: center;
  }

  @include breakpoint(large) {
    &:not(.SearchResults-menuLink--active):not(.SearchResults-menuLink--disabled) {
      &:hover, #{$keyboard} &:focus {
        color: map-get($color-main, 'brick');
        box-shadow: inset rem-calc(0 -6 0 -1) map-get($color-main, 'brick');
      }
    }
  }

  &--active {
    font-weight: 800;
    box-shadow: inset rem-calc(0 -6 0 -1) map-get($color-main, 'darkblue');
  }

  &--disabled {
    color: rgba(map-get($color-main, 'darkblue'), 0.5);
    cursor: not-allowed;
  }
 }

.SearchResults-definition {
  @include breakpoint(large) {
    padding-right: 20%;
  }
}

.SearchResults-videosItem {
  &:not(.swiper-slide):not(:last-child) {
    margin-bottom: rem-calc(20)
  }
}

.SearchResults-article {
  &:not(last-child) {
    margin-bottom: rem-calc(20);
  }

  @include breakpoint(small only) {
    &:not(last-child) {
      margin-bottom: rem-calc(35);
    }

    .Card--inline {
      height: auto;
    }

    .Card-header {
      display: none;
    }

    .Card-description {
      height: rem-calc(20);
    }
  }
}

.SearchResults-moreButton {
  margin-top: rem-calc(25);
  text-align: center;
}
