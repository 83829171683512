/*! Glossary Tooltip component */

.GlossaryTooltip {
  color: map-get($color-main, 'darkblue');
  font-weight: 800;
  text-align: left;
  transition: color .2s ease(out-quint);

  .RichText-content & {
    font-weight: 500;
  }

  #{$mouse} &:focus,
  #{$touch} &:focus {
    outline: none;
  }

  @include breakpoint(large) {
    &:hover, #{$keyboard} &:focus {
      sup {
        color: map-get($color-main, 'brick');
      }

      &:not(.GlossaryTooltip--inPage) {
        color: map-get($color-main, 'brick');
      }
    }
  }

  &[aria-expanded="true"] {
    color: map-get($color-main, 'brick');
  }

  .Glossary & {
    font-size: rem-calc(16);

    @include breakpoint(small only) {
      display: block;
      padding-top: rem-calc(10);
      padding-bottom: rem-calc(10);
    }
  }

  &:not(.GlossaryTooltip--inPage) {
    @include breakpoint(small only) {
      width: 100%;
    }
  }

  &--inPage {
    color: $color-grey;

    &[aria-expanded="true"] {
      color: map-get($color-main, 'darkblue');
    }

    sup {
      font-weight: 500;
      color: map-get($color-main, 'darkblue');
      transition: color .2s ease(out-quint);
    }
  }
}

.GlossaryTooltip-title {
  @extend .CardBevel-title;
}

.GlossaryTooltip-content {
  @extend .CardBevel-text;
  display: block;
  text-align: left;
}

.GlossaryTooltip-link {
  @extend .CardBevel-link;
}

.GlossaryTooltip-close {
  @include sprite('cross', (fill: $color-white));
  position: absolute;
  top: 0;
  right: 0;
  width: rem-calc(60);
  height: rem-calc(60);
  background-color: map-get($color-main, 'brick');
  border-radius: 50%;
  background-size: rem-calc(14 14);
  transform: translate(20px, -20px);
  transition: background-color .3s ease(out-quint);

  @include breakpoint(small only) {
    transform: translate(-10px, -20px);
  }

  &:hover, #{$keyboard} &:focus {
    background-color: darken(map-get($color-main, 'brick'), 5);
  }
}

// Glossary tooltip custom theme
.tippy-box {
  width: rem-calc(350);
  max-width: calc(100vw - #{rem-calc(60)}) !important;

  &[data-inertia] {
    transition-timing-function: ease(out-quint);
  }

  &[data-animation='fade'][data-state='hidden'] {
    opacity: 0;
  }

  &[data-theme~='glossary'] {
    outline: none;

    .tippy-content {
      @extend .CardBevel-container;
    }

    .GlossaryTooltip-tooltip {
      @extend .CardBevel-content;
    }
  }
}
