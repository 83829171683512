/*! MenuMobile component */

.MenuMobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  transition: visibility 0s linear .55s;

  body.menu-open--mobile & {
    visibility: visible;
    transition: visibility 0s linear;
  }

  @include breakpoint(large) {
    display: none;
  }
}

.MenuMobile-container {
  width: 100%;
  max-width: rem-calc(430);
  height: 100%;
  margin-left: auto;
  margin-right: 0;
  padding-top: rem-calc(55);
  padding-bottom: rem-calc(50);
  background-color: map-get($color-main, 'darkblue');
  opacity: 0;
  overflow-y: auto;
  overflow-x: hidden;
  transform: translateX(100%);
  transition:
    transform .25s ease(out-quint),
    opacity .2s ease(out-quart) .05s,
    background-color .25s ease(out-quint);

  @include breakpoint(medium) {
    padding-top: rem-calc(100);
  }

  &--subrubricsSelected {
    background-color: map-get($color-secondary, 'lavander');
  }

  &--articlesSelected {
    background-color: $color-white;
  }

  body.menu-open--mobile & {
    opacity: 1;
    transform: translateX(0);
    transition:
      transform .35s ease(out-expo),
      opacity .20s ease(out-quint),
      background-color .35s ease(out-quint);
  }

  .Header--extended:not(.Header--sticky) & {
    padding-top: rem-calc(130);

    @include breakpoint(medium) {
      padding-top: rem-calc(175);
    }
  }
}

.MenuMobile-wrapper {
  overflow: hidden;
  transition: height .25s ease(out-quart);
}

.MenuMobile-rubrics {
  position: relative;
  padding-top: rem-calc(20);
}

.MenuMobile-sub {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  padding-top: rem-calc(20);
  opacity: 0;
  visibility: hidden;
  transition:
    transform .25s ease(out-quint),
    opacity .25s ease(out-quint),
    visibility 0s linear .25s;

  &--subrubrics {
    background-color: map-get($color-secondary, 'lavander');
  }

  &--articles {
    background-color: $color-white;
  }

  &.is-active {
    transform: translateX(-100%);
    opacity: 1;
    visibility: visible;
    transition:
      transform .35s ease(out-quint),
      opacity .35s ease(out-quint),
      visibility 0s linear;
  }
}

.MenuMobile-button {
  @include sprite('arrow-small', (fill: map-get($color-main, 'brick')));
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: left;
  padding: rem-calc(15 50 15 20);
  font-weight: 700;
  line-height: 1.15;
  color: map-get($color-main, 'darkblue');
  background-size: rem-calc(11 9) !important;
  background-position: right rem-calc(20) center;
  outline: none;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: rem-calc(20);
    width: calc(100% - #{rem-calc(40)});
    height: 1px;
    background-color: rgba(map-get($color-main, 'darkblue'), .5);
  }

  .MenuMobile-item > & {
    font-size: rem-calc(20);
    font-weight: 800;
    color: $color-white;

    &::after {
      background-color: rgba($color-white, .2);
    }
  }
}

.MenuMobile-back {
  position: relative;
  width: 100%;
  padding: rem-calc(5 20 5 40);
  font-weight: 800;
  color: map-get($color-main, 'brick');
  text-transform: uppercase;
  text-align: left;
  outline: none;

  &::after {
    content: '';
    @include sprite('arrow-small', (fill: map-get($color-main, 'brick')));
    position: absolute;
    top: 50%;
    left: rem-calc(20);
    width: rem-calc(11);
    height: rem-calc(11);
    transform: rotate(180deg) translateY(50%);
  }
}

.MenuMobile-parentName {
  display: block;
  width: 100%;
  margin-top: rem-calc(5);
  margin-bottom: rem-calc(10);
  padding-left: rem-calc(20);
  padding-right: rem-calc(20);
  font-size: rem-calc(20);
  font-weight: 800;
  line-height: 1.15;
  color: map-get($color-main, 'darkblue');
}

.MenuMobile-overlay {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparentize($color: #000, $amount: .5);
  opacity: 0;
  visibility: hidden;
  transition:
    opacity .3s ease(out-quint),
    visibility 0s linear .3s;

  body.menu-open--mobile & {
    opacity: 1;
    visibility: visible;
    transition:
      opacity .5s ease(out-quint),
      visibility 0s linear;
  }
}
