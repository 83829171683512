/*! Landing Page */

.LandingPage {
  margin-bottom: rem-calc(50);

  .has-sidebar {
    @include breakpoint(medium down) {
      padding-top: 0;
    }

    .LandingPage-content {
      @include breakpoint(medium down) {
        padding-top: rem-calc(10);
      }
    }
  }
}

.LandingPage-container {
  @extend %container;
  padding-top: rem-calc(30);

  .Summary {
    @include breakpoint(large) {
      display: none;
    }
  }
}

.LandingPage-chapo {
  @extend .Chapo-content;
}

.LandingPage-topBlock {
  margin-top: rem-calc(30);
  margin-bottom: rem-calc(50);

  @include breakpoint(xlarge) {
    margin-left: 5%;
    margin-right: 5%;
  }
}

.LandingPage-block {
  margin-top: rem-calc(30);
  margin-bottom: rem-calc(30);

  @include breakpoint(medium) {
    margin-top: rem-calc(50);
    margin-bottom: rem-calc(50);
  }

  @include breakpoint(large) {
    @include xy-grid;
    flex-wrap: nowrap;
  }
}

.LandingPage-blockSidebar {
  @include breakpoint(medium down) {
    display: none;
  }

  @include breakpoint(large) {
    width: rem-calc(360);
    min-width: rem-calc(360);
    margin-right: rem-calc(30);
  }

  @include breakpoint(xlarge) {
    margin-right: rem-calc(75);
  }
}

.LandingPage-blockContent {
  @include breakpoint(xlarge) {
    padding-right: rem-calc(200);
  }
}

.LandingPage-blockImage {
  top: 130px;
  position: sticky;
}
