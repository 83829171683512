/*! PictoNumber Component */

.PictoNumber {
  .icon {
    width: auto;
    height: rem-calc(60);
    margin-bottom: rem-calc(20);
  }
}

.PictoNumber-value {
  font-size: rem-calc(40);
  font-weight: 800;
  line-height: 1;
}

.PictoNumber-label {
  font-size: rem-calc(16);
  font-weight: 800;
}

.PictoNumber-value,
.PictoNumber-label {
  display: block;
  color: map-get($color-main, 'darkblue');
}

.PictoNumber-description {
  margin-top: rem-calc(5);
}
