/*! CardVideo Component */

.CardVideo {
  position: relative;
  height: auto;

  @include breakpoint(large) {
    &:hover, #{$keyboard} &:focus {
      .CardVideo-header::after {
        background-color: map-get($color-main, 'brick');
      }

      .CardVideo-title {
        color: map-get($color-main, 'brick');
      }
    }
  }
}

.CardVideo-header {
  position: relative;
  height: 0;
  margin-bottom: rem-calc(15);
  padding-top: 70%;

  &::after {
    @include sprite('play', (fill: $color-white));
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: rem-calc(45);
    height: rem-calc(45);
    border-radius: 50%;
    background-color: map-get($color-main, 'darkblue');
    background-size: rem-calc(10 10) !important;
    background-position: left 52% center !important;
    box-shadow: rem-calc(0 2 3 0) $shadow-strong;
    transform: translate(-50%, -50%);
    transition: background-color .3s ease(out-quint);
  }
}

.CardVideo-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  // object-fit polyfill for IE11
  font-family: 'object-fit: cover;';
}

.CardVideo-infos {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.CardVideo-title {
  color: map-get($color-main, 'darkblue');
  transition: color .2s ease(out-quint);

  @include breakpoint(small only) {
    margin-bottom: rem-calc(5);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.CardVideo-duration {
  width: rem-calc(50);
  min-width: rem-calc(50);
  margin-left: rem-calc(20);
  padding-top: rem-calc(3);
  padding-bottom: rem-calc(3);
  border-radius: rem-calc(30);
  font-size: rem-calc(12);
  text-align: center;
  color: map-get($color-main, 'darkblue');
  background-color: map-get($color-secondary, 'lavander');
  transform: translateY(-2px);

  @include breakpoint(large) {
    top: 0;
    bottom: auto;
  }

  .VideoPlayer-related & {
    background-color: map-get($color-secondary, 'lavander');
  }
}
