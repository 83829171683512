/*! MenuDesktop component */

.MenuDesktop {
  display: none;

  @include breakpoint(large) {
    position: absolute;
    top: 100%;
    left: 0;
    display: block;
    width: 100%;
    background-color: $color-white;
    box-shadow: rem-calc(0 0 5 0) $shadow-strong;
    transition: transform .35s ease(out-quart);

    body.menu-hidden & {
      transform: translateY(-100%);
      transition: transform .35s ease(out-quart) .12s;
    }
  }
}

.MenuDesktop-container {
  @extend %container;
  transition: visibility 0s linear;

  @include breakpoint(large) {
    body.menu-hidden & {
      visibility: hidden;
      transition: visibility 0s linear .35s;
    }
  }
}

.MenuDesktop-rubrics {
  @include xy-grid;
}

.MenuDesktop-item {
  @include xy-cell(auto, $gutter-type: 'none');

  &:not(:last-child) {
    margin-right: rem-calc(20);

    @include breakpoint(xlarge) {
      margin-right: rem-calc(30);
    }
  }
}

.MenuDesktop-link {
  display: inline-flex;
  align-items: center;
  height: rem-calc(75);
  max-height: rem-calc(75);
  font-weight: 700;
  line-height: 1.15;
  text-transform: uppercase;
  text-align: left;
  color: map-get($color-main, 'darkblue');
  transition: color .2s ease(out-quint);

  &:hover, #{$keyboard} &:focus,
  .MenuDesktop-item.is-active & {
    color: map-get($color-main, 'brick');
  }
}

.MenuDesktop-submenu {
  z-index: 2;
  position: fixed;
  top: rem-calc(175);
  left: 0;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity .3s ease(out-quint),
    visibility 0s linear .3s;

  &.dropdown-active {
    opacity: 1;
    visibility: visible;
    transition:
      opacity .3s ease(out-quint),
      visibility 0s linear;
  }

  .Header--extended:not(.Header--sticky) & {
    top: rem-calc(275);
  }
}

.MenuDesktop-submenuContainer {
  @extend %container;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - #{rem-calc(175)});
  padding-top: rem-calc(60);
  padding-bottom: rem-calc(50);

  .Header--extended:not(.Header--sticky) & {
    height: calc(100vh - #{rem-calc(275)});
  }
}

.MenuDesktop-submenuContent {
  @include xy-grid;
  @include xy-gutters($negative: true, $gutters: rem-calc(60));
  height: 100%;
}

.MenuDesktop-rubricInfos {
  @include xy-cell(3, $gutters: rem-calc(60));
  color: $color-white;
  opacity: 0;
  transition: opacity .3s ease(out-quint);

  .dropdown-active & {
    opacity: 1;
    transition: opacity .75s ease(out-quint) .3s;
  }
}

.MenuDesktop-rubricName {
  display: inline-block;
  margin-bottom: rem-calc(20);
  font-size: rem-calc(28);
}

.MenuDesktop-separator {
  display: block;
  width: rem-calc(1);
  background-color: rgba($color-white, .2);
  transform: scaleY(0);
  transform-origin: top center;
  transition: transform 0s linear .3s;

  body.menu-open--desktop & {
    transform: scaleY(1);
    transition: transform .8s ease(out-quint) .3s;
  }
}

.MenuDesktop-subrubrics {
  @include xy-cell(4.5, $gutter-type: 'padding', $gutters: rem-calc(60));
  max-height: 100%;
  overflow: auto;
  opacity: 0;
  transition: opacity .3s ease(out-quint);

  .dropdown-active & {
    opacity: 1;
    transition: opacity .75s ease(out-quint) .4s;
  }
}

.MenuDesktop-subitem {
  &:not(:last-child) {
    margin-bottom: rem-calc(20);
  }
}

.MenuDesktop-sublink {
  display: block;
  width: 100%;
  padding-right: rem-calc(30);
  font-weight: 700;
  text-align: left;
  color: $color-white;
  transition:
    color .2s ease(out-quint),
    background .2s ease(out-quint);

  &:hover, #{$keyboard} &:focus,
  .MenuDesktop-subitem.is-active > & {
    color: map-get($color-main, 'brick');
  }

  .MenuDesktop-subitem--has-submenu > & {
    @include sprite('arrow-small', (fill: map-get($color-main, 'brick')));
    background-size: rem-calc(11 9) !important;
    background-position: center right !important;
  }

  .MenuDesktop-subitem.is-not-open > &:not(:hover) {
    @include sprite('arrow-small', (fill: rgba($color-white, .5)));
    color: rgba($color-white, .5);
  }
}

.MenuDesktop-articles {
  @include xy-cell(4.5, $gutter-type: 'padding', $gutters: rem-calc(60));
  position: absolute;
  top: rem-calc(60);
  right: 0;
  max-height: calc(100% - #{rem-calc(110)});
  overflow: auto;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity .3s ease(out-quint),
    visibility 0s linear .3s;

  &.dropdown-active {
    opacity: 1;
    visibility: visible;
    transition:
      opacity .75s ease(out-quint) .1s,
      visibility 0s linear;
  }
}

.MenuDesktop-overlay {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: map-get($color-main, 'darkblue');
  opacity: 0;
  visibility: hidden;
  transition:
    opacity .3s ease(out-quint) .1s,
    visibility 0s linear .4s;

  body.menu-open--desktop & {
    opacity: 1;
    visibility: visible;
    transition:
      opacity .5s ease(out-quint),
      visibility 0s linear;
  }
}

.MenuDesktop-closeButton {
  z-index: 3;
  position: fixed;
  top: rem-calc(195);
  right: rem-calc(30);
  display: inline-flex;
  align-items: center;
  font-weight: 800;
  color: map-get($color-main, 'brick');
  opacity: 0;
  visibility: hidden;
  transition:
    color .3s ease(out-quint),
    opacity .3s ease(out-quint),
    visibility 0s linear .3s;

  &:hover, #{$keyboard} &:focus {
    color: $color-white;

    &::after {
      @include sprite('cross', (fill: map-get($color-main, 'darkblue')));
      background-color: $color-white;
    }
  }

  &::after {
    @include sprite('cross', (fill: $color-white));
    content: '';
    display: block;
    width: rem-calc(55);
    height: rem-calc(55);
    margin-left: rem-calc(10);
    border-radius: 50%;
    background-color: map-get($color-main, 'brick');
    background-size: rem-calc(15 15) !important;
    transition: background .3s ease(out-quint);
  }

  .Header--extended:not(.Header--sticky) & {
    top: rem-calc(295);
  }

  body.menu-open--desktop & {
    opacity: 1;
    visibility: visible;
    transition:
      color .3s ease(out-quint),
      opacity .5s ease(out-quint) .55s,
      visibility 0s linear;
  }
}
