/*! CardMedia component */


.CardMedia {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  &--video {
    .CardMedia-header {
      &::after {
        @include sprite('play', (fill: $color-white));
        background-size: rem-calc(10 10) !important;
        background-position: left 52% center !important;
        background-color: map-get($color-main, 'darkblue');
      }
    }
  }

  &--podcast {
    .CardMedia-header {
      &::after {
        @include sprite('podcasts', (fill: $color-white));
        background-size: rem-calc(20 23) !important;
        background-color: map-get($color-main, 'darkblue');
      }
    }
  }

  @include breakpoint(large) {
    &:hover, #{$keyboard} &:focus {
      .CardMedia-header::after {
        background-color: map-get($color-main, 'brick');
      }
    }
  }

  .MediasSlider:not(.MediasSlider--colored) &,
  .RelatedMedias--white & {
    box-shadow:
      rem-calc(0 6 12 -2) $shadow-soft,
      rem-calc(0 3 7 -3) $shadow-strong;
  }
}

.CardMedia-header {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: rem-calc(45);
    height: rem-calc(45);
    border-radius: 50%;
    background-color: map-get($color-main, 'darkblue');
    box-shadow: rem-calc(0 2 3 0) $shadow-strong;
    transform: translate(-50%, -50%);
    transition: background-color .3s ease(out-quint);
  }
}

.CardMedia-image {
  position: relative;
  height: 0;
  padding-top: 70%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    // object-fit polyfill for IE11
    font-family: 'object-fit: cover;';
  }
}

.CardMedia-mediasLength {
  position: absolute;
  top: calc(50% + #{rem-calc(35)});
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  font-size: rem-calc(16);
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1;
  color: $color-white;
  text-shadow: rem-calc(0 2 5) rgba($color-black, .5);

  @include breakpoint(medium) {
    font-size: rem-calc(20);
  }

  .icon {
    width: rem-calc(20);
    height: rem-calc(15);
    margin-right: rem-calc(10);

    @include breakpoint(medium) {
      width: rem-calc(27);
      height: rem-calc(20);
    }
  }
}

.CardMedia-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-left: rem-calc(25);
  padding-right: rem-calc(25);
  padding-bottom: rem-calc(30);
  background-color: $color-white;
}

.CardMedia-infos {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: rem-calc(15);
  font-size: rem-calc(10);
}

.CardMedia-mediaType {
  display: inline-block;
  padding: rem-calc(7 12);
  text-transform: uppercase;
  color: $color-white;
  background-color: map-get($color-main, 'darkblue');
  border-bottom-left-radius: rem-calc(5);
  border-bottom-right-radius: rem-calc(5);
}

.CardMedia-duration {
  display: inline-block;
  width: rem-calc(50);
  min-width: rem-calc(50);
  margin-left: rem-calc(20);
  padding-top: rem-calc(2);
  padding-bottom: rem-calc(2);
  border-radius: rem-calc(30);
  font-size: rem-calc(12);
  text-align: center;
  color: map-get($color-main, 'darkblue');
  background-color: map-get($color-secondary, 'lightgrey');
}

.CardMedia-text {
  height: rem-calc(160);
  max-height: rem-calc(160);
  overflow: hidden;
}

.CardMedia-title {
  margin-bottom: rem-calc(10);

  @include breakpoint(medium) {
    font-size: rem-calc(18);
  }
}

.CardMedia-button {
  margin-top: rem-calc(20);

  .Button {
    position: static;

    &::after {
      content: '';
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}


// Inline card style
.CardMedia--inline {
  flex-direction: row;
  align-items: flex-start;

  &.CardMedia--podcast {
    .CardMedia-header::after {
      top: auto;
      left: rem-calc(10);
      bottom: rem-calc(10);
      transform: translate(0, 0);
    }
  }

  @include breakpoint(medium) {
    height: rem-calc(190);
    overflow: hidden;
  }

  @include breakpoint(large) {
    &:hover, #{$keyboard} &:focus {
      .CardMedia-title {
        color: map-get($color-main, 'brick');
      }
    }
  }

  .CardMedia-header {
    width: rem-calc(130);
    min-width: rem-calc(130);
    margin-right: rem-calc(15);

    @include breakpoint(medium) {
      width: rem-calc(275);
      min-width: rem-calc(275);
      margin-right: rem-calc(30);
    }
  }

  .CardMedia-content {
    width: 100%;
    height: auto;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }

  .CardMedia-text {
    display: flex;
    flex-direction: column;
    height: auto;
    max-height: auto;
  }

  .CardMedia-infos {
    justify-content: flex-end;
    margin-bottom: 0;
  }

  .CardMedia-mediaType {
    display: none;
  }

  .CardMedia-heading {
    @include breakpoint(large) {
      margin-bottom: rem-calc(10);
      padding-bottom: rem-calc(5);
      border-bottom: 2px solid map-get($color-secondary, 'lavander');
    }
  }

  .CardMedia-title {
    margin-top: rem-calc(-22);
    margin-bottom: 0;
    padding-right: rem-calc(65);
    transition: color .2s ease(out-quint);
  }

  .CardMedia-date {
    color: rgba(map-get($color-main, 'darkblue'), .7);
  }

  .CardMedia-description {
    @include breakpoint(small only) {
      display: none;
    }
  }

  .CardMedia-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
