/*! QuizQuestions component */

.Question {
  margin-top: rem-calc(30);
  will-change: transform;
}

.Question-subtitle {
  margin-bottom: rem-calc(30);
  text-align: center;
  color: map-get($color-main, 'darkblue');
}

.Question-item {
  text-align: center;

  &:not(:last-child) {
    margin-bottom: rem-calc(10);
  }

  &--radio,
  &--checkbox {
    label {
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      @include breakpoint(medium) {
        max-width: rem-calc(300px);
      }
    }
  }

  &--radio {
    .Quiz--quiz & {
      input {
        #{$keyboard} &:focus + label {
          &::before {
            transform: translateY(0);
            border-radius: 5%;
            transition:
            border-radius .4s ease(out-quart),
            transform .5s ease(out-quart);
          }
        }
      }

      label {
        @extend .Button;
        @extend .Button--primary;

        @include breakpoint(small only) {
          width: 100%;
        }
      }
    }

    .Quiz--test & {
      input {
        #{$keyboard} &:focus + label {
          color: map-get($color-main, 'brick');
          border: 1px solid map-get($color-main, 'brick');
        }
      }

      label {
        display: block;
        max-width: auto;
        padding: rem-calc(12 25);
        font-size: rem-calc(16);
        text-align: left;
        color: map-get($color-main, 'darkblue');
        border: 1px solid map-get($color-main, 'darkblue');
        border-radius: rem-calc(10);
        transition:
          color .2s ease(out-quint),
          border .2s ease(out-quint);

        @include breakpoint(large) {
          &:hover {
            cursor: pointer;
            color: map-get($color-main, 'brick');
            border: 1px solid map-get($color-main, 'brick');
          }
        }
      }
    }
  }

  &--checkbox {
    &.Question-item--responseGood {
      label {
        font-weight: 800;
        color: map-get($color-secondary, 'green');
      }
    }

    &.Question-item--resultBad {
      label {
        @include sprite('quiz-badanswer', (fill: rgba($color-grey, .5)));
      }
    }

    &.Question-item--resultGood {
      label {
        @include sprite('quiz-goodanswer', (fill: rgba($color-grey, .5)));
      }
    }

    &.Question-item--resultBad,
    &.Question-item--resultGood {
      label {
        padding-left: rem-calc(55);
        background-size: rem-calc(15 15);
        background-position: left top rem-calc(3);

        @include breakpoint(large) {
          &:hover, #{$keyboard} &:focus {
            cursor: default;
          }
        }

        &::before {
          left: rem-calc(25);
        }

        &::after {
          left: rem-calc(30);
        }
      }
    }

    &:not([class*="--result"]) {
      label {
        @include breakpoint(large) {
          &:hover, #{$keyboard} &:focus {
            color: map-get($color-main, 'brick');
            cursor: pointer;
          }
        }
      }
    }

    label {
      position: relative;
      display: block;
      padding-left: rem-calc(30);
      text-align: left;
      color: map-get($color-main, 'darkblue');
      transition: color .2s ease(out-quint);

      &::before,
      &::after {
        content: '';
        position: absolute;
      }

      &::before {
        top: 0;
        left: 0;
        width: rem-calc(20);
        height: rem-calc(20);
        border: 1px solid map-get($color-main, 'darkblue');
      }

      &::after {
        top: rem-calc(5);
        left: rem-calc(5);
        width: rem-calc(10);
        height: rem-calc(10);
        background-color: map-get($color-main, 'darkblue');
        transform: scale(0);
        opacity: 0;
        transition:
          opacity .2s ease(out-quint),
          transform .15s ease(out-back);
      }
    }

    input {
      #{$keyboard} &:focus + label {
        color: map-get($color-main, 'brick');
      }
    }

    input {
      &:checked + label::after {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}

.Question-slider {
  max-width: rem-calc(500);
  margin-left: auto;
  margin-right: auto;

  .vue-slider-rail {
    background-color: #E0E0E0;
    border-radius: rem-calc(10);
  }

  .vue-slider-dot {
    #{$keyboard} &:focus {
      .vue-slider-dot-handle {
        background-color: map-get($color-main, 'brick');
      }
    }

    @include breakpoint(medium down) {
      transition: left .3s ease(out-quart) !important;
    }
  }

  .vue-slider-dot-handle {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $color-white;
    border: 1px solid map-get($color-main, 'darkblue');
    box-shadow: rem-calc(0 5 3 -2) $shadow-strong;

    @include breakpoint(large) {
      cursor: grab;
    }
  }

  $smileysType: (bad: #D74102, neutral: #404040, good: #00AC8C);
  @each $smileyType, $smileyColor in $smileysType {
    &--#{$smileyType} {
      .vue-slider-dot-handle {
        &::after {
          content: '';
          @include sprite('quiz-smiley#{$smileyType}', (fill: $smileyColor));
          position: absolute;
          left: 50%;
          bottom: calc(100% + #{rem-calc(10)});
          width: rem-calc(31);
          height: rem-calc(31);
          background-size: rem-calc(30 30);
          transform: translateX(-50%);
        }
      }
    }
  }

  .vue-slider-dot-tooltip-top {
    top: -5px;
  }

  .vue-slider-dot-tooltip-text {
    font-size: rem-calc(16);
    font-weight: 700;
    color: map-get($color-main, 'darkblue');

    @include breakpoint(medium) {
      white-space: nowrap;
    }
  }
}

$responsesType: (
  badAnswer: 'quiz-smileybad' #D74102,
  almostAnswer: 'quiz-smileyneutral' #404040,
  goodAnswer: 'quiz-smileygood' #00AC8C
);
.Question-responseStatus {
  font-size: rem-calc(22);

  #{$mouse} &:focus,
  #{$touch} &:focus {
    outline: none;
  }

  @each $responseType, $smileyInfos in $responsesType {
    &.#{$responseType} {
      display: flex;
      align-items: center;
      justify-content: center;
      color: nth($smileyInfos, 2);

      &::after {
        content: '';
        @include sprite(nth($smileyInfos, 1), (fill: nth($smileyInfos, 2)));
        display: inline-block;
        width: rem-calc(26);
        height: rem-calc(26);
        margin-left: rem-calc(10);

        @include breakpoint(medium) {
          width: rem-calc(31);
          height: rem-calc(31);
        }
      }
    }
  }

  @include breakpoint(medium) {
    font-size: rem-calc(26);
  }
}

.Question-responseLabel {
  margin-top: rem-calc(20);
  font-size: rem-calc(12);
  font-style: italic;
  text-align: center;
  color: map-get($color-main, 'darkblue');
}

.Question-responseMessage {
  margin-top: rem-calc(30);
  margin-bottom: rem-calc(30);
  text-align: center;
}

.Question-buttons {
  margin-top: rem-calc(30);
  text-align: center;
  will-change: transition;
}

.Question-button {
  @extend .Button;
  @extend .Button--primary;

  &[disabled] {
    opacity: .35;

    &:hover, #{$keyboard} &:focus {
      cursor: not-allowed;

      &::before {
        transform: translateY(50%);
      }
    }
  }

  @include breakpoint(small only) {
    width: 100%;
  }
}

// Question SliderIcon
.Question--slider,
.Question--sliderIcon {
  margin-top: rem-calc(50);

  .Question-subtitle {
    margin-top: rem-calc(-25);
    margin-bottom: rem-calc(45);
  }
}

// Question Unique
.Question--unique {
  .Question-subtitle {
    margin-top: rem-calc(-10);
  }
}

// Question Multiple
.Question--multiple {
  .Question-subtitle {
    margin-top: rem-calc(-25);
  }

  .Question-responseStatus {
    margin-bottom: rem-calc(30);
  }
}
