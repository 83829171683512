/*! Related Component */

.Related {
  margin-top: rem-calc(50);
  padding-top: rem-calc(50);
  padding-bottom: rem-calc(50);

  @include breakpoint(medium down) {
    .related-landing & {
      margin-left: rem-calc(-20);
      margin-right: rem-calc(-20);
    }
  }

  @each $color, $value in $color-layer {
    &--#{$color} {
      background-color: $value;
    }
  }
}

.Related-container {
  @include xy-grid-container($width: rem-calc(1100), $padding: rem-calc(40));

  @include breakpoint(large) {
    @include xy-grid-container($width: rem-calc(1100), $padding: rem-calc(60));
  }
}

.Related-title {
  margin-bottom: rem-calc(30);
  text-align: center;

  &::before {
    content: '';
    display: block;
    width: rem-calc(30);
    height: rem-calc(5);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: rem-calc(10);
    background-color: map-get($color-main, 'brick');
  }
}

.Related-slider {
  @include xy-gutters($negative: true, $gutters: rem-calc(10));
  overflow: visible;

  &.swiper-container-initialized {
    padding-bottom: rem-calc(50);
  }
}

.Related-list {
  &:not(.swiper-wrapper) {
    @include xy-grid;

    @include breakpoint(large) {
      @include xy-gutters($negative: true);
    }
  }
}

.Related-article {
  &:not(:last-child) {
    margin-bottom: rem-calc(20);
  }

  &.swiper-slide {
    height: auto;
    margin-bottom: 0;

    @include breakpoint(small only) {
      @include xy-cell(11, $gutters: rem-calc(10));
      max-width: rem-calc(265);
    }

    @include breakpoint(medium only) {
      @include xy-cell(5, $gutters: rem-calc(10));
    }
  }

  @include breakpoint(large) {
    @include xy-cell(4);

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
}

.Related-pagination {
  z-index: 1;
  position: absolute;
  text-align: center;

  &:not(.swiper-pagination-bullets) {
    display: none;
  }

  .swiper-pagination-bullet {
    width: rem-calc(10);
    height: rem-calc(10);
    background-color: map-get($color-main, 'darkblue');
    transform: scale(0);

    &.swiper-pagination-bullet-active-main {
      transform: scale(1);
    }

    &.swiper-pagination-bullet-active-prev,
    &.swiper-pagination-bullet-active-next {
      transform: scale(.66);
    }

    &.swiper-pagination-bullet-active-prev-prev,
    &.swiper-pagination-bullet-active-next-next {
      transform: scale(.33);
    }
  }

  .swiper-pagination-bullet-active-main.swiper-pagination-bullet-active {
    background-color: map-get($color-main, 'darkblue');
  }
}
