/*! RubricsStratums component */

.RubricStratums {
  margin-top: rem-calc(50);
  margin-bottom: rem-calc(50);
}

.RubricStratums-container {
  @extend %container;
}

.RubricStratums-item {
  background-color: map-get($color-secondary, 'silk');

  &:nth-child(odd) {
    background-color: map-get($color-secondary, 'lavander');
  }

  &:not(:last-child) {
    margin-bottom: rem-calc(30);
  }

  @include breakpoint(small only) {
    &.is-active {
      .RubricStratums-title::after {
        transform: translateY(-50%) rotateX(180deg);
      }
    }
  }

  @include breakpoint(medium) {
    position: relative;
    min-height: rem-calc(600);
    padding-top: rem-calc(40);
  }
}

.RubricStratums-title,
.RubricStratums-itemContent {
  margin-left: rem-calc(20);
  margin-right: rem-calc(20);

  @include breakpoint(medium) {
    width: rem-calc(295);
  }

  @include breakpoint(large) {
    width: rem-calc(325);
    margin-left: rem-calc(30);
    margin-right: rem-calc(30);
  }
}

.RubricStratums-title {
  display: inline-block;

  @include breakpoint(small only) {
    position: relative;
    display: flex;
    align-items: center;
    min-height: rem-calc(80);
    padding: rem-calc(10 35 10 0);

    &::after {
      @include sprite('angle', (fill: map-get($color-main, 'darkblue')));
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      width: rem-calc(25);
      height: rem-calc(25);
      background-size: rem-calc(15);
      transform: translateY(-50%);
      transition: transform .3s ease(out-quad);
    }
  }

  @include breakpoint(medium) {
    @include titleNet();
    margin-bottom: rem-calc(10);
  }
}

.RubricStratums-itemContent {
  display: flex;
  flex-direction: column;

  @include breakpoint(small only) {
    @include accordion-content;
    margin-left: 0;
    margin-right: 0;
  }

  @include breakpoint(medium) {
    height: rem-calc(465);
    max-height: rem-calc(465);
    padding-bottom: rem-calc(40);
  }
}

.RubricStratums-chapo {
  @include breakpoint(small only) {
    margin-left: rem-calc(20);
    margin-right: rem-calc(20);
    margin-bottom: rem-calc(15);
  }

  @include breakpoint(medium) {
    margin-bottom: rem-calc(30);
  }
}

.RubricStratums-menu {
  overflow: auto;
  overflow-x: hidden;
  padding-right: rem-calc(20);

  @include breakpoint(small only) {
    max-height: rem-calc(400);
    margin-left: rem-calc(20);
    margin-bottom: rem-calc(30);

    .is-drilldown {
      max-width: 100% !important;
    }
  }

  @include breakpoint(medium) {
    margin-right: rem-calc(-20);
    padding-right: rem-calc(20);

    .is-drilldown {
      max-width: rem-calc(295) !important;
    }
  }

  @include breakpoint(large) {
    margin-right: rem-calc(-30);
    padding-right: rem-calc(30);

    .is-drilldown {
      max-width: rem-calc(325) !important;
    }
  }
}

.RubricStratums-pushContents {
  padding: rem-calc(20);
  background-color: map-get($color-secondary, 'palerose');
  overflow: hidden;

  @include breakpoint(small only) {
    margin-top: rem-calc(30);
  }

  @include breakpoint(medium) {
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100% - #{rem-calc(335)});
    height: 100%;
    padding: rem-calc(30 30 40);
  }

  @include breakpoint(large) {
    width: calc(100% - #{rem-calc(385)});
    padding: rem-calc(60 60 40);
  }

  .RubricStratums-item:nth-child(odd) & {
    background-color: map-get($color-secondary, 'lightgrey');
  }
}

.RubricStratums-slider {
  @include xy-gutters($negative: true, $gutters: rem-calc(10));
  overflow: visible;
}

.RubricStratums-sliderItem {
  @include xy-cell(11, $gutters: rem-calc(10));
  height: auto;

  #{$mouse} &:focus,
  #{$touch} &:focus {
    outline: none;
  }

  @include breakpoint(small only) {
    max-width: rem-calc(265);
  }

  @include breakpoint(medium) {
    @include xy-cell(shrink, $gutters: rem-calc(10));
    width: rem-calc(275);
    max-height: rem-calc(450);
  }
}

.RubricStratums-sliderNav {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: rem-calc(20);

  @include breakpoint(large) {
    justify-content: flex-end;
    margin-top: rem-calc(10);
    padding-right: rem-calc(15);
  }
}

.RubricStratums-sliderButton {
  width: rem-calc(40);
  height: rem-calc(40);
  border-radius: 50%;
  color: map-get($color-main, 'brick');
  background-color: transparent;
  transition:
    color .3s ease(out-quint),
    background-color .3s ease(out-quint),
    opacity .3s ease(out-quint);

  &:not(.swiper-button-lock) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--prev {
    transform: rotate(180deg);
  }

  &.swiper-button-disabled {
    cursor: default;
    opacity: .5;
  }

  @include breakpoint(medium down) {
    display: none !important;
  }

  @include breakpoint(large) {
    &:not(.swiper-button-disabled) {
      &:hover, #{$keyboard} &:focus {
        color: $color-white;
        background-color: map-get($color-main, 'brick');
      }
    }
  }

  .icon {
    width: rem-calc(19);
    height: rem-calc(16);
  }
}

.RubricStratums-sliderPagination {
  text-align: center;
  white-space: nowrap;

  @include breakpoint(large) {
    margin-left: rem-calc(10);
    margin-right: rem-calc(10);
  }

  .swiper-pagination-bullet {
    width: rem-calc(10);
    height: rem-calc(10);
    margin: 0 4px;
    background-color: map-get($color-main, 'darkblue');
    transform: scale(0);
    transition: .2s transform, .2s left;

    &.swiper-pagination-bullet-active-main {
      transform: scale(1);
    }

    &.swiper-pagination-bullet-active-prev,
    &.swiper-pagination-bullet-active-next {
      transform: scale(.66);
    }

    &.swiper-pagination-bullet-active-prev-prev,
    &.swiper-pagination-bullet-active-next-next {
      transform: scale(.33);
    }
  }
}
