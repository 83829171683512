/*! QuickAccess component */

.QuickAccess-list {
  @include breakpoint(large) {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

.QuickAccess-item {
  font-size: rem-calc(12);

  @include breakpoint(medium down) {
    &:last-child {
      .QuickAccess-link::after {
        display: none;
      }
    }
  }

  &:not(:last-child) {
    @include breakpoint(large) {
      margin-right: rem-calc(15);
    }

    @include breakpoint(xlarge) {
      margin-right: rem-calc(30);
    }
  }
}

.QuickAccess-link {
  position: relative;
  transition: color .2s ease(out-quint);

  @include breakpoint(medium down) {
    display: flex;
    align-items: center;
    padding: rem-calc(15 20);
    color: $color-white;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: rem-calc(20);
      width: calc(100% - #{rem-calc(40)});
      height: 1px;
      background-color: rgba($color-white, .2);
      transition: background-color .3s ease(out-quint);
    }

    .MenuMobile-container--subrubricsSelected &,
    .MenuMobile-container--articlesSelected & {
      color: map-get($color-main, 'darkblue');

      &::after {
        background-color: rgba(map-get($color-main, 'darkblue'), .5);
      }
    }

    .icon {
      width: rem-calc(25);
      height: auto;
      margin-right: rem-calc(15);
    }
  }

  @include breakpoint(large) {
    display: inline-block;
    padding-top: rem-calc(22);
    color: map-get($color-main, 'darkblue');

    .icon {
      position: absolute;
      top: 0;
      left: 50%;
      width: auto;
      height: rem-calc(20);
      transform: translateX(-50%);
    }

    &:hover, #{$keyboard} &:focus {
      color: map-get($color-main, 'brick');
    }
  }
}
