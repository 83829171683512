/*! Global styles */

// set global containers parameters
%container {
  @include xy-grid-container($padding: rem-calc(40));

  @include breakpoint(large) {
    @include xy-grid-container($padding: rem-calc(60));
  }
}

// hide element only visually
.sr-only {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.main {
  display: block; // Fix "main" padding top on ie11
  padding-top: rem-calc(55);

  @include breakpoint(medium) {
    padding-top: rem-calc(100);
  }

  @include breakpoint(large) {
    padding-top: rem-calc(175);
  }

  .Header--extended + & {
    padding-top: 0;

    @include breakpoint(large) {
      padding-top: rem-calc(75);
    }
  }

  .Header--extended.Header--sticky + & {
    padding-top: rem-calc(130);

    @include breakpoint(medium) {
      padding-top: rem-calc(175);
    }

    @include breakpoint(large) {
      padding-top: rem-calc(275);
    }
  }
}

.heading {
  font-size: rem-calc(25);
  font-weight: 800;
  line-height: 1.15;
  color: map-get($color-main, 'darkblue');

  @include breakpoint(medium) {
    font-size: rem-calc(28);
  }

  span {
    display: block;
    margin-top: rem-calc(10);
    font-size: rem-calc(14);
    font-weight: 500;
    font-style: italic;

    @include breakpoint(medium) {
      margin-top: rem-calc(15);
    }
  }
}

.avoidance-link {
  &:not(:focus) {
    @extend .sr-only;

    & + .Header {
      top: 0;
    }
  }

  & + .Header {
    top: rem-calc(20);
  }
}
