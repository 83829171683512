/*! Rubrics component */

.Rubrics {
  @extend %container;
  margin-top: rem-calc(50);
  margin-bottom: rem-calc(50);

  @include breakpoint(large) {
    margin-bottom: rem-calc(90);
  }
}

.Rubrics-rubric {
  &:not(:last-child) {
    margin-bottom: rem-calc(20);
  }

  @include breakpoint(medium down) {
    background-color: map-get($color-secondary, 'silk');

    &:nth-child(even) {
      background-color: map-get($color-secondary, 'lavander');
    }

    &.is-active {
      .Rubrics-rubricTitle::after {
        transform: translateY(-50%) rotateX(180deg);
      }
    }
  }

  @include breakpoint(large) {
    @include xy-grid;
    @include xy-gutters($negative: true, $gutters: rem-calc(50));

    .Rubrics-rubricContent {
      background-color: map-get($color-secondary, 'silk');
    }

    &:not(:last-child) {
      margin-bottom: rem-calc(30);
    }

    &:nth-child(even) {
      .Rubrics-rubricContent {
        background-color: map-get($color-secondary, 'lavander');
      }
    }
  }
}

.Rubrics-rubricTitle {
  position: relative;
  padding: rem-calc(10 55 10 20);

  @include breakpoint(medium down) {
    display: flex;
    align-items: center;
    min-height: rem-calc(80);

    &::after {
      @include sprite('angle', (fill: map-get($color-main, 'darkblue')));
      content: '';
      position: absolute;
      top: 50%;
      right: rem-calc(10);
      width: rem-calc(25);
      height: rem-calc(25);
      background-size: rem-calc(15);
      transform: translateY(-50%);
      transition: transform .3s ease(out-quad);
    }
  }

  @include breakpoint(medium) {
    padding: rem-calc(20 75 20 30);
  }

  @include breakpoint(large) {
    @include xy-cell(3.9, $gutters: rem-calc(50));
    padding: rem-calc(60 0);
    text-align: right;
  }

  @include breakpoint(xlarge) {
    @include xy-cell(3.6, $gutters: rem-calc(50));
  }
}

.Rubrics-rubricDescription {
  .Rubrics-rubricTitle & {
    margin-top: rem-calc(10);
    color: map-get($color-main, 'darkblue');

    @include breakpoint(medium down) {
      display: none;
    }
  }

  .Rubrics-rubricContent & {
    margin-bottom: rem-calc(20);

    @include breakpoint(large) {
      display: none;
    }
  }
}

.Rubrics-rubricContent {
  @include breakpoint(medium down) {
    @include accordion-content;
    padding-left: rem-calc(20);
    padding-right: rem-calc(20);
    padding-bottom: rem-calc(20);
  }

  @include breakpoint(large) {
    @include xy-cell(8.1, $gutters: rem-calc(50));
    @include xy-grid;
    padding: rem-calc(60 50);
  }

  @include breakpoint(xlarge) {
    @include xy-cell(8.4, $gutters: rem-calc(50));
  }
}

.Rubrics-rubricArticles {
  @include breakpoint(medium down) {
    margin-bottom: rem-calc(20);
  }

  @include breakpoint(large) {
    @include xy-cell(6, $gutters: rem-calc(60));
  }
}

.Rubrics-rubricArticle {
  &:not(:last-child) {
    margin-bottom: rem-calc(20);
  }
}

.Rubrics-rubricArticleLink {
  @include sprite('arrow-small', (fill: map-get($color-main, 'brick')));
  display: block;
  padding-right: rem-calc(52);
  font-weight: 700;
  color: map-get($color-main, 'darkblue');
  background-size: rem-calc(12 9) !important;
  background-position: right rem-calc(0) top rem-calc(6);
  transition:
    color .2s ease(out-quint),
    background .2s ease(out-quint);

  @include breakpoint(large) {
    @include sprite('arrow-small', (fill: map-get($color-main, 'darkblue')));
    background-position: right rem-calc(15) top rem-calc(6) !important;

    &:hover, #{$keyboard} &:focus {
      @include sprite('arrow-small', (fill: map-get($color-main, 'brick')));
      color: map-get($color-main, 'brick');
    }
  }
}
