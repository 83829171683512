/*! SearchBlock Component */

.SearchBlock {
  position: relative;

  // Hero specific style
  .Hero:not(.Hero--big) & {
    max-width: rem-calc(650);

    @include breakpoint(medium down) {
      margin-top: rem-calc(30);
      margin-bottom: rem-calc(20);
    }
  }
  .Hero--big & {
    margin-bottom: rem-calc(20);
  }

  .Header-search & {
    @include breakpoint(medium) {
      opacity: 1;
      transition:
        visibility 0s linear,
        transform .5s ease(out-quint),
        opacity .5s ease(out-quint);
    }
  }

  .Header-search--hidden & {
    @include breakpoint(medium) {
      visibility: hidden;
      opacity: 0;
      transform: translateY(rem-calc(-5));
      transition:
        visibility 0s linear .3s,
        transform .3s ease(out-quint),
        opacity .3s ease(out-quint);
    }
  }

  .Header--extended:not(.Header--sticky) & {
    @include breakpoint(small only) {
      padding-top: rem-calc(150);
    }
  }
}

.SearchBlock-form {
  display: flex;
  border-radius: rem-calc(30);
  background-color: $color-white;
  overflow: hidden;
  transition:
    border-color .3s ease(out-quint),
    border-radius .3s ease(out-quint);

  &--is-filled {
    .SearchBlock-reset {
      display: block;
    }

    .SearchBlock-submit {
      @include sprite('search', (fill: $color-white));
      background-color: map-get($color-main, 'darkblue');

      @include breakpoint(large) {
        &:hover, #{$keyboard} &:focus {
          @include sprite('search', (fill: $color-white));
          background-color: map-get($color-main, 'brick');
        }
      }

      span {
        width: 100%;
        visibility: visible;
        transition: width 0s linear;
      }
    }
  }

  &--autocomplete-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 1px solid rgba(map-get($color-main, 'darkblue'), .5);
    transition:
      border-color .5s ease(out-quint),
      border-radius .5s ease(out-quint);

    & + .SearchBlock-autocomplete {
      opacity: 1;
      visibility: visible;
      transition:
        opacity .5s ease(out-quint),
        visibility 0s linear;
    }
  }
}

.SearchBlock-input {
  height: rem-calc(60);
  padding-left: rem-calc(20);
  color: map-get($color-main, 'darkblue');
  outline: none;

  @include breakpoint(small only) {
    font-size: rem-calc(16);
  }

  &::placeholder {
    font-size: rem-calc(16);
    font-style: italic;
    color: rgba(map-get($color-main, 'darkblue'), .7);
  }
}

.SearchBlock-reset {
  @include sprite('cross-thin', (fill: map-get($color-main, 'darkblue')));
  display: none;
  width: rem-calc(35);
  min-width: rem-calc(35);
  background-size: rem-calc(12 12) !important;

  @include breakpoint(large) {
    &:hover, #{$keyboard} &:focus {
      @include sprite('cross-thin', (fill: map-get($color-main, 'brick')));
    }
  }
}

.SearchBlock-submit {
  @include sprite('search', (fill: map-get($color-main, 'darkblue')));
  padding-right: rem-calc(52);
  padding-left: rem-calc(10);
  color: $color-white;
  background-size: rem-calc(22 22) !important;
  background-position: right rem-calc(20) center !important;
  transition: background-color .2s ease(out-quint);

  @include breakpoint(large) {
    &:hover, #{$keyboard} &:focus {
      @include sprite('search', (fill: map-get($color-main, 'brick')));
    }
  }

  span {
    display: block;
    width: 0;
    overflow: hidden;
    visibility: hidden;
    transition: width 0s linear .2s;

    @include breakpoint(medium down) {
      display: none;
    }
  }
}

.SearchBlock-autocomplete {
  z-index: 1;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding: rem-calc(10 5 20);
  background-color: $color-white;
  border: 1px solid map-get($color-main, 'darkblue');
  border-top: transparent;
  border-bottom-left-radius: rem-calc(30);
  border-bottom-right-radius: rem-calc(30);
  opacity: 0;
  visibility: hidden;
  transition:
    opacity .3s ease(out-quint),
    visibility 0s linear .3s;
}

.SearchBlock-autocompleteItem {
  em {
    font-style: normal;
    font-weight: 800;
  }
}

.SearchBlock-autocompleteLink {
  display: block;
  padding: rem-calc(5 15);
  color: map-get($color-main, 'darkblue');
  border-radius: rem-calc(3);
  transition:
    color .2s ease(out-quint),
    background-color .2s ease(out-quint);

  @include breakpoint(medium) {
    padding: rem-calc(3 20);
  }

  @include breakpoint(large) {
    &:hover, #{$keyboard} &:focus {
      color: map-get($color-main, 'brick');
    }
  }

  #{$keyboard} .active & {
    background-color: rgba(map-get($color-secondary, 'lavander'), .5);
  }
}


// Header specific style
.Header-search {
  .SearchBlock {
    @include breakpoint(small only) {
      z-index: -1;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      padding: rem-calc(75 20 30);
      background-color: $color-white;
      opacity: 0;
      visibility: hidden;
      transition:
        opacity .3s ease(out-quint),
        visibility 0s linear .3s;

      .Header--homepage:not(.Header--sticky) & {
        padding: rem-calc(150 20 30);
      }
    }
  }

  .SearchBlock-form {
    border: 1px solid map-get($color-main, 'darkblue');
    border-radius: rem-calc(30);

    @include breakpoint(medium) {
      border-radius: rem-calc(20);

      &--autocomplete-open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .SearchBlock-input {
    @include breakpoint(medium) {
      height: rem-calc(40);
    }
  }

  .SearchBlock-autocomplete {
    @include breakpoint(small only) {
      position: static;
      border-radius: 0;
      border: none;
    }

    @include breakpoint(medium) {
      border-bottom-left-radius: rem-calc(20);
      border-bottom-right-radius: rem-calc(20);
    }
  }

  body.search-open & {
    .SearchBlock {
      @include breakpoint(small only) {
        opacity: 1;
        visibility: visible;
        transition:
          opacity .3s ease(out-quint),
          visibility 0s linear;
      }
    }
  }
}
