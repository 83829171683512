// Landing Podcast page specific style

.landing_podcast-allPodcasts {
  @include breakpoint(large) {
    margin-left: rem-calc(40);
    margin-right: rem-calc(40);
  }

  .Pagination {
    margin-top: rem-calc(30);
  }
}

.landing_podcast-allPodcastsTitle {
  @include titleNet();
  margin-bottom: rem-calc(30);
}

.landing_podcast-allPodcastsList {
  @include breakpoint(large) {
    margin-left: rem-calc(40);
    margin-right: rem-calc(40);
  }

  li {
    @include breakpoint(medium down) {
      margin-bottom: rem-calc(20);

      .CardPodcast-description {
        display: none;
      }
    }

    &:not(:last-child) {
      margin-bottom: rem-calc(20);

      @include breakpoint(large) {
        margin-bottom: rem-calc(30);
      }
    }
  }
}
