/*! Hero component */

.Hero {
  z-index: 2;
  position: relative;
  padding-bottom: rem-calc(30);

  @include breakpoint(large) {
    min-height: rem-calc(380);
  }

  &--has-image {
    @include breakpoint(small only) {
      padding-bottom: rem-calc(180);
    }

    @include breakpoint(medium only) {
      padding-bottom: rem-calc(300);
    }

    .Hero-text {
      @include breakpoint(large) {
        @include xy-cell(7.5, $gutter-type: 'none');
      }
    }
  }

  &--big {
    padding-top: rem-calc(140);
    padding-bottom: rem-calc(100);

    @include breakpoint(medium) {
      padding-top: rem-calc(240);
      padding-bottom: rem-calc(130);
    }

    .Hero-content {
      justify-content: center;
    }

    .Hero-text {
      @include xy-cell(12, $gutter-type: 'none');
      max-width: rem-calc(750);
    }

    .Hero-image {
      top: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
    }
  }

  .Breadcrumbs {
    color: map-get($color-main, 'darkblue');
  }

  @each $color, $value in $color-layer {
    &--#{$color} {
      background-color: $value;

      @if $color == 'purple' or $color == 'brick' or $color == 'darkblue' {
        .Hero-title {
          color: $color-white;
        }

        .Breadcrumbs {
          color: $color-white;
        }
      }
    }
  }
}

.Hero-container {
  @extend %container;
}

.Hero-content {
  @include xy-grid;
}

.Hero-text {
  z-index: 1;
  position: relative;
  @include xy-cell(12, $gutter-type: 'none');
}

.Hero-title {
  @extend .h1-style;
  display: flex;
  margin-bottom: rem-calc(10);

  @include breakpoint(medium) {
    margin-top: rem-calc(45);
    margin-bottom: rem-calc(45);
  }

  body.playlist &,
  body.podcast &,
  body.playlist_podcast & {
    &::after {
      content: '';
      display: inline-block;
      width: rem-calc(35);
      min-width: rem-calc(35);
      height: rem-calc(35);
      margin-left: rem-calc(30);

      @include breakpoint(medium) {
        width: rem-calc(68);
        min-width: rem-calc(68);
        height: rem-calc(68);
      }
    }
  }

  body.playlist & {
    &::after {
      @include sprite('playlist', (fill: map-get($color-main, 'darkblue')));
      transform: translateY(rem-calc(3));
    }
  }

  body.podcast &,
  body.playlist_podcast & {
    &::after {
      @include sprite('podcasts', (fill: map-get($color-main, 'darkblue')));
    }
  }

}

.Hero-image {
  position: absolute;
  bottom: 0;
  right: 0;
  max-height: rem-calc(180);
  max-width: auto;
  object-fit: cover;
  // object-fit polyfill for IE11
  font-family: 'object-fit: cover;';

  @include breakpoint(medium) {
    max-height: rem-calc(300);
  }

  @include breakpoint(large) {
    @include xy-cell(5, $gutter-type: 'none');
    max-width: rem-calc(580);
    max-height: 100%;
  }
}
