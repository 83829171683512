/*! FAQ Tabs component */

.FaqTabs {
  margin-top: rem-calc(30);
  margin-bottom: rem-calc(30);

  @include breakpoint(large) {
    @include xy-grid;
    @include xy-gutters($negative: true, $gutters: rem-calc(60));
    align-items: flex-start;
  }
}

.FaqTabs-titles {
  @include breakpoint(large) {
    position: sticky;
    top: rem-calc(130);
    @include xy-cell(3.9, $gutter-type: none);
  }

  @include breakpoint(xlarge) {
    @include xy-cell(3.6, $gutter-type: none);
  }
}

.FaqTabs-title {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    width: rem-calc(20);
    height: rem-calc(20);
    border: rem-calc(10) solid transparent;
    border-left: rem-calc(10) solid map-get($color-main, 'darkblue');
    transform-origin: left center;
    transform: translateY(-50%) scaleX(0);
  }

  &:not(:last-child) {
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: calc(100% - #{rem-calc(25)});
      height: 1px;
      background-color: map-get($color-secondary, 'lavander');
      transition: background-color .3s ease(out-quint);
    }
  }

  &.is-active {
    &::before {
      transform: translateY(-50%) scaleX(1);
      transition: transform .3s ease(out-quint);
    }

    .FaqTabs-button {
      background-color: map-get($color-main, 'darkblue') !important;
    }

    .FaqTabs-buttonName {
      color: $color-white !important;
    }

    &::after {
      background-color: transparent;
    }
  }
}

.FaqTabs-button {
  display: block;
  padding: rem-calc(15 50 15 20);
  transition: background-color .3s ease(out-quint);

  #{$mouse} &:focus,
  #{$touch} &:focus {
    outline: none;
  }

  @include breakpoint(large) {
    padding: rem-calc(25 30);

    &:hover, #{$keyboard} &:focus {
      background-color: rgba(map-get($color-main, 'darkblue'), .03);

      .FaqTabs-buttonName {
        color: map-get($color-main, 'brick');
      }
    }
  }
}

.FaqTabs-buttonName {
  display: block;
  font-size: rem-calc(16);
  font-weight: 800;
  color: map-get($color-main, 'darkblue');
  transition: color .3s ease(out-quint);
}

.FaqTabs-buttonInfo {
  font-style: italic;
  color: map-get($color-main, 'brick');
}

.FaqTabs-content,
.FaqTabs .tabs-content {
  @include xy-cell(8.1, $gutters: rem-calc(50), $gutter-type: padding);
  align-self: stretch;
  padding-top: rem-calc(35);
  padding-bottom: rem-calc(35);
  background-color: rgba(map-get($color-main, 'darkblue'), .03);

  @include breakpoint(large) {
    @include xy-cell(8.1, $gutters: rem-calc(60), $gutter-type: padding);
  }

  @include breakpoint(xlarge) {
    @include xy-cell(8.4, $gutters: rem-calc(60), $gutter-type: padding);
  }
}

.FaqTabs-panel {

  @include tabs-panel;
  padding: 0;
}


// Accordion style for mobile devices
.FaqTabs {
  @include breakpoint(medium down) {
    .accordion-item {
      box-shadow: rem-calc(0 1 0 0) map-get($color-secondary, 'lavander');

      &.is-active {
        .accordion-title {
          &::before,
          &::after {
            background-color: $color-white;
          }

          &::after {
            transform: translateY(-50%) rotate(90deg) scaleY(0);
          }
        }

        .FaqTabs-button {
          background-color: map-get($color-main, 'darkblue');
        }

        .FaqTabs-buttonName {
          color: $color-white !important;
        }
      }
    }

    .accordion-title {
      position: relative;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: rem-calc(20);
        width: rem-calc(10);
        height: rem-calc(2);
        background-color: map-get($color-main, 'darkblue');
        transform: translateY(-50%);
      }

      &::after {
        transform: translateY(-50%) rotate(90deg) scaleY(1);
        transition: transform .3s ease(out-quint);
      }
    }

    .accordion-content {
      @include accordion-content;
      padding-top: rem-calc(10);
      padding-bottom: rem-calc(30);
    }
  }
}
