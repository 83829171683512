/*! MostSearched components */

.MostSearched {
  margin-bottom: rem-calc(40);

  // Hero specific style
  .Hero:not(.Hero--big) & {
    @include breakpoint(medium down) {
      margin-top: rem-calc(30);
      margin-bottom: rem-calc(-40);
    }
  }
}

.MostSearched-title {
  margin-bottom: rem-calc(15);
  font-size: rem-calc(18);
  font-weight: 700;
  color: map-get($color-main, 'darkblue');

  // Hero specific style
  .Hero:not(.Hero--big) & {
    color: $color-white;
  }

  .SearchResults & {
    @extend .h3-style;
    margin-bottom: rem-calc(30);
    padding-bottom: rem-calc(4);
    border-bottom: rem-calc(4) solid rgba(map-get($color-main, 'brick'), .2);
  }
}

.MostSearched-list {
  @include xy-grid;
  @include xy-gutters($negative: true, $gutters: rem-calc(10));

  @include breakpoint(medium) {
    @include xy-gutters($negative: true, $gutters: rem-calc(20));
  }
}

.MostSearched-word {
  @include xy-cell('shrink', $gutters: rem-calc(10));
  max-width: 100%;
  margin-bottom: rem-calc(10);

  @include breakpoint(medium) {
    @include xy-cell('shrink', $gutters: rem-calc(20));
  }
}

.MostSearched-link {
  display: inline-block;
  padding: rem-calc(10 20);
  font-size: rem-calc(12);
  line-height: 1.1;
  color: map-get($color-main, 'darkblue');
  border-radius: rem-calc(100);
  background-color: map-get($color-secondary, 'lightgrey');
  transition:
    color .3s ease(out-quint),
    background-color .3s ease(out-quint);

  &:not(:last-child) {
    margin-right: rem-calc(20);
  }

  @include breakpoint(large) {
    &:hover, #{$keyboard} &:focus {
      background-color: map-get($color-secondary, 'lavander');
    }
  }

  // Hero specific style
  .Hero & {
    background-color: $color-white;

    @include breakpoint(large) {
      &:hover, #{$keyboard} &:focus {
        background-color: map-get($color-main, 'brick');
        color: $color-white;
      }
    }
  }

  // SearchBlock no results specific style
  .SearchResults-mostSearchedWords & {
    @extend .Button;
    @extend .Button--secondary;
    @extend .Button--small;

    @include breakpoint(large) {
      &:hover, #{$keyboard} &:focus {
        background-color: transparent;
      }
    }
  }
}
