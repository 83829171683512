/*! MediasSlider component */

.MediasSlider {
  &:not(:first-child) {
    margin-top: rem-calc(30);
  }

  &:not(:last-child) {
    margin-bottom: rem-calc(30);
  }

  @each $color, $value in $color-secondary {
    &--#{$color} {
      background-color: $value;
    }
  }

  &--colored {
    padding: rem-calc(40 20 20 20);
    overflow: hidden;

    @include breakpoint(large) {
      padding: rem-calc(40 40 20 40);
    }
  }
}

.MediasSlider-title {
  @include titleNet();
  margin-bottom: rem-calc(30);
}

.MediasSlider-container {
  @include xy-gutters($negative: true, $gutters: rem-calc(10));

  @include breakpoint(medium) {
    @include xy-gutters($negative: true);
  }

  @include breakpoint(large) {
    margin-top: rem-calc(-10);
    margin-bottom: rem-calc(-20);
    padding-top: rem-calc(10);
    padding-bottom: rem-calc(20);
  }
}

.MediasSlider-item {
  @include xy-cell(11, $gutters: rem-calc(10));
  height: auto;

  #{$mouse} &:focus,
  #{$touch} &:focus {
    outline: none;
  }

  @include breakpoint(small only) {
    max-width: rem-calc(265);
  }

  @include breakpoint(medium) {
    @include xy-cell(shrink);
    width: rem-calc(275);
  }
}

.MediasSlider-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: rem-calc(20);

  @include breakpoint(large) {
    justify-content: flex-end;
    margin-top: rem-calc(10);
    padding-right: rem-calc(15);
  }
}

.MediasSlider-button {
  width: rem-calc(40);
  height: rem-calc(40);
  border-radius: 50%;
  color: map-get($color-main, 'brick');
  background-color: transparent;
  transition:
    color .3s ease(out-quint),
    background-color .3s ease(out-quint),
    opacity .3s ease(out-quint);

  &:not(.swiper-button-lock) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--prev {
    transform: rotate(180deg);
  }

  &.swiper-button-disabled {
    cursor: default;
    opacity: .5;
  }

  @include breakpoint(medium down) {
    display: none !important;
  }

  @include breakpoint(large) {
    &:not(.swiper-button-disabled) {
      &:hover, #{$keyboard} &:focus {
        color: $color-white;
        background-color: map-get($color-main, 'brick');
      }
    }
  }

  .icon {
    width: rem-calc(19);
    height: rem-calc(16);
  }
}

.MediasSlider-pagination {
  text-align: center;
  white-space: nowrap;

  @include breakpoint(large) {
    margin-left: rem-calc(10);
    margin-right: rem-calc(10);
  }

  .swiper-pagination-bullet {
    width: rem-calc(10);
    height: rem-calc(10);
    margin: 0 4px;
    background-color: map-get($color-main, 'darkblue');
    transform: scale(0);
    transition: .2s transform, .2s left;

    &.swiper-pagination-bullet-active-main {
      transform: scale(1);
    }

    &.swiper-pagination-bullet-active-prev,
    &.swiper-pagination-bullet-active-next {
      transform: scale(.66);
    }

    &.swiper-pagination-bullet-active-prev-prev,
    &.swiper-pagination-bullet-active-next-next {
      transform: scale(.33);
    }
  }
}
