/*! Contraceptive component */

.Contraceptive {
  @include xy-grid;
}

.Contraceptive-cell {
  position: relative;

  &:first-child {
    @include xy-cell(12, $gutter-type: none);

    @include breakpoint(large) {
      @include xy-cell(2/11, $gutter-type: none);
    }
  }

  &:last-child {
    @include xy-cell(auto, $gutter-type: none);
  }

  &:not(:last-child) {
    border-right: 1px solid map-get($color-main, 'darkblue');
  }

  &:not(:first-child) {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  @include breakpoint(medium down) {
    &:first-child {
      border-right: none;
    }

    &:not(:first-child) {
      @include xy-cell(12/9, $gutter-type: none);
      padding: rem-calc(15 35);
    }

    &:not(:last-child) {
      border-right: 1px solid rgba(map-get($color-main, 'darkblue'), .1);
    }
  }

  @include breakpoint(large) {
    @include xy-cell(1/11, $gutter-type: padding);
    padding-top: rem-calc(20);
    padding-bottom: rem-calc(20);

    &:not(.Contraceptive-infos) {
      padding-top: rem-calc(30);
      padding-bottom: rem-calc(30);
    }
  }

  .icon-contraceptive-false,
  .icon-contraceptive-true {
    width: rem-calc(30);
    height: rem-calc(30);
  }

  a:not([class]) {
    @include underline($inverted: false);
    background-image: linear-gradient(to right, map-get($color-main, 'brick'), map-get($color-main, 'brick'));
    color: map-get($color-main, 'darkblue');
    font-weight: 800;

    transition:
      background-size .3s ease(out-quad),
      color .2s ease(out-quint);

    @include breakpoint(large) {
      &:hover,
      #{$keyboard} &:focus {
        color: map-get($color-main, 'brick')
      }
    }
  }
}

.Contraceptive-infosHead {
  @include breakpoint(medium down) {
    background-color: rgba(map-get($color-main, 'darkblue'), .05);
  }

  @include breakpoint(large) {
    display: flex;
    align-items: flex-start;
    margin-bottom: rem-calc(10);
  }


  a:not([class]) {
    @include underline($inverted: false);
    background-image: linear-gradient(to right, map-get($color-main, 'brick'), map-get($color-main, 'brick'));

    .Contraceptive-name {
    color: map-get($color-main, 'darkblue');
    }

    transition:
      background-size .3s ease(out-quad),
      color .2s ease(out-quint);

    @include breakpoint(large) {
      &:hover .Contraceptive-name,
      #{$keyboard} &:focus .Contraceptive-name {
        color: map-get($color-main, 'brick')
      }
    }
  }
}

.Contraceptive-picto {
  margin-right: rem-calc(5);
  width: rem-calc(20);

  @include breakpoint(medium down) {
    display: none;
  }
}

.Contraceptive-name {
  color: map-get($color-main, 'darkblue');

  @include breakpoint(medium down) {
    display: inline-block;
    max-width: calc(100vw - #{rem-calc(55)});
    padding: rem-calc(10 20);
    transition: transform .3s ease(out-quint);
    position: sticky;
    left: 0px;
  }
}

.Contraceptive-description {
  font-size: rem-calc(12);

  @include breakpoint(medium down) {
    display: none;
  }
}

.Contraceptive-efficiency {
  font-size: rem-calc(26);
  color: map-get($color-main, 'darkblue');

  sup {
    font-size: rem-calc(14);
  }

  .icon-gauge {
    position: absolute;
    top: 50%;
    left: 50%;
    width: rem-calc(85);
    height: rem-calc(85);
    transform: translate(-50%, -50%) rotate(-90deg);
    stroke: map-get($color-main, 'darkblue');
    stroke-dasharray: 1130px;
    stroke-dashoffset: calc(1130px - (1130px * var(--efficiency) / 100));

    @include breakpoint(medium down) {
      display: none;
    }
  }
}

.Contraceptive-useFrequency {
  font-weight: 800;
  color: map-get($color-main, 'darkblue');

  @include breakpoint(medium) {
    font-size: rem-calc(16);
  }
}

.Contraceptive-price {
  font-size: rem-calc(16);
  color: map-get($color-main, 'darkblue');
}

.Contraceptive-tooltip {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem-calc(35);
  height: rem-calc(35);
  font-size: rem-calc(14);

  &[aria-expanded="true"] {
    span {
      color: $color-white;
      background-color: map-get($color-main, 'brick');
    }
  }

  @include breakpoint(large) {
    &:hover, #{$keyboard} &:focus {
      span {
        color: $color-white;
        background-color: map-get($color-main, 'brick');
      }
    }
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem-calc(25);
    height: rem-calc(25);
    color: map-get($color-main, 'brick');
    border-radius: 50%;
    box-shadow: 0 0 0 1px map-get($color-main, 'brick');
    transition:
      color .2s ease(out-quint),
      background-color .2s ease(out-quint);
  }
}
