/*! VideoPlayer component */

// VideoPlayer layout
.VideoPlayer {
  @extend %container;

  @include breakpoint(large) {
    margin-bottom: rem-calc(50);
  }
}

.VideoPlayer-content {
  position: relative;

  @include breakpoint(large) {
    @include xy-grid;
    justify-content: center;
  }
}

.VideoPlayer-player {
  @include breakpoint(medium down) {
    margin-bottom: rem-calc(30);
  }

  @include breakpoint(large) {
    @include xy-cell(auto, $gutter-type: none);
    margin-right: rem-calc(360);

    &:only-child {
      @include xy-cell(10, $gutter-type: none);
      margin-right: 0;
    }
  }

  @include breakpoint(xlarge) {
    margin-right: rem-calc(480);

    &:only-child {
      @include xy-cell(8, $gutter-type: none);
    }
  }
}

.VideoPlayer-accordionItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include breakpoint(medium down) {
    &.is-active .KaliopPlayer-infos::after {
      transform: rotateX(180deg);
    }
  }
}

.VideoPlayer-infos {
  display: block;
  width: 100%;
  padding-top: rem-calc(10);
  padding-bottom: rem-calc(5);
  text-align: left;
  border-bottom: solid 2px map-get($color-secondary, 'lavander');

  @include breakpoint(medium down) {
    outline: none;
  }

  @include breakpoint(large) {
    &:hover {
      cursor: auto;
    }
  }
}

.VideoPlayer-description {
  margin-top: rem-calc(10);

  @include breakpoint(medium down) {
    @include accordion-content;
  }

  @include breakpoint(large) {
    order: 2;
  }

  body.internet-explorer & {
    width: 100%;
  }
}

.VideoPlayer-transcription {
  margin-top: rem-calc(15);

  @include breakpoint(large) {
    order: 1;
  }
}

.VideoPlayer-playlist {
  background-color: map-get($color-secondary, 'lightgrey');

  @include breakpoint(medium down) {
    margin-left: rem-calc(-20);
    margin-right: rem-calc(-20);
  }

  @include breakpoint(large) {
    position: absolute;
    right: rem-calc(0);
    display: flex;
    flex-direction: column;
    width: rem-calc(330);
    height: 100%;
  }

  @include breakpoint(xlarge) {
    width: rem-calc(440);
  }
}

.VideoPlayer-related {
  background-color: map-get($color-secondary, 'lightgrey');
  padding: rem-calc(25 20 15);

  @include breakpoint(medium down) {
    margin-left: rem-calc(-20);
    margin-right: rem-calc(-20);
    padding: rem-calc(40 20 15);
  }

  @include breakpoint(large) {
    position: absolute;
    right: rem-calc(0);
    display: flex;
    flex-direction: column;
    width: rem-calc(330);
    height: 100%;
    padding: rem-calc(25);
  }

  @include breakpoint(xlarge) {
    width: rem-calc(440);
    padding: rem-calc(35 35 25);
  }
}

.VideoPlayer-relatedTitle {
  @include titleNet();
  display: inline-block;
  margin-bottom: rem-calc(20);
}

.VideoPlayer-relatedSlider {
  @include breakpoint(medium down) {
    @include xy-gutters($negative: true, $gutters: rem-calc(20));
    overflow: visible;
    margin-bottom: rem-calc(20);
  }

  @include breakpoint(large) {
    &.swiper-container {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.VideoPlayer-relatedList {
  @include breakpoint(large) {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    &.swiper-wrapper {
      display: block;
    }
  }
}

.VideoPlayer-relatedItem {
  @include breakpoint(small only) {
    @include xy-cell(10, $gutters: rem-calc(20));
  }

  @include breakpoint(medium only) {
    @include xy-cell(5, $gutters: rem-calc(20));
  }

  @include breakpoint(large) {
    &.swiper-slide {
      height: auto;
    }
  }

  &:not(:last-child) {
    margin-bottom: rem-calc(35);
  }

  &.is-active .KaliopPlayer-playlistButton {
    background-color: map-get($color-secondary, 'lavander');
  }
}

.VideoPlayer-relatedSliderPagination {
  z-index: 1;
  position: absolute;
  bottom: 0 !important;
  margin-top: rem-calc(20);
  text-align: center;

  .swiper-pagination-bullet {
    width: rem-calc(10);
    height: rem-calc(10);
    background-color: map-get($color-main, 'darkblue');
    transform: scale(0);

    &.swiper-pagination-bullet-active-main {
      transform: scale(1);
    }

    &.swiper-pagination-bullet-active-prev,
    &.swiper-pagination-bullet-active-next {
      transform: scale(.66);
    }

    &.swiper-pagination-bullet-active-prev-prev,
    &.swiper-pagination-bullet-active-next-next {
      transform: scale(.33);
    }
  }

  .swiper-pagination-bullet-active-main.swiper-pagination-bullet-active {
    background-color: map-get($color-main, 'brick');
  }

  .VideoPlayer-relatedSlider:not(.swiper-container-initialized) & {
    display: none;
  }
}

// MediaPlayer custom style for videos
.VideoPlayer {
  // Bloc player
  .KaliopPlayer-player {
    &.is-playing {
      .KaliopPlayer-playerButton {
        opacity: 0;
        transition:
          opacity .7s ease(out-quint),
          visibility 0s linear .7s;
      }
    }
  }

  .KaliopPlayer-playerButton {
    &::after {
      @include sprite('play', (fill: $color-white));
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: rem-calc(50);
      height: rem-calc(50);
      border-radius: 50%;
      background-color: map-get($color-main, 'darkblue');
      background-size: rem-calc(12) !important;
      background-position: left 55% center !important;
      box-shadow: rem-calc(0 2 3 0) $shadow-strong;
      transform: translate(-50%, -50%);
      transition: background-color .5s ease(out-quint);
    }

    @include breakpoint(large) {
      &:hover, #{$keyboard} &:focus {
        &::after {
          background-color: map-get($color-main, 'brick');
        }
      }
    }
  }

  .KaliopPlayer-player {
    @include breakpoint(medium) {
      position: relative;
      height: 0;
      padding-top: 56.25%;
    }

    video {
      width: 100%;
      height: auto;
      background-color: $color-black;

      @include breakpoint(small only) {
        max-height: 75vh;
      }

      @include breakpoint(medium) {
        position: absolute;
        top: 0;
        height: 100%;
      }
    }
  }

  // Bloc infos
  .KaliopPlayer-infos {
    position: relative;
    padding-right: rem-calc(70);

    @include breakpoint(medium down) {
      &::after {
        @include sprite('angle', (fill: map-get($color-main, 'darkblue')));
        content: '';
        position: absolute;
        top: rem-calc(3);
        right: 0;
        width: rem-calc(15);
        height: rem-calc(15);
        transition: transform .3s ease(out-quad);
      }
    }
  }

  .KaliopPlayer-infosTitle {
    margin-bottom: rem-calc(5);
    font-size: rem-calc(18);
    font-weight: 800;
    line-height: 1.2;
    color: map-get($color-main, 'darkblue');

    @include breakpoint(small only) {
      margin-bottom: rem-calc(5);
    }
  }

  .KaliopPlayer-infosDate {
    color: map-get($color-main, 'darkblue');
  }

  .KaliopPlayer-infosDuration {
    position: absolute;
    bottom: rem-calc(0);
    right: 0;
    width: rem-calc(50);
    padding-top: rem-calc(3);
    padding-bottom: rem-calc(3);
    border-radius: rem-calc(30);
    font-size: rem-calc(12);
    text-align: center;
    color: map-get($color-main, 'darkblue');
    background-color: map-get($color-secondary, 'lavander');

    @include breakpoint(large) {
      top: 0;
      bottom: auto;
    }
  }

  // Bloc transcription
  .KaliopPlayer-transcription {
    @extend .Button;
    @extend .Button--secondary;
    @extend .Button--small;
  }

  // Bloc autoplay
  .KaliopPlayer-autoplay {
    padding: rem-calc(25 20 15);

    @include breakpoint(medium) {
      padding: rem-calc(35 20 15);
    }

    @include breakpoint(large) {
      padding: rem-calc(25);
    }

    @include breakpoint(xlarge) {
      padding: rem-calc(35 35 15);
    }
  }

  .KaliopPlayer-autoplayTitle {
    @include titleNet();
    display: inline-block;
    margin-bottom: rem-calc(5);
  }

  .KaliopPlayer-autoplayCounter {
    display: inline-block;
    font-size: rem-calc(18);
    color: map-get($color-main, 'darkblue');

    &::before {
      content: '-';
      display: inline-block;
      margin-left: rem-calc(5);
      margin-right: rem-calc(5);
    }
  }

  .KaliopPlayer-autoplayToggle {
    label {
      display: inline-flex;
      align-items: center;
      font-size: rem-calc(16);
      font-weight: 800;
      color: map-get($color-main, 'darkblue');

      &:hover {
        cursor: pointer;

        span::after {
          background-color: map-get($color-main, 'darkblue');
        }
      }
    }

    input {
      @extend .sr-only;

      #{$keyboard} &:focus + span::after {
        background-color: map-get($color-main, 'darkblue');
      }

      &:checked + span {
        background-color: map-get($color-secondary, 'silk');

        &::after {
          background-color: map-get($color-main, 'brick') !important  ;
          transform: translateX(rem-calc(18));
        }
      }
    }

    span {
      position: relative;
      display: inline-block;
      width: rem-calc(40);
      height: rem-calc(20);
      margin-left: rem-calc(15);
      border-radius: rem-calc(20);
      background-color: map-get($color-secondary, 'lavander');
      transition: background-color .3s ease(out-quint);

      &::after {
        content: '';
        position: absolute;
        top: rem-calc(3);
        left: rem-calc(4);
        width: rem-calc(14);
        height: rem-calc(14);
        border-radius: 50%;
        background-color: rgba(map-get($color-main, 'darkblue'), .7);
        transition:
          transform .5s ease(out-quint),
          background .3s ease(out-quint);
      }
    }
  }

  // Bloc playlist
  .KaliopPlayer-playlist {
    @include breakpoint(large) {
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  .KaliopPlayer-playlistItem {
    &.is-active .KaliopPlayer-playlistButton {
      background-color: map-get($color-secondary, 'lavander');
    }
  }

  .KaliopPlayer-playlistButton {
    position: relative;
    display: flex;
    width: 100%;
    padding: rem-calc(15 20);
    text-align: left;
    transition: background-color .3s ease(out-quint);

    @include breakpoint(large) {
      padding: rem-calc(15 25);

      &:hover, #{$keyboard} &:focus {
        background-color: map-get($color-secondary, 'lavander');
      }
    }

    @include breakpoint(xlarge) {
      padding: rem-calc(15 35);
    }
  }

  .KaliopPlayer-playlistButtonHeader {
    width: rem-calc(150);
    min-width: rem-calc(150);
    height: rem-calc(100);
    margin-right: rem-calc(20);

    @include breakpoint(medium only) {
      width: rem-calc(250);
      min-width: rem-calc(250);
      height: rem-calc(160);
    }
  }

  .KaliopPlayer-playlistButtonImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    // object-fit polyfill for IE11
    font-family: 'object-fit: cover;';
  }

  .KaliopPlayer-playlistButtonTitle {
    font-weight: 800;
    color: map-get($color-main, 'darkblue');
    text-align: left;
    line-height: 1.3;

    @include breakpoint(medium only) {
      font-size: rem-calc(16);
    }

    @include breakpoint(xlarge) {
      font-size: rem-calc(16);
    }
  }

  .KaliopPlayer-playlistButtonDuration {
    position: absolute;
    top: rem-calc(80);
    left: rem-calc(110);
    width: rem-calc(50);
    padding-top: rem-calc(3);
    padding-bottom: rem-calc(3);
    border-radius: rem-calc(30);
    font-size: rem-calc(12);
    font-weight: 700;
    text-align: center;
    color: $color-white;
    background-color: rgba(map-get($color-main, 'darkblue'), .35);

    @include breakpoint(medium only) {
      top: rem-calc(144);
      left: rem-calc(210);
    }

    @include breakpoint(large) {
      left: rem-calc(115);
    }

    @include breakpoint(xlarge) {
      left: rem-calc(125);
    }
  }
}

// Page video specific style
.Hero + .VideoPlayer {
  margin-top: rem-calc(20);

  @include breakpoint(medium) {
    margin-top: rem-calc(50);
  }
}
