/*! Accordion component */

.Accordion-item {
  &:not(:last-child) {
    margin-bottom: rem-calc(10);
  }

  &.is-active {
    .Accordion-title::after {
      transform: translateY(-50%) rotateX(180deg);
    }
  }
}

.Accordion-title {
  position: relative;
  display: block;
  padding: rem-calc(20 65 20 20);
  font-size: rem-calc(16);
  font-weight: 800;
  color: map-get($color-main, 'darkblue');
  transition: color .3s ease(out-quint);

  @include breakpoint(medium) {
    padding: rem-calc(20 75 20 30);
  }

  @include breakpoint(large) {
    &:hover, #{$keyboard} &:focus {
      color: map-get($color-main, 'brick');

      &::before {
        box-shadow: inset rem-calc(0 0 0 1) map-get($color-main, 'brick');
      }

      &::after {
        @include sprite('angle', (fill: map-get($color-main, 'brick')));
        background-size: rem-calc(11);
      }
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: rem-calc(20);
    width: rem-calc(25);
    height: rem-calc(25);
    box-shadow: inset rem-calc(0 0 0 1) map-get($color-main, 'darkblue');
    border-radius: 50%;
    transform: translateY(-50%);
    transition: box-shadow .3s ease(out-quint);
  }

  &::after {
    @include sprite('angle', (fill: map-get($color-main, 'darkblue')));
    content: '';
    position: absolute;
    top: 50%;
    right: rem-calc(20);
    width: rem-calc(25);
    height: rem-calc(25);
    background-size: rem-calc(11) auto;
    transform: translateY(-50%);
    transition: transform .3s ease(out-quad);
  }
}

.Accordion-content {
  @include accordion-content;
  padding: rem-calc(0 20 30);

  @include breakpoint(medium) {
    padding: rem-calc(0 75 30 30);
  }

  h1, h2, h3, h4, h5, h6,
  p,
  ul, ol,
  figure,
  blockquote {
    margin-top: rem-calc(20);
    margin-bottom: rem-calc(20);

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

// Primary style
.Accordion--primary {
  .Accordion-item {
    border: 1px solid rgba(map-get($color-main, 'darkblue'), .3);
    border-radius: rem-calc(5);
  }
}

// Secondary style
.Accordion--secondary {
  .Accordion-item {
    border-radius: rem-calc(30);
    background-color: map-get($color-secondary, 'silk');
  }
}
