/*! Summary component */

.Summary {
  z-index: 3;
  position: relative;
  width: 100%;

  @include breakpoint(medium down) {
    transform: translateY(-50%);

    &--sticky {
      position: fixed;
      left: rem-calc(20);
      top: rem-calc(130);
      width: calc(100% - #{rem-calc(40)});

      & + .RichText-content,
      & + .LandingPage-content {
        padding-top: rem-calc(50) !important;
      }
    }
  }

  @include breakpoint(small only) {
    &--sticky {
      top: rem-calc(85);
    }
  }

  @include breakpoint(large) {
    width: rem-calc(360);
    min-width: rem-calc(360);
    margin-right: rem-calc(30);
  }

  @include breakpoint(xlarge) {
    margin-right: rem-calc(75);
  }
}

.Summary-container {
  position: relative;

  @include breakpoint(large) {
    top: 130px;
    position: sticky;
    max-height: calc(100vh - #{rem-calc(160)});
    padding-top: rem-calc(30);
    padding-bottom: rem-calc(30);
    overflow: auto;
    background-color: map-get($color-secondary, 'lightgrey');

    body.internet-explorer & {
      position: static;
      max-height: none;
    }
  }
}

.Summary-title {
  margin-bottom: rem-calc(15);
  padding-left: rem-calc(35);
  padding-right: rem-calc(35);
  font-size: rem-calc(25);
  font-weight: 700;
  letter-spacing: rem-calc(1);
  text-transform: uppercase;
  color: map-get($color-main, 'darkblue');

  @include breakpoint(medium down) {
    display: none;
  }
}

.Summary-toggle {
  position: relative;
  padding-left: rem-calc(20);
  padding-right: rem-calc(20);
  width: 100%;
  height: rem-calc(40);
  margin-bottom: 0;
  font-weight: 700;
  text-align: left;
  color: $color-white;
  border-radius: rem-calc(20);
  outline: none;
  background-color: map-get($color-main, 'darkblue');
  transition: border-radius .2s ease(out-quint) .05s;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: rem-calc(10);
    width: rem-calc(25);
    height: rem-calc(25);
    box-shadow: inset rem-calc(0 0 0 1) $color-white;
    border-radius: 50%;
    transform: translateY(-50%);
  }

  &::after {
    @include sprite('angle', (fill: $color-white));
    content: '';
    position: absolute;
    top: 50%;
    right: rem-calc(10);
    width: rem-calc(25);
    height: rem-calc(25);
    background-size: rem-calc(11) auto;
    transform: translateY(-50%);
    transition: transform .3s ease(out-quad);
  }

  &.hover {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    transition: border-radius .2s ease(out-quad);

    &::after {
      transform: translateY(-50%) rotateX(180deg);
    }
  }

  @include breakpoint(large) {
    display: none;
  }
}

.Summary-content {
  @include breakpoint(medium down) {
    visibility: hidden;
    position: absolute !important;
    top: 100%;
    left: 0;
    width: 100%;
    height: auto;
    max-height: calc(100vh - #{rem-calc(160)});
    background-color: map-get($color-secondary, 'lightgrey');
    opacity: 0;
    overflow: auto;
    transform-origin: top center;
    transform: scaleY(0);
    transition:
      opacity .3s ease(out-quint),
      transform 0s ease(out-quint) .3s,
      visibility 0s linear .3s;

      &.is-open {
      visibility: visible;
      border-bottom-left-radius: rem-calc(20);
      border-bottom-right-radius: rem-calc(20);
      opacity: 1;
      transform: scaleY(1);
      transition:
        opacity .3s ease(out-quint),
        transform .3s ease(out-quint),
        visibility 0s linear;
    }
  }

  @include breakpoint(small only) {
    max-height: calc(100vh - #{rem-calc(115)});
  }

  @include breakpoint(large) {
    position: static !important;
    padding-left: rem-calc(35);
    padding-right: rem-calc(35);
  }
}

.Summary-item {
  position: relative;

  @include breakpoint(medium down) {
    background: linear-gradient(to right, map-get($color-secondary, 'lavander') var(--scrollPercent), transparent var(--scrollPercent));
    transition: background .5s ease(out-quint);

    &:not(:last-child) {
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: rem-calc(20);
        width: calc(100% - #{rem-calc(40)});
        height: rem-calc(1);
        background-color: rgba(map-get($color-main, 'darkblue'), .5);
      }
    }
  }

  @include breakpoint(large) {
    margin-bottom: rem-calc(10);
    border-radius: rem-calc(5);
    overflow: hidden;
  }
}

.Summary-link {
  @include sprite('arrow-small', (fill: map-get($color-main, 'brick')));
  z-index: 1;
  position: relative;
  color: map-get($color-main, 'brick');
  line-height: 1.15;
  transition:
    background-color .5s ease(out-quint),
    background-position .5s ease(out-quint);

  @include breakpoint(large) {
    background-color: rgba(map-get($color-main, 'darkblue'), .1);

    &:hover, #{$keyboard} &:focus {
      background-color: rgba(map-get($color-main, 'darkblue'), .2);
    }
  }
}

.Summary-scroller {
  @include sprite('arrow-small', (fill: $color-white));
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: $color-white;
  background-color: map-get($color-main, 'darkblue');
  clip: rect(0 0 200px 0);
  transition:
    clip .5s ease(out-quint),
    background-position .5s ease(out-quint);

  @include breakpoint(medium down) {
    display: none !important;
  }
}

.Summary-link,
.Summary-scroller {
  display: block;
  padding: rem-calc(14 50 14 20);
  font-weight: 700;
  background-size: rem-calc(10 10);
  background-position: center right rem-calc(20);

  @include breakpoint(large) {
    &:hover, #{$keyboard} &:focus {
      background-position: center right rem-calc(15);
    }
  }
}
