/*! Breadcrumbs Component */
.Breadcrumbs {
  padding-top: rem-calc(20);
  padding-bottom: rem-calc(20);
}

.Breadcrumbs-list {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.Breadcrumbs-item {
  display: inline-flex;
  align-items: center;
  font-size: rem-calc(11);
  font-weight: 700;

  @include breakpoint(medium) {
    font-size: rem-calc(12);
  }

  &:not(:last-child)::after {
    content: ">";
    margin-left: rem-calc(7);
    margin-right: rem-calc(7);
    font-size: rem-calc(16);
  }
}

.Breadcrumbs-link {
  @include underline();
}
