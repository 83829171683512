.PillSelect {
  width: 100%;
  background-color: map-get($color-layer, "white");
  border: 0.5px solid map-get($color-main, "darkblue");
  border-radius: rem-calc(5);

  @include breakpoint(large) {
    width: rem-calc(336);
  }
}

.PillSelect-container {
  padding: rem-calc(14) rem-calc(20);
  background-color: map-get($color-layer, "white");
  border-radius: rem-calc(5);
  cursor: pointer;
}

.PillSelect-container--open {
  border-bottom: 0.5px solid map-get($color-main, "darkblue");
  border-radius: rem-calc(5) rem-calc(5) 0 0;

  .PillSelect-icon {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
  }
}

.PillSelect-optgroupList {
  max-height: rem-calc(400);
  overflow: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: rem-calc(5);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: rem-calc(5);
    background: map-get($color-main, "darkblue");
  }

  &::-webkit-scrollbar-track {
    background: map-get($color-layer, "white");
    border-radius: rem-calc(5);
  }
}

.PillSelect-optgroupList
  > li:last-child
  .PillSelect-optionsList
  > li:last-child {
  border-radius: 0 0 rem-calc(5) rem-calc(5);
}

.PillSelect-optgroupLabel {
  display: inline-block;
  width: 100%;
  padding: rem-calc(5) rem-calc(20);
  color: map-get($color-main, "darkblue");
  font-weight: 800;
  background-color: map-get($color-layer, "lightgrey");
}

.PillSelect-option {
  padding: rem-calc(5) rem-calc(20);
  color: $color-grey;
  background-color: map-get($color-layer, "white");
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 0.5px solid map-get($color-main, "darkblue");
  }

  &:hover {
    color: map-get($color-layer, "white");
    background-color: map-get($color-layer, "brick");
  }
}

.PillSelect-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.PillSelect-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: 0.5px solid map-get($color-main, "darkblue");
  border-radius: 50%;
  transition: transform 0.3s ease;

  svg {
    width: 12px;
    height: 12px;
    color: map-get($color-main, "darkblue");
  }
}
