/* Form component */

$input-error: #dc3545;

.Form {
  margin-top: rem-calc(50);
  margin-bottom: rem-calc(50);

  input,
  input[type="email"],
  select,
  textarea {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: rem-calc(25);
    padding-right: rem-calc(25);
    font-size: rem-calc(16);
    font-weight: 400;
    color: map-get($color-main, 'darkblue');
    border-radius: rem-calc(25);
    background-color: $color-white;
    box-shadow: inset rem-calc(0 0 0 1) map-get($color-main, 'darkblue');
    transition: box-shadow 0.4s ease(out-quint);

    &:focus {
      box-shadow:
        inset rem-calc(0 0 0 1) rgba(map-get($color-main, 'darkblue'), .5),
        rem-calc(0 0 0 4) rgba(map-get($color-main, 'darkblue'), .15);
      outline: none;
    }

    &::placeholder {
      font-size: rem-calc(16);
      font-style: italic;
      color: rgba(map-get($color-main, 'darkblue'), .7);
    }

    &.has-error {
      box-shadow:
        inset rem-calc(0 0 0 1) rgba($input-error, .75),
        rem-calc(0 0 0 4) rgba($input-error, .35);
    }
  }

  input,
  select {
    height: rem-calc(50);
  }

  textarea {
    height: rem-calc(250);
    padding-top: rem-calc(20);
    padding-bottom: rem-calc(20);
    resize: none;
  }
}

.Form-container {
  @extend %container;
}

.Form-content {
  @include xy-grid;
  justify-content: center;
}

.Form-grid {
  @include xy-cell(12, $gutter-type: none);

  @include breakpoint(large) {
    @include xy-cell(10, $gutter-type: none);
  }

  @include breakpoint(xlarge) {
    @include xy-cell(8, $gutter-type: none);
  }
}

.Form-item {
  &:not(:last-child) {
    margin-bottom: rem-calc(20);
  }

  & > label {
    display: inline-block;
    margin-bottom: rem-calc(5);
    font-size: rem-calc(12);
    font-weight: 700;
    text-transform: uppercase;
    color: map-get($color-main, 'darkblue');
  }

  & > ul {
    margin-top: rem-calc(5);
    font-size: rem-calc(12);
    font-weight: 700;
    color: $input-error;
  }

  &:not(.Form-checkbox) {
    label.required {
      &::after {
        content: '*';
        display: inline-block;
        margin-left: rem-calc(2);
        color: $input-error;
        vertical-align: super;
      }
    }
  }
}

.Form-item--submit {
  text-align: right;

  @include breakpoint(small only) {
    margin-top: rem-calc(35);
  }

  button {
    @extend .Button;
    @extend .Button--primary;
  }
}

.Form-legend {
  margin-top: rem-calc(5);
  font-size: rem-calc(12);

  a {
    @include underline($inverted: true);
    color: map-get($color-main, 'darkblue');
    transition:
      background-size .3s ease(out-quad),
      color .2s ease(out-quint);

    @include breakpoint(large) {
      &:hover,
      #{$keyboard} &:focus {
        color: map-get($color-main, 'brick')
      }
    }
  }
}

.Form-select {
  & > div {
    position: relative;

    &::after {
      @include sprite('angle', (fill: map-get($color-main, 'darkblue')));
      content: '';
      z-index: -1;
      position: absolute;
      top: 50%;
      right: rem-calc(15);
      width: rem-calc(30);
      height: rem-calc(30);
      background-size: rem-calc(14 14);
      box-shadow: inset rem-calc(0 0 0 1) map-get($color-main, 'darkblue');
      border-radius: 50%;
      transform: translateY(-50%);
      transition: transform .3s ease(out-quad);
    }
  }

  select {
    background-color: transparent;
  }
}

.Form-checkbox {
  input[type="checkbox"] {
    @extend .sr-only;

    #{$keyboard} &:focus + label {
      color: map-get($color-main, 'brick');
    }
  }

  input:checked + label::after {
    opacity: 1;
    transform: scale(1);
  }

  label {
    position: relative;
    display: inline-block;
    padding-left: rem-calc(30);
    color: map-get($color-main, 'darkblue');
    cursor: pointer;

    &::before,
    &::after {
      content: '';
      position: absolute;
    }

    &::before {
      top: 0;
      left: 0;
      width: rem-calc(20);
      height: rem-calc(20);
      border: 1px solid map-get($color-main, 'darkblue');
    }

    &::after {
      top: rem-calc(5);
      left: rem-calc(5);
      width: rem-calc(10);
      height: rem-calc(10);
      background-color: map-get($color-main, 'darkblue');
      transform: scale(0);
      opacity: 0;
      transition:
        opacity .2s ease(out-quint),
        transform .15s ease(out-back);
    }
  }
}
