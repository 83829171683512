/*! CardQuiz component  */

.CardQuiz {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: rem-calc(35 25 50);
  text-align: center;

  @include breakpoint(large) {
    padding: rem-calc(40 30 70);

    &:hover,
    #{$keyboard} &:focus {
      .Button::before {
        transform: translateY(0);
        border-radius: 5%;
        transition:
        border-radius .4s ease(out-quart),
        transform .5s ease(out-quart);
      }
    }
  }

  @each $color, $value in $color-layer {
    &--#{$color} {
      background-color: $value;
    }
  }
}

.CardQuiz-infos {
  margin-bottom: rem-calc(45);

  img {
    width: auto;
    height: rem-calc(65);
  }

  span {
    display: block;
    margin-top: rem-calc(10);
    font-size: rem-calc(25);
    font-weight: 800;
    color: map-get($color-main, 'darkblue');

    @include breakpoint(medium) {
      font-size: rem-calc(18);
    }
  }
}

.CardQuiz-title {
  font-size: rem-calc(18);
  color: map-get($color-main, 'darkblue');

  &::after {
    content: '';
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.CardQuiz-button {
  margin-top: rem-calc(30);

  .Button {
    width: 100%;
  }
}
