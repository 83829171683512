/*! GlossaryList component */

.Glossary {
  position: relative;
  @extend %container;

  @include breakpoint(medium) {
    padding-top: rem-calc(30);
    padding-bottom: rem-calc(50);
  }
}

.Glossary-letters {
  z-index: 2;
  position: relative;
  display: flex;

  &.sticky {
    position: fixed;
    top: rem-calc(75);

    @include breakpoint(medium) {
      top: rem-calc(130);
    }

    @include breakpoint(large) {
      body:not(.menu-hidden) & {
        transform: translateY(rem-calc(75));
        transition: transform .35s ease(out-quart);
      }
    }
  }

  @include breakpoint(small only) {
    position: absolute;
    right: 0;
    flex-direction: column;
    width: rem-calc(25);
    height: calc(100vh - #{rem-calc(95)});
    overflow: auto;

    &.to-bottom {
      position: absolute;
      top: auto;
      bottom: rem-calc(20);
    }
  }

  @include breakpoint(medium) {
    padding: rem-calc(10);
    margin-bottom: rem-calc(60);
    background-color: map-get($color-secondary, 'lavander');

    &.sticky {
      width: calc(#{$global-width} - #{rem-calc(60)});
      max-width: calc(100% - #{rem-calc(40)});

      & + .Glossary-definitions {
        margin-top: rem-calc(103);
      }
    }
  }

  @include breakpoint(large) {
    transition: transform .35s ease(out-quart) .12s;

    &.sticky {
      max-width: calc(100% - #{rem-calc(60)});

      & + .Glossary-definitions {
        margin-top: rem-calc(-177);
      }
    }

    body.menu-hidden & {
      &.sticky + .Glossary-definitions {
        margin-top: rem-calc(-107);
      }
    }
  }
}

.Glossary-letter {
  flex-grow: 1;
}

.Glossary-letterButton {
  display: inline-flex;
  width: 100%;
  height: 100%;
  font-size: rem-calc(12);
  font-weight: 700;
  line-height: 1;
  color: map-get($color-main, 'darkblue');
  transition: color .2s ease(out-quint);

  @include breakpoint(small only) {
    align-items: center;
    justify-content: center;
  }

  @include breakpoint(medium) {
    display: inline-block;
    font-size: rem-calc(20);
    text-align: center;
  }

  @include breakpoint(large) {
    font-size: rem-calc(26);
  }

  &:not(.Glossary-letterButton--inactive) {
    @include breakpoint(large) {
      &:hover, #{$keyboard} &:focus {
        color: map-get($color-main, 'brick');
      }
    }
  }

  &--inactive {
    color: rgba(map-get($color-main, 'darkblue'), 0.5);
  }
}

.Glossary-definitions {
  @include xy-grid();

  @include breakpoint(small only) {
    @include xy-gutters($negative: true, $gutters: rem-calc(40));
  }

  @include breakpoint(medium) {
    position: relative;
    padding-bottom: rem-calc(40);

    &:not(:last-child) {
      margin-bottom: rem-calc(40);
      border-bottom: rem-calc(1) solid rgba(map-get($color-main, 'darkblue'), .2);
    }
  }

  @include breakpoint(large) {
    margin-top: rem-calc(-285);

    &::before {
      position: relative;
      content: '';
      display: block;
      width: 100%;
      padding-top: rem-calc(285);
    }

    body.menu-hidden & {
      margin-top: rem-calc(-210);

      &::before {
        padding-top: rem-calc(210);
      }
    }
  }
}

.Glossary-definitionsLetter {
  @include xy-cell(12, $gutter-type: none);
  color: map-get($color-main, 'darkblue');

  @include breakpoint(small only) {
    padding: rem-calc(2 20);
    background-color: rgba(map-get($color-main, 'darkblue'), .1);
  }

  @include breakpoint(medium) {
    @include xy-cell(1, $gutters: 0);
    transform: translateY(rem-calc(-6));
  }
}

.Glossary-definitionsCol {
  @include xy-cell(12, $gutter-type: none);
  z-index: 1;
  position: relative;

  @include breakpoint(small only) {
    &:last-child {
      .Glossary-definitionsCol-item:last-child {
        border-bottom: none;
      }
    }
  }

  @include breakpoint(medium only) {
    @include xy-cell(11/2);

    &:nth-child(4) {
      @include xy-cell-offset(1);
    }
  }

  @include breakpoint(large) {
    @include xy-cell(11/4);
  }
}

.Glossary-definitionsCol-item {
  @include breakpoint(small only) {
    margin-right: rem-calc(25);
    padding-left: rem-calc(20);
    padding-right: rem-calc(20);
    border-bottom: rem-calc(1) solid rgba(map-get($color-main, 'darkblue'), .1);;
  }
}
