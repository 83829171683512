/*! RubricsStratumsMenu component */

.RubricStratumsMenu {
  position: relative;
  background-color: map-get($color-secondary, 'silk');

  .RubricStratums-item:nth-child(odd) & {
    background-color: map-get($color-secondary, 'lavander');
  }
}

.RubricStratumsMenu-wrapper {
  overflow: hidden;
  transition: height .25s ease(out-quart);
}

.RubricStratumsMenu-sub {
  background-color: map-get($color-secondary, 'silk');

  .RubricStratums-item:nth-child(odd) & {
    background-color: map-get($color-secondary, 'lavander');
  }
}

.RubricStratumsMenu-item {
  @include breakpoint(large) {
    &:not(:last-child) {
      margin-bottom: rem-calc(20);
    }
  }
}

.RubricStratumsMenu-sub {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition:
    transform .25s ease(out-quint),
    opacity .25s ease(out-quint),
    visibility 0s linear .25s;

  &--subrubrics {
    background-color: map-get($color-secondary, 'lavander');
  }

  &--articles {
    background-color: $color-white;
  }

  &.is-active {
    transform: translateX(-100%);
    opacity: 1;
    visibility: visible;
    transition:
      transform .35s ease(out-quint),
      opacity .35s ease(out-quint),
      visibility 0s linear;
  }
}

.RubricStratumsMenu-back {
  position: relative;
  margin-bottom: rem-calc(10);
  padding-left: rem-calc(20);
  color: map-get($color-main, 'brick');
  text-transform: uppercase;
  background: none;

  &::after {
    content: '';
    @include sprite('arrow-small', (fill: map-get($color-main, 'brick')));
    position: absolute;
    top: 50%;
    left: 0;
    width: rem-calc(11);
    height: rem-calc(11);
    transform: rotate(180deg) translateY(50%);
  }
}

.RubricStratumsMenu-parentName {
  display: block;
  margin-bottom: rem-calc(20);
  font-weight: 700;
  color: map-get($color-main, 'darkblue');
  line-height: 1.15;
}

.RubricStratumsMenu-link {
  @include sprite('arrow-small', (fill: map-get($color-main, 'brick')));
  display: block;
  width: 100%;
  text-align: left;
  color: map-get($color-main, 'darkblue');
  background-size: rem-calc(11 9) !important;
  background-position: right center;
  outline: none;
  transition: color .2s ease(out-quint);

  @include breakpoint(medium down) {
    padding: rem-calc(10 30 10 0);
  }

  @include breakpoint(large) {
    padding-right: rem-calc(30);

    &:hover, #{$keyboard} &:focus {
      cursor: pointer;
      color: map-get($color-main, 'brick');
    }
  }

  &:not(.RubricStratumsMenu-link--sub) {
    font-weight: 700;
  }
}
