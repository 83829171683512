/*! Footer component */

.Footer-top {
  background-color: map-get($color-main, 'darkblue');
  padding-top: rem-calc(30);
  padding-bottom: rem-calc(30);

  &--has_description {
    .Footer-logo {
      @include breakpoint(large) {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}

.Footer-bottom {
  background-color: $color-white;
  padding-top: rem-calc(15);
  padding-bottom: rem-calc(20);

  @include breakpoint(medium) {
    padding-bottom: rem-calc(15);
  }
}

.Footer-container {
  @extend %container;
}

.Footer-content {
  @include breakpoint(large) {
    @include xy-grid;
    @include xy-gutters($negative: true, $gutters: rem-calc(70));
    position: relative;
  }
}

.Footer-logo {
  display: inline-block;

  @include breakpoint(medium down) {
    margin-bottom: rem-calc(25);
  }

  @include breakpoint(large) {
    @include xy-cell(shrink, $gutters: rem-calc(70));
  }

  .logo {
    width: rem-calc(250);

    @include breakpoint(medium) {
      width: rem-calc(400);
    }

    @include breakpoint(large only) {
      width: rem-calc(320);
    }
  }
}

.Footer-menu {
  @include breakpoint(large) {
    @include xy-cell(auto, $gutters: rem-calc(70));
    order: 2;
  }
}

.Footer-menuContent {
  @include xy-gutters($negative: true, $gutters: rem-calc(40));

  @include breakpoint(medium) {
    @include xy-grid;
    @include xy-gutters($negative: true, $gutters: rem-calc(50));
  }

  @include breakpoint(xlarge) {
    @include xy-gutters($negative: true, $gutters: rem-calc(70));
  }
}

.Footer-menuCol {
  @include breakpoint(small only) {
    &:first-child {
      .Footer-menuItem:first-child {
        .Footer-menuLink {
          border-top: 1px solid rgba($color-white, .2);
        }
      }
    }
  }

  @include breakpoint(medium) {
    @include xy-cell(6, $gutters: rem-calc(50));
  }

  @include breakpoint(xlarge) {
    @include xy-cell(6, $gutters: rem-calc(70));
  }
}

.Footer-menuLink {
  display: flex;
  align-items: center;
  height: rem-calc(65);
  padding-left: rem-calc(20);
  padding-right: rem-calc(20);
  font-weight: 700;
  color: $color-white;
  border-bottom: 1px solid rgba($color-white, .2);
  transition: color .2s ease(out-quint);

  @include breakpoint(medium) {
    padding-left: rem-calc(30);
    padding-right: rem-calc(30);
  }

  @include breakpoint(large) {
    &:hover, #{$keyboard} &:focus {
      color: map-get($color-main, 'brick');
    }
  }
}

.Footer-description {
  margin-top: rem-calc(25);
  font-size: rem-calc(12);
  color: rgba($color-white, .6);

  @include breakpoint(large) {
    @include xy-cell(shrink, $gutters: rem-calc(70));
    order: 1;
    width: rem-calc(320);
    margin-top: rem-calc(62);
  }

  @include breakpoint(xlarge) {
    width: rem-calc(400);
    margin-top: rem-calc(70);
  }
}

.Footer-logoRF {
  display: inline-block;
  margin-right: rem-calc(30);

  .logo-rf {
    width: rem-calc(75);
  }
}

.Footer-logoSPF {
  display: inline-block;

  .logo-spf {
    width: rem-calc(105);
  }
}
