/*! Images Carousel Component */

.ImagesCarousel {
  position: relative;
}

.ImagesCarousel-container {
  padding-bottom: rem-calc(40);
}

.ImagesCarousel-content {
  align-items: center;
  margin-bottom: 0;
  padding-bottom: rem-calc(50);
}

.ImagesCarousel-item {
  @include xy-cell('shrink', $gutter-type: 'none');
  max-width: 85%;

  #{$mouse} &:focus,
  #{$touch} &:focus {
    outline: none;
  }

  @include breakpoint(medium) {
    max-width: 100%;
  }
}

.ImagesCarousel-figure {
  position: relative;

  img {
    width: auto !important;
    max-width: 100%;
    height: auto;
    max-height: 50vh;
  }

  figcaption {
    position: absolute;
    top: 100%;
    margin-top: rem-calc(15);
    font-size: rem-calc(12);
    font-style: italic;
  }
}

.ImagesCarousel-pagination {
  z-index: 1;
  position: absolute;
  text-align: center;

  body.internet-explorer & {
    width: 100% !important;
  }

  .swiper-pagination-bullet {
    width: rem-calc(10);
    height: rem-calc(10);
    background-color: map-get($color-main, 'darkblue');
    transform: scale(0);

    &.swiper-pagination-bullet-active-main {
      transform: scale(1);
    }

    &.swiper-pagination-bullet-active-prev,
    &.swiper-pagination-bullet-active-next {
      transform: scale(.66);
    }

    &.swiper-pagination-bullet-active-prev-prev,
    &.swiper-pagination-bullet-active-next-next {
      transform: scale(.33);
    }
  }

  .swiper-pagination-bullet-active-main.swiper-pagination-bullet-active {
    background-color: map-get($color-main, 'brick');
  }
}

.ImagesCarousel-button {
  z-index: 2;
  position: absolute;
  top: calc(50% - #{rem-calc(50)});
  display: flex;
  justify-content: center;
  align-items: center;
  width: rem-calc(45);
  height: rem-calc(45);
  border-radius: 50%;
  background-color: $color-white;
  box-shadow: rem-calc(0 5 10) rgba($color-black, 0.3);
  transition: background-color .3s ease(out-quint);

  &::after {
    background-color: $color-white;
  }

  @include breakpoint(large) {
    &:not(.swiper-button-disabled) {
      &:hover, #{$keyboard} &:focus {
        background-color: map-get($color-main, 'brick');

        .icon {
          color: $color-white;
          transition: color .3s ease(out-quint);
        }
      }
    }
  }

  &--prev {
    left: 0;
    transform: translateX(-25%);

    .icon {
      transform: rotate(180deg);
    }

    @include breakpoint(xlarge) {
      transform: translateX(-50%);
    }
  }

  &--next {
    right: 0;
    transform: translateX(25%);

    @include breakpoint(xlarge) {
      transform: translateX(50%);
    }
  }

  &.swiper-button-disabled {
    .icon {
      opacity: .3;
    }
  }

  .icon {
    z-index: 1;
    position: relative;
    width: rem-calc(11);
    height: rem-calc(9);
    color: map-get($color-main, 'brick');
    transition:
      opacity .2s ease(out-quint),
      color .2s ease(out-quint);
  }
}
