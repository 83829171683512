/*! Init page styles */

// Default box model
*,
:before,
:after {
  box-sizing: border-box;
}

// Document
html {
  font-size: $global-font-size;
}

body {
  font-family: $font-main;
  font-size: rem-calc(14);
  font-weight: 500;
  line-height: $global-lineheight;
  color: $color-grey;
  overflow-x: hidden; // Prevent horizontal scroll on every support

  // Optimize text rendering on all Browsers & OS
  @include osx-font-smoothing-grayscale;

  &.search-open {
    overflow: hidden;
  }

  &[class*='menu-open'] {
    overflow: hidden;
  }
}

// Prevent horizontal scroll on mobile support
.site-wrapper {
  @include breakpoint(medium down) {
    overflow-x: hidden;
  }
}

// Additionnal reset styles for very common stuff
// Normalize tries to only remove differences between browsers.
// If we want an actual reset, we need to reset the styles of
// just a handful of elements.
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: inherit;
  color: map-get($color-main, 'darkblue');
}

.h1-style {
  font-size: rem-calc(34);
  font-weight: 800;
  line-height: 1.05;

  @include breakpoint(medium) {
    font-size: rem-calc(70);
  }
}

.h2-style {
  font-size: rem-calc(25);
  font-weight: 800;
  line-height: 1.2;
}

.h3-style {
  font-size: rem-calc(16);
  font-weight: 800;
  line-height: 1.25;
}

p,
ul,
ol,
figure {
  margin: 0;
}

ul,
ol {
  list-style: none;
  padding-left: 0;
}

a,
button {
  color: inherit;
  text-decoration: none;
}

// Reset button styles.
// It takes a bit of work to achieve a neutral look.
button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;

  // show a hand cursor on hover
  &:hover {
    cursor: pointer;
  }
}

iframe {
  border: none;
}

details > summary {
  cursor: pointer;
  user-select: none;
}

// Here we contradict Normalize
fieldset {
  margin: 0;
  border: 0;
  padding: 0;
}

// Force images and frames to fit their container at most
img,
iframe {
  max-width: 100%;
}

// Other small fixes
::-ms-clear {
  display: none;
}
