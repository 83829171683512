/*! Chapo component */

.Chapo {
  @extend %container;
  margin-top: rem-calc(30);
  margin-bottom: rem-calc(50);
}

.Chapo-content {
  font-size: rem-calc(17);
  font-weight: 700;
  line-height: 1.6;
  color: map-get($color-main, 'darkblue');

  @include breakpoint(large) {
    font-size: rem-calc(25);
  }
}
