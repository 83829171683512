/*! Button component */

.Button {
  z-index: 1;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: rem-calc(14);
  font-weight: 700;
  line-height: 1.1;
  background-color: transparent;
  overflow: hidden;
  transition: color .2s ease(out-quint);

  &:not(:last-child) {
    margin-bottom: rem-calc(5);
  }

  body.internet-explorer & {
    height: 1px;
  }
}

.Button--primary,
.Button--secondary {
  min-height: rem-calc(45);
  padding: rem-calc(5 30);
  border-radius: rem-calc(100);

  &::before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200%;
    background-color: map-get($color-main, 'brick');
    border-radius: 40%;
    transform: translateY(50%);
    will-change: transform;
    transition:
      border-radius .3s ease(out-quart),
      transform .3s ease(out-quart);
  }

  &::after {
    z-index: -2;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: rem-calc(100);
  }

  @include breakpoint(large) {
    &:not([disabled]) {
      &:hover,
      #{$keyboard} &:focus {
        &::before {
          transform: translateY(0);
          border-radius: 5%;
          transition:
          border-radius .4s ease(out-quart),
          transform .5s ease(out-quart);
        }
      }
    }
  }

  &[disabled] {
    opacity: .5;
    cursor: not-allowed;
  }
}

.Button--primary {
  color: $color-white;

  @include breakpoint(large) {
    &:hover,
    #{$keyboard} &:focus {
      color: $color-white;
    }
  }

  &::after {
    background-color: map-get($color-main, 'darkblue');
  }
}

.Button--secondary {
  color: map-get($color-main, 'darkblue');

  &::after {
    box-shadow: inset rem-calc(0 0 0 1) map-get($color-main, 'darkblue');
  }

  @include breakpoint(large) {
    &:hover,
    #{$keyboard} &:focus {
      color: $color-white;
      transition: color .35s ease(out-quint);
    }
  }
}

.Button--tertiary {
  @include sprite('arrow-small', (fill: map-get($color-main, 'brick')));
  min-height: auto;
  padding: rem-calc(7 23 7 0);
  font-weight: 800;
  color: map-get($color-main, 'brick');
  background-color: transparent;
  background-size: rem-calc(11 9);
  background-position: center right;
  border-radius: rem-calc(100);
  transition: background-color .2s ease(out-quint);

  @include breakpoint(large) {
    padding: rem-calc(7 38 7 15);
    background-position: center right rem-calc(15);

    &:hover,
    #{$keyboard} &:focus {
      background-color: map-get($color-secondary, 'palerose');
    }
  }

  body.internet-explorer & {
    height: auto;
  }
}

.Button--back {
  min-height: auto;
  padding: rem-calc(7 0);
  font-weight: 800;
  color: map-get($color-main, 'brick');

  @include breakpoint(large) {
    padding: rem-calc(7 15);

    &:hover,
    #{$keyboard} &:focus {
      &::before {
        transform: translateY(0) translateX(-7px) rotate(180deg);
      }
    }
  }

  &::before {
    content: '';
    @include sprite('arrow', (fill: map-get($color-main, 'brick')));
    position: static;
    width: rem-calc(18);
    height: rem-calc(18);
    margin-right: rem-calc(15);
    border-radius: 0;
    background-color: transparent;
    background-size: contain;
    transform: translateY(0) rotate(180deg);
    transition:
      transform .3s ease(out-quart);
  }

  body.internet-explorer & {
    height: auto;
  }
}

.Button--icon {
  padding-left: rem-calc(20);

  .icon {
    z-index: 2;
    position: relative;
    width: rem-calc(22);
    min-width: rem-calc(22);
    height: auto;
    margin-right: rem-calc(10);
  }
}

.Button--small {
  min-height: rem-calc(36);
  padding: rem-calc(5 20);
  font-size: rem-calc(12);
}

.Button--external {
  min-height: rem-calc(50);
  padding-left: rem-calc(20);
  padding-right: rem-calc(70);
  color: map-get($color-main, 'darkblue');
  box-shadow: inset rem-calc(0 0 0 1) map-get($color-main, 'darkblue');
  border-radius: rem-calc(7);
  transition: box-shadow .2s ease(out-quart);

  @include breakpoint(large) {
    &:hover, #{$keyboard} &:focus {
      box-shadow: inset rem-calc(0 0 0 1) map-get($color-main, 'brick');

      &::after {
        background-color: map-get($color-main, 'brick');
      }
    }
  }

  img {
    width: auto;
    max-width: rem-calc(100);
    height: auto;
    max-height: rem-calc(40);

    &:not(:only-child) {
      margin-right: rem-calc(15);
    }

    @include breakpoint(medium) {
      max-width: rem-calc(120);
    }
  }

  &::after {
    @include sprite('external-link', (fill: $color-white));
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: rem-calc(50);
    height: 100%;
    background-color: map-get($color-main, 'darkblue');
    background-size: rem-calc(18 18);
    transition: background-color .2s ease(out-quart);
  }
}
