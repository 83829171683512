/*! Global layout */

.layout-container {
  @extend %container;
}

.layout-grid {
  margin-top: rem-calc(50);
  margin-bottom: rem-calc(50);

  @include breakpoint(large) {
    @include xy-grid;
    @include xy-gutters($negative: true, $gutters: rem-calc(60));
  }
}

.layout-sidebar {
  @include breakpoint(large) {
    @include xy-cell(3.9, $gutters: rem-calc(60));
  }

  @include breakpoint(xlarge) {
    @include xy-cell(3.6, $gutters: rem-calc(60));
  }

  .heading {
    @include breakpoint(medium down) {
      margin-bottom: rem-calc(20);
    }

    @include breakpoint(large) {
      text-align: right;
    }
  }
}

.layout-content {
  @include breakpoint(large) {
    @include xy-cell(12, $gutters: rem-calc(60));
  }
}

.layout-content--half {
  @include breakpoint(large) {
    @include xy-cell(8.1, $gutters: rem-calc(60));
  }

  @include breakpoint(xlarge) {
    @include xy-cell(8.4, $gutters: rem-calc(60));
  }
}

.layout-content--center {
  text-align: center;
}
