/*! RichText component */

$richtext-margin: (
  'small': rem-calc(30),
  'large': rem-calc(50)
);

.RichText {
  margin-bottom: rem-calc(50);

  .has-sidebar {
    @include breakpoint(medium down) {
      padding-top: 0;
    }

    .RichText-content {
      @include breakpoint(medium down) {
        padding-top: rem-calc(10);
      }

      @include breakpoint(large) {
        @include xy-cell(auto, $gutter-type: none);
        max-width: calc(100% - #{rem-calc(390)});
      }

      @include breakpoint(xlarge) {
        max-width: calc(100% - #{rem-calc(435)});
        padding-right: rem-calc(200);
      }
    }
  }

  .Chapo + & {
    margin-top: rem-calc(-50);

    @include breakpoint(medium) {
      margin-top: rem-calc(-30);
    }
  }
}

.RichText-container {
  @extend %container;
  @include xy-grid;
  justify-content: center;
  padding-top: rem-calc(30);

  @include breakpoint(large) {
    flex-wrap: nowrap;
  }
}

.RichText-content {
  @include xy-cell(12, $gutter-type: none);

  @include breakpoint(large) {
    @include xy-cell(10, $gutter-type: none);
  }

  @include breakpoint(xlarge) {
    @include xy-cell(8, $gutter-type: none);
  }
}

/*! RichText global style */
.RichText,
.LandingPage {
  h1, h2, h3, h4, h5, h6,
  p:not([class]),
  ul:not([class]), ol:not([class]),
  .ezimage-field,
  .highlighted,
  .Accordion,
  .bigImage,
  .Blockquote,
  .ButtonsBloc,
  .ImagesCarousel,
  .bigImage,
  .wrapper,
  .PodcastPlayer,
  .Quiz,
  .Steps,
  .VideoPlayer,
  .Tabs,
  .choose-contraceptive {
    margin-top: map-get($richtext-margin, 'small');
    margin-bottom: map-get($richtext-margin, 'small');

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    @include breakpoint(medium) {
      margin-top: map-get($richtext-margin, 'large');
      margin-bottom: map-get($richtext-margin, 'large');
    }
  }

  h1 {
    @extend .h1-style;
  }

  h2 {
    @include titleNet();

    & + *:not([class]),
    & + .ezimage-field,
    & + .highlighted,
    & + .Accordion,
    & + .Card,
    & + .ImagesCarousel,
    & + .Blockquote,
    & + .ButtonsBloc,
    & + .bigImage,
    & + .wrapper,
    & + .PodcastPlayer,
    & + .Quiz,
    & + .Steps,
    & + .VideoPlayer,
    & + .Tabs,
    & + .choose-contraceptive {
      margin-top: calc(-#{map-get($richtext-margin, 'small') - rem-calc(20)});

      @include breakpoint(medium) {
        margin-top: calc(-#{map-get($richtext-margin, 'large') - rem-calc(20)});
      }
    }
  }

  h3,
  h4,
  h5,
  h6 {
    @extend .h3-style;

    &:not([class]) {
      padding-bottom: rem-calc(4);
      border-bottom: rem-calc(4) solid rgba(map-get($color-main, 'brick'), .2);
    }

    &.related-title {
      margin-bottom: rem-calc(30);
      padding-left: rem-calc(25);
      border-left: rem-calc(5) solid rgba(map-get($color-main, 'brick'), .2);
      font-size: rem-calc(18);

      @include breakpoint(small only) {
        margin-bottom: rem-calc(10);
        padding-left: rem-calc(15);
      }
    }

    & + *:not([class]),
    & + .ezimage-field,
    & + .Accordion,
    & + .ImagesCarousel,
    & + .Blockquote,
    & + .ButtonsBloc,
    & + .big-image,
    & + .wrapper,
    & + .PodcastPlayer,
    & + .Quiz,
    & + .Steps,
    & + .VideoPlayer,
    & + .Tabs,
    & + .choose-contraceptive {
      margin-top: calc(-#{map-get($richtext-margin, 'small') - rem-calc(10)});

      @include breakpoint(medium) {
        margin-top: calc(-#{map-get($richtext-margin, 'large') - rem-calc(10)});
      }
    }

    & + .Card {
      margin-top: calc(-#{map-get($richtext-margin, 'small') - rem-calc(20)});

      @include breakpoint(medium) {
        margin-top: calc(-#{map-get($richtext-margin, 'large') - rem-calc(20)});
      }
    }
  }

  a:not([class]) {
    @include underline($inverted: true);
    color: map-get($color-main, 'brick');
    transition:
      background-size .3s ease(out-quad),
      color .2s ease(out-quint);

    @include breakpoint(large) {
      &:hover,
      #{$keyboard} &:focus {
        color: map-get($color-main, 'darkblue')
      }
    }
  }

  figure:not([class]),
  .ezimage-field {
    img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }

    figcaption {
      margin-top: rem-calc(15);
      font-size: rem-calc(12);
      font-style: italic;
    }
  }

  p:not([class]) {
    & + ul:not([class]),
    & + ol:not([class]) {
      margin-top: calc(-#{map-get($richtext-margin, 'small') - rem-calc(20)});

      @include breakpoint(medium) {
        margin-top: calc(-#{map-get($richtext-margin, 'large') - rem-calc(20)});
      }
    }
  }

  ul:not([class]),
  ol:not([class]) {
    & + p:not([class]) {
      margin-top: calc(-#{map-get($richtext-margin, 'small') - rem-calc(20)});

      @include breakpoint(medium) {
        margin-top: calc(-#{map-get($richtext-margin, 'large') - rem-calc(20)});
      }
    }

    li {
      position: relative;
      max-width: 100%;
      padding-left: rem-calc(25);
      text-overflow: ellipsis;
      overflow: hidden;

      &:not(:last-child) {
        margin-bottom: rem-calc(10);
      }
    }
  }

  ul:not([class]) {
    li {
      &::before {
        content: '';
        position: absolute;
        top: rem-calc(8);
        right: calc(100% - #{rem-calc(15)});
        width: rem-calc(5);
        height: rem-calc(5);
        background-color: map-get($color-main, 'brick');
        border-radius: 50%;
      }
    }
  }

  ol:not([class]) {
    counter-reset: ordered-list-increment;

    li {
      counter-increment: ordered-list-increment;

      &::before {
        content: counter(ordered-list-increment) '.';
        position: absolute;
        top: rem-calc(0);
        right: calc(100% - #{rem-calc(18)});
        font-weight: 800;
        color: map-get($color-main, 'brick');
      }
    }
  }

  .chapo {
    font-size: rem-calc(17);
    font-weight: 700;
    line-height: 1.6;
    color: map-get($color-main, 'darkblue');

    @include breakpoint(large) {
      font-size: rem-calc(25);
    }
  }

  .wrapper {
    @include xy-grid;
    @include xy-gutters($negative: true, $gutters: rem-calc(10));

    &--centered {
      @include xy-gutters($negative: true);
      justify-content: center;
    }

    & > p:not([class]) {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 0;

      @include breakpoint(small only) {
        width: 100%;
      }

      &:not(:last-child) {
        margin-bottom: rem-calc(10);
      }
    }

    a:not([class]),
    a[class=""],
    a.focus-visible {
      @extend .Button;
      @extend .Button--primary;
    }

    a:not([class]),
    a[class=""],
    a.focus-visible,
    .Button {
      @include xy-cell(12, $gutters: rem-calc(10));

      @include breakpoint(medium) {
        @include xy-cell(auto, $gutters: rem-calc(10));
      }
    }


    .PictoNumber {
      @include xy-cell(12);

      @include breakpoint(small only) {
        &:not(:last-child) {
          margin-bottom: rem-calc(20);
        }
      }

      @include breakpoint(medium) {
        @include xy-cell(4);
        margin-bottom: 0;
      }
    }
  }

  .highlighted {
    &--border {
      padding: rem-calc(20 38);
      border: 8px solid map-get($color-layer, 'lightblue');
    }

    &--background {
      padding: rem-calc(35 25);
      color: map-get($color-main, 'darkblue');
      background-color: map-get($color-secondary, 'yellow');

      @include breakpoint(large) {
        padding: rem-calc(40 70);
      }

      h2:first-child {
        text-align: center;
      }

      ul {
        li {
          &::before {
            content: '';
            position: absolute;
            top: rem-calc(6);
            right: calc(100% - #{rem-calc(12)});
            width: rem-calc(8);
            height: rem-calc(8);
            background-color: transparent;
            border-radius: 0;
            border: rem-calc(4) solid transparent;
            border-top: rem-calc(4) solid map-get($color-main, 'brick');
            border-right: rem-calc(4) solid map-get($color-main, 'brick');
            transform: rotate(45deg);
          }
        }
      }
    }

    h2 {
      padding-top: 0;

      &::after {
        display: none;
      }
    }

    h3, h4, h5, h6 {
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  .Accordion + .Accordion {
    margin-top: calc(-#{map-get($richtext-margin, 'small') - rem-calc(10)});

    @include breakpoint(medium) {
      margin-top: calc(-#{map-get($richtext-margin, 'large') - rem-calc(10)});
    }
  }

  .Card {
    height: rem-calc(110);
    padding-left: rem-calc(25);
    padding-top: rem-calc(20);
    border-left: rem-calc(5) solid rgba(map-get($color-main, 'brick'), .2);

    @include breakpoint(small only) {
      padding-left: rem-calc(15);
    }

    @include breakpoint(large) {
    }

    .Card-header {
      width: rem-calc(110);
      min-width: rem-calc(110);

      @include breakpoint(medium) {
        width: rem-calc(130);
      }

      @include breakpoint(large) {
        width: rem-calc(140);
      }
    }

    .Card-image {
      height: rem-calc(90);
    }
  }

  .bigImage {
    width: calc(100% + #{rem-calc(40)});
    margin-left: rem-calc(-20);

    img {
      width: 100%;
      max-height: rem-calc(600);
      object-fit: cover;
      // object-fit polyfill for IE11
      font-family: 'object-fit: cover;';
    }

    figcaption {
      margin-left: rem-calc(20);
      margin-right: rem-calc(20);
    }
  }

  .PodcastPlayer {
    .PodcastPlayer-player {
      margin-bottom: rem-calc(20);
    }

    .PodcastPlayer-player,
    .PodcastPlayer-detail {
      @include breakpoint(large) {
        @include xy-cell(12, $gutter-type: none);
      }
    }
  }

  .VideoPlayer,
  .PodcastPlayer {
    padding-left: 0;
    padding-right: 0;
  }

  .VideoPlayer-player {
    @include breakpoint(large) {
      &:only-child {
        @include xy-cell(12, $gutter-type: none);
        margin-right: 0;
      }
    }
  }

  .choose-contraceptive {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 110%;

    @include breakpoint(small only) {
      padding-top: 0;
      height: calc(100vh - #{rem-calc(165)});
    }

    @include breakpoint(xlarge) {
      width: calc(100% + #{rem-calc(200)});
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  // add top offset to RichText anchors
  h2.ez-has-anchor {
    padding-top: 0;

    &::after {
      top: rem-calc(185);
    }
  }

  .ez-has-anchor {
    position: relative;
    z-index: -1;

    &::before {
      content: '';
      display: block;
      margin-top: rem-calc(-180);
      padding-top: rem-calc(200);

      @include breakpoint(medium) {
        margin-top: rem-calc(-180);
      }
    }
  }

  .has-sidebar {
    .bigImage {
      width: calc(100% + #{map-get($grid-margin-gutters, 'small') * 2});
      margin-left: rem-calc(-20);

      @include breakpoint(large) {
        width: calc(100% + #{rem-calc(75)});
        margin-left: rem-calc(-30);
      }

      @include breakpoint(xlarge) {
        margin-left: rem-calc(-75);
        width: calc(100% + #{rem-calc(320)});
      }

      @include breakpoint(xxlarge) {
        width: calc(100% + #{rem-calc(320)} + ((100vw - #{$global-width}) / 2));
      }

      img {
        max-height: rem-calc(500);
      }

      figcaption {
        @include breakpoint(large) {
          margin-left: rem-calc(30);
          margin-right: rem-calc(15);
        }

        @include breakpoint(xlarge) {
          margin-left: rem-calc(75);
          width: 100%;
          max-width: calc(100% - (#{rem-calc(90) + rem-calc(200)} + ((100vw - #{$global-width}) / 2)));
        }
      }
    }

    .highlighted {
      &--background {
        @include breakpoint(xlarge) {
          width: calc(100% + #{rem-calc(200)});
        }
        }
    }

    .ButtonsBloc {
      @include breakpoint(xlarge) {
        width: calc(100% + #{rem-calc(200)});
      }
    }
  }
}

.ezrichtext-field {
  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }
}
