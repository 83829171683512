// ======
// Mixins
// ======

// Switch to grayscale font smoothing on OSX
@mixin osx-font-smoothing-grayscale {
  text-rendering: optimizeLegibility;
  text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

@mixin underline($inverted: false) {
  background-image: linear-gradient(to right, currentColor, currentColor);
  background-size: 0 1px;
  background-repeat: no-repeat;
  background-position: right bottom;
  transition: background-size .3s ease(out-quad);

  @if $inverted {
    background-size: 100% 1px;
  }

  @include breakpoint(large) {
    &:hover,
    #{$keyboard} &:focus {
      background-size: 100% 1px;
      background-position: left bottom;

      @if $inverted {
        background-size: 0 1px;
      }
    }
  }
}

@mixin titleNet($alignment: 'left') {
  position: relative;
  font-size: rem-calc(25);
  font-weight: 800;
  line-height: 1.2;
  padding-top: rem-calc(15);
  color: map-get($color-main, 'darkblue');

  @if $alignment == 'center' {
    text-align: center;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: rem-calc(30);
    height: rem-calc(5);
    background-color: map-get($color-main, 'brick');

    @if $alignment == 'center' {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
