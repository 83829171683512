.PillPage {
  .Chapo {
    padding: 0;
  }
}

.PillPage-container {
  display: flex;
  flex-direction: column;
  gap: rem-calc(24);
  padding: rem-calc(50) rem-calc(35);
  background-color: map-get($color-secondary, "lightgrey");
}

.PillPage-title {
  margin-bottom: rem-calc(6);

  &::before {
    content: "";
    display: block;
    width: rem-calc(30);
    height: rem-calc(5);
    margin-bottom: rem-calc(10);
    background-color: map-get($color-main, "brick");
  }
}

.PillPage-question {
  margin-top: rem-calc(50);
}

.PillPage-answers {
  display: flex;
  gap: rem-calc(12);
}

.PillPage-answerButton {
  @extend .Button;
  @extend .Button--secondary;
  margin-top: rem-calc(30);
  text-align: center;
  will-change: transition;

  &[disabled] {
    opacity: 0.35;

    &:hover,
    #{$keyboard} &:focus {
      cursor: not-allowed;

      &::before {
        transform: translateY(50%);
      }
    }
  }

  @include breakpoint(small only) {
    width: 100%;
  }
}

.PillPage-answerButton--selected {
  color: map-get($color-layer, "white");

  &::after {
    background-color: map-get($color-main, "brick");
    box-shadow: inset rem-calc(0 0 0 1) map-get($color-main, "brick");
  }
}

.PillPage-result {
  margin-top: rem-calc(50);
}

.PillPage-resultTitle {
  display: flex;
  gap: rem-calc(24);
}

.PillPage-resultIcon {
  display: flex;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;
    color: map-get($color-main, "darkblue");
    transform: rotate(90deg);
  }
}

.PillPage-resultContent {
  margin-top: rem-calc(20);
  padding: rem-calc(50) rem-calc(35);
  background-color: map-get($color-layer, "midblue");
}

.PillPage-resultContent .ezrichtext-field {
  p:not([class]) {
    margin-top: rem-calc(14);
  }

  ol {
    display: flex;
    flex-direction: column;
    gap: rem-calc(10);
    margin-top: rem-calc(35);
    list-style-position: inside;
  }

  li {
    padding: rem-calc(24) rem-calc(30) rem-calc(30);
    color: map-get($color-main, "darkblue");
    background-color: map-get($color-layer, "white");
    border: 0.5px solid map-get($color-main, "darkblue");
    border-radius: 5px;

    &::marker {
      font-size: rem-calc(25);
      font-weight: 800;
      color: map-get($color-layer, "brick");
    }
  }
}
