/*! GlossaryDefinition component */

.CardBevel-container {
  position: relative;
  padding-bottom: rem-calc(50);

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% - #{rem-calc(50)});
    height: rem-calc(55);
    background-color: $color-white;
    border-left: 4px solid map-get($color-secondary, 'palerose');
    border-bottom: 4px solid map-get($color-secondary, 'palerose');
  }

  &::after {
    content: '';
    position: absolute;
    top: bottom;
    right: 0;
    width: rem-calc(50);
    height: rem-calc(50);
    border-left: rem-calc(25) solid map-get($color-secondary, 'silk');
    border-top: rem-calc(25) solid map-get($color-secondary, 'silk');
    border-bottom: rem-calc(25) solid transparent;
    border-right: rem-calc(25) solid transparent;
  }
}

.CardBevel-content {
  padding-left: rem-calc(25);
  padding-right: rem-calc(25);
  padding-top: rem-calc(30);
  background-color: $color-white;
  border: rem-calc(4) solid map-get($color-secondary, 'palerose');
  border-bottom-color: transparent;
}

.CardBevel-title {
  position: relative;
  display: block;
  margin-bottom: rem-calc(20);
  padding-right: rem-calc(50);
  font-size: rem-calc(18);
  font-weight: 800;
  line-height: 1.1;
  color: map-get($color-main, 'darkblue');

  span {
    display: block;
    margin-top: rem-calc(10);
    font-size: rem-calc(12);
    font-weight: 500;
    color: map-get($color-main, 'brick');
  }
}

.CardBevel-text {
  font-size: rem-calc(14);
  color: map-get($color-main, 'darkblue');
}

.CardBevel-link {
  @extend .Button;
  @extend .Button--tertiary;
  margin-top: rem-calc(20);
}
