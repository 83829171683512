/*! ContraceptivesTable component */

.ContraceptivesTable {
  @extend %container;
}

.ContraceptivesTable-container {
  @include breakpoint(medium down) {
    padding-bottom: rem-calc(10);
    overflow-x: auto;
  }
}

.ContraceptivesTable-table {
  border: 1px solid map-get($color-main, 'darkblue');
  border-radius: rem-calc(10);

  @include breakpoint(medium down) {
    display: inline-block;
    min-width: rem-calc(1200);
  }
}

.ContraceptivesTable-tableHead {
  @include xy-grid;
  background-color: map-get($color-main, 'darkblue');
  border-top-left-radius: rem-calc(9);
  border-top-right-radius: rem-calc(9);
  overflow: hidden;
  transition: transform .2s ease(out-quint);

  @include breakpoint(medium down) {
    z-index: 2;
    position: relative;
    transform: translateY(var(--topTranslate));
  }
}

.ContraceptivesTable-tableHeading {
  @include xy-cell(12/9, $gutter-type: padding);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: rem-calc(10);
  padding-bottom: rem-calc(30);
  text-align: center;
  font-weight: 800;
  color: $color-white;
  background-color: map-get($color-main, 'darkblue');

  &:first-child {
    @include breakpoint(medium down) {
      display: none;
    }

    @include breakpoint(large) {
      @include xy-cell(2/11, $gutter-type: none);
      border-top-left-radius: rem-calc(10);
    }
  }

  &:not(:last-child) {
    border-right: 1px solid map-get($color-main, 'darkblue');
  }

  &:last-child {
    @include xy-cell(auto, $gutter-type: padding);
  }

  @include breakpoint(large) {
    @include xy-cell(1/11, $gutter-type: padding);
  }
}

.ContraceptivesTable-tableHeading--sort {
  position: relative;

  @include breakpoint(large) {
    transition: background-color .3s ease(out-quint);

    &:hover, #{$keyboard} &:focus {
      background-color: map-get($color-main, 'brick');
    }
  }
}

.ContraceptivesTable-tableHeading--asc,
.ContraceptivesTable-tableHeading--desc {
  background-color: map-get($color-main, 'brick');
}

.ContraceptivesTable-tableHeadingSort {
  position: absolute;
  bottom: rem-calc(5);
  left: 0;
  width: 100%;
  text-align: center;
  font-size: rem-calc(12);
  font-weight: 500;
  text-transform: uppercase;
  pointer-events: none;

  &::after {
    content: '-';
    display: inline-block;
    margin-left: rem-calc(5);
    transition: transform .3s ease(out-quad);
  }

  .ContraceptivesTable-tableHeading--asc &,
  .ContraceptivesTable-tableHeading--desc & {
    &::after {
      @include sprite('angle', (fill: $color-white));
      content: '';
      width: rem-calc(10);
      height: rem-calc(10);
      margin-left: rem-calc(10);
    }
  }

  .ContraceptivesTable-tableHeading--asc & {
    &::after {
      transform: rotateX(180deg);
    }
  }
}

.ContraceptivesTable-item {
  &:not(:last-child) {
    border-bottom: 1px solid map-get($color-main, 'darkblue');
  }

  @include breakpoint(large) {
    &:nth-child(odd) {
      background-color: rgba(map-get($color-main, 'darkblue'), .05);
    }
  }
}
