/*! PodcastPlayer component */

// PodcastPlayer layout
.PodcastPlayer {
  @extend %container;
  margin-top: rem-calc(50);
  margin-bottom: rem-calc(50);
}

.PodcastPlayer-content {
  @include breakpoint(large) {
    @include xy-grid;
    justify-content: center;
  }
}

.PodcastPlayer-player,
.PodcastPlayer-detail,
.PodcastPlayer-knowMore,
.PodcastPlayer-playlist {
  @include breakpoint(large) {
    @include xy-cell(10, $gutter-type: none);
  }

  @include breakpoint(xlarge) {
    @include xy-cell(8, $gutter-type: none);
  }
}

.PodcastPlayer-player {
  margin-bottom: rem-calc(50);
}

.PodcastPlayer-accordionItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include breakpoint(medium down) {
    &.is-active .KaliopPlayer-infos::after {
      transform: rotateX(180deg);
    }
  }
}

.PodcastPlayer-infos {
  display: block;
  width: 100%;
  padding-bottom: rem-calc(5);
  text-align: left;
  border-bottom: solid 2px map-get($color-secondary, 'lavander');

  @include breakpoint(medium down) {
    outline: none;
  }

  @include breakpoint(large) {
    &:hover {
      cursor: auto;
    }
  }
}

.PodcastPlayer-description {
  margin-top: rem-calc(10);

  @include breakpoint(medium down) {
    @include accordion-content;
  }

  @include breakpoint(large) {
    order: 2;
  }
}

.PodcastPlayer-transcription {
  margin-top: rem-calc(15);

  @include breakpoint(large) {
    order: 1;
  }
}

// Podcast landing page detail
.PodcastPlayer-detail {
  position: relative;
  display: flex;
  flex-wrap: nowrap;

  &:hover, #{$keyboard} &:focus {
    .PodcastPlayer-detailHeader {
      &::after {
        background-color: map-get($color-main, 'brick');
      }
    }

    .PodcastPlayer-detailTitle {
      color: map-get($color-main, 'brick');
    }
  }
}

.PodcastPlayer-detailHeader {
  position: absolute;
  top: 0;
  left: 0;
  width: rem-calc(113);
  min-width: rem-calc(130);
  height: rem-calc(90);
  margin-bottom: 0;

  @include breakpoint(large) {
    position: relative;
    width: rem-calc(275);
    min-width: rem-calc(275);
    height: rem-calc(193);
    margin-right: rem-calc(30);
  }

  &::after {
    @include sprite('play', (fill: $color-white));
    content: '';
    position: absolute;
    bottom: rem-calc(10);
    left: rem-calc(10);
    width: rem-calc(45);
    height: rem-calc(45);
    border-radius: 50%;
    background-color: map-get($color-main, 'darkblue');
    background-size: rem-calc(10 10) !important;
    background-position: left 55% center !important;
    box-shadow: rem-calc(0 2 3 0) $shadow-strong;
    transition: background-color .3s ease(out-quint);
  }
}

.PodcastPlayer-detailImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  // object-fit polyfill for IE11
  font-family: 'object-fit: cover;';
}

.PodcastPlayer-detailContent {
  z-index: 1;
  width: 100%;
  padding-top: rem-calc(3);

  @include breakpoint(medium down) {
    display: flex;
    flex-direction: column;
  }
}

.PodcastPlayer-detailInfos {
  display: block;

  @include breakpoint(medium down) {
    min-height: rem-calc(90);
    padding-left: rem-calc(145);
  }

  @include breakpoint(large) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: rem-calc(10);
    padding-bottom: rem-calc(5);
    border-bottom: 2px solid map-get($color-secondary, 'lavander');
  }
}

.PodcastPlayer-detailTitle {
  display: inline-block;
  max-width: calc(100% - #{rem-calc(70)});
  text-align: left;
  color: map-get($color-main, 'darkblue');
  transition: color .2s ease(out-quint);

  @include breakpoint(small only) {
    margin-bottom: rem-calc(5);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.PodcastPlayer-detailDuration {
  width: rem-calc(50);
  min-width: rem-calc(50);
  margin-left: rem-calc(20);
  padding-top: rem-calc(3);
  padding-bottom: rem-calc(3);
  border-radius: rem-calc(30);
  font-size: rem-calc(12);
  text-align: center;
  color: map-get($color-main, 'darkblue');
  background-color: map-get($color-secondary, 'lavander');
  transform: translateY(-2px);

  @include breakpoint(medium down) {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(0);
  }
}

.PodcastPlayer-detailDate {
  width: 100%;
  color: rgba(map-get($color-main, 'darkblue'), .7);
}

.PodcastPlayer-detailTranscription {
  @include breakpoint(medium down) {
    order: 2;
  }

  @include breakpoint(large) {
    margin-top: rem-calc(15);
    margin-bottom: rem-calc(15);
  }
}

.PodcastPlayer-detailDescription {
  @include breakpoint(medium down) {
    order: 1;
    margin-top: rem-calc(15);
    margin-bottom: rem-calc(25);
  }
}

.PodcastPlayer-knowMore {
  margin-top: rem-calc(50);
  text-align: center;
}

// MediaPlayer custom style for podcasts
.PodcastPlayer {
  .KaliopPlayer-mediaPlayer {
    width: 100%;
    transition:
      transform .35s ease(out-quart) .12s,
      opacity .3s ease(out-quint);

    &.sticky {
      z-index: 2;
      position: fixed;
      top: rem-calc(75);
      width: calc(100% - #{rem-calc(40)});

      @include breakpoint(medium) {
        top: rem-calc(130);
      }

      @include breakpoint(large) {
        width: calc(#{xy-cell-size(10)} - #{rem-calc(50)});
        opacity: .65;

        body:not(.menu-hidden) & {
          transform: translateY(rem-calc(75));
          transition:
            transform .35s ease(out-quart),
            opacity .3s ease(out-quint);
        }

        &:hover, #{$keyboard} &:focus {
          opacity: 1;
        }
      }

      @include breakpoint(xlarge) {
        width: calc(#{xy-cell-size(8)} - #{rem-calc(40)});
        max-width: calc((#{$global-width * (8 / 12)}) - #{rem-calc(40)});
      }
    }
  }

  // Bloc player
  .KaliopPlayer-player {
    &:not(:only-child) {
      margin-bottom: rem-calc(30);
    }
  }

  // Bloc infos
  .KaliopPlayer-infos {
    position: relative;
    padding-right: rem-calc(70);

    @include breakpoint(medium down) {
      &::after {
        @include sprite('angle', (fill: map-get($color-main, 'darkblue')));
        content: '';
        position: absolute;
        top: rem-calc(3);
        right: 0;
        width: rem-calc(15);
        height: rem-calc(15);
        transition: transform .3s ease(out-quad);
      }
    }
  }

  .KaliopPlayer-infosTitle {
    margin-bottom: rem-calc(5);
    font-size: rem-calc(18);
    font-weight: 800;
    line-height: 1.2;
    color: map-get($color-main, 'darkblue');

    @include breakpoint(small only) {
      margin-bottom: rem-calc(5);
    }
  }

  .KaliopPlayer-infosDate {
    color: map-get($color-main, 'darkblue');
  }

  .KaliopPlayer-infosDuration {
    position: absolute;
    bottom: rem-calc(0);
    right: 0;
    width: rem-calc(50);
    padding-top: rem-calc(3);
    padding-bottom: rem-calc(3);
    border-radius: rem-calc(30);
    font-size: rem-calc(12);
    text-align: center;
    color: map-get($color-main, 'darkblue');
    background-color: map-get($color-secondary, 'lavander');

    @include breakpoint(large) {
      top: 0;
      bottom: auto;
    }
  }

  // Bloc transcription
  .KaliopPlayer-transcription {
    @extend .Button;
    @extend .Button--secondary;
    @extend .Button--small;
  }

  // Bloc playlist
  .KaliopPlayer-playlistItem {
    position: relative;
    margin-left: rem-calc(5);
    margin-right: rem-calc(5);

    @include breakpoint(large) {
      margin-left: rem-calc(15);
      margin-right: rem-calc(15);
    }

    &:not(:last-child) {
      margin-bottom: rem-calc(30);
    }

    &::before {
      content: '';
      position: absolute;
      top: rem-calc(-15);
      left: rem-calc(-15);
      width: calc(100% + #{rem-calc(30)});
      height: calc(100% + #{rem-calc(30)});
      background-color: transparent;
      border-radius: rem-calc(15);
      transition: background-color .3s ease(out-quint);
    }

    &.is-active {
      &::before {
        background-color: map-get($color-secondary, 'palerose');
      }

      .KaliopPlayer-playlistButtonHeader {
        &::before {
          opacity: 1;
        }

        &::after {
          content: 'Lecture \a en cours';
          width: auto;
          height: auto;
          border-radius: 0;
          background: none !important;
          background-position: left 55% center !important;
          background-color: transparent !important;
          box-shadow: none;
          transition: background 0s ease(out-quint);

          @include breakpoint(large) {
            content: 'Lecture en cours';
            left: rem-calc(20);
            bottom: rem-calc(15);
          }
        }
      }

      .KaliopPlayer-playlistButtonTitle,
      .KaliopPlayer-playlistButtonDate {
        color: map-get($color-main, 'brick');
      }
    }
  }

  .KaliopPlayer-playlistButton {
    position: relative;
    display: flex;
    width: 100%;
    text-align: left;
    transition: background-color .3s ease(out-quint);

    @include breakpoint(large) {
      &:hover, #{$keyboard} &:focus {
        .KaliopPlayer-playlistButtonHeader::after {
          background-color: map-get($color-main, 'brick');
        }
      }
    }
  }

  .KaliopPlayer-playlistButtonHeader {
    position: relative;
    width: rem-calc(110);
    min-width: rem-calc(110);
    height: rem-calc(77);
    margin-right: rem-calc(15);
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to top, rgba(map-get($color-main, 'brick'), 0.7) 20%, transparent);
      opacity: 0;
      transition: opacity .5s ease(out-quint);
    }

    &::after {
      @include sprite('play', (fill: $color-white));
      content: '';
      position: absolute;
      bottom: rem-calc(10);
      left: rem-calc(10);
      width: rem-calc(45);
      height: rem-calc(45);
      color: $color-white;
      font-weight: 700;
      line-height: 1.1;
      white-space: pre-wrap;
      border-radius: 50%;
      background-color: map-get($color-main, 'darkblue');
      background-size: rem-calc(10 10) !important;
      background-position: left 55% center !important;
      box-shadow: rem-calc(0 2 3 0) $shadow-strong;
      transition: background .3s ease(out-quint);
    }

    @include breakpoint(large) {
      position: relative;
      width: rem-calc(275);
      min-width: rem-calc(275);
      height: rem-calc(193);
      margin-right: rem-calc(30);
    }
  }

  .KaliopPlayer-playlistButtonImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    // object-fit polyfill for IE11
    font-family: 'object-fit: cover;';
  }

  .KaliopPlayer-playlistButtonContent {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding-top: rem-calc(3);
  }

  .KaliopPlayer-playlistButtonTitle {
    @extend .h3-style;
    display: block;
    max-width: calc(100% - #{rem-calc(70)});
    margin-bottom: rem-calc(5);
    color: map-get($color-main, 'darkblue');
    text-align: left;
    transition: color .3s ease(out-quint);
  }

  .KaliopPlayer-playlistButtonDuration {
    width: rem-calc(50);
    min-width: rem-calc(50);
    margin-left: rem-calc(20);
    padding-top: rem-calc(3);
    padding-bottom: rem-calc(3);
    border-radius: rem-calc(30);
    font-size: rem-calc(12);
    text-align: center;
    color: map-get($color-main, 'darkblue');
    background-color: rgba(map-get($color-main, 'darkblue'), .15);
    transform: translateY(-2px);
  }

  .KaliopPlayer-playlistButtonDate {
    width: 100%;
    color: rgba(map-get($color-main, 'darkblue'), .7);
    transition: color .3s ease(out-quint);

    @include breakpoint(large) {
      margin-bottom: rem-calc(10);
      padding-bottom: rem-calc(5);
      border-bottom: 2px solid rgba(map-get($color-main, 'darkblue'), .15);
    }
  }

  .KaliopPlayer-playlistButtonDescription {
    width: 100%;
    max-height: rem-calc(125);
    overflow: hidden;

    @include breakpoint(medium down) {
      display: none;
    }
  }
}
