/*! Quiz component */

.Quiz {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 55vh;
  padding: rem-calc(40 40 50);
  transition:
    background-color .5s ease(out-quart),
    box-shadow .5s ease(out-quart);

  @include breakpoint(medium) {
    min-height: rem-calc(350);
    padding: rem-calc(40 80 50);
  }

  @each $color, $value in $color-layer {
    &--#{$color} {
      box-shadow: inset rem-calc(0 0 0 10) $value;

      &.Quiz--start {
        box-shadow: inset rem-calc(0 0 0 30) $value;
        background-color: $value;
      }
    }
  }

  &:not(.Quiz--end) {
    justify-content: center;
  }

  // Specific style for quiz page
  body.quiz & {
    @include breakpoint(large) {
      @include xy-cell(10, $gutter-type: none);
      margin-left: auto;
      margin-right: auto;
    }

    @include breakpoint(xlarge) {
      @include xy-cell(8, $gutter-type: none);
    }
  }
}

.Quiz-header {
  text-align: center;

  img {
    width: auto;
    height: rem-calc(65);
  }

  span {
    display: block;
    margin-top: rem-calc(10);
    font-size: rem-calc(25);
    font-weight: 800;
    color: map-get($color-main, 'darkblue');

    @include breakpoint(medium) {
      font-size: rem-calc(18);
    }
  }
}

.Quiz-start {
  text-align: center;
}

.Quiz-title {
  margin-top: rem-calc(20);
  margin-bottom: rem-calc(40);
  font-size: rem-calc(20);
  font-weight: 800;
  line-height: 1.1;
  color: map-get($color-main, 'darkblue');

  &::after {
    display: none;
  }

  @include breakpoint(medium) {
    font-size: rem-calc(30);
  }
}

.Quiz-startButton {
  @extend .Button;
  @extend .Button--primary;

  @include breakpoint(small only) {
    width: 100%;
  }
}

.Quiz-stepInfos {
  padding-top: rem-calc(45);
  text-align: center;
  color: map-get($color-main, 'darkblue');
}

.Quiz-stepIndicator {
  position: absolute;
  top: rem-calc(40);
  left: 50%;
  transform: translateX(-50%);

  #{$mouse} &:focus,
  #{$touch} &:focus {
    outline: none;
  }

  strong {
    font-weight: 800;
  }
}

.Quiz-stepTitle {
  font-size: rem-calc(20);
  font-weight: 800;
  line-height: 1.1;
  will-change: transform;

  #{$mouse} &:focus,
  #{$touch} &:focus {
    outline: none;
  }
}

// Quiz result
.Quiz-result {
  will-change: transform;

  .Card--primary.Card--inline {
    height: rem-calc(110);

    @include breakpoint(small only) {
      height: rem-calc(90);
    }

    .Card-header {
      width: rem-calc(110);
      min-width: rem-calc(110);

      @include breakpoint(medium) {
        width: rem-calc(130);
      }

      @include breakpoint(large) {
        width: rem-calc(140);
      }
    }

    .Card-image {
      height: rem-calc(110);

      @include breakpoint(small only) {
        height: rem-calc(90);
      }
    }
  }
}

.Quiz-resultInfos {
  text-align: center;
}

.Quiz-resultTitle {
  margin-top: rem-calc(20);
  margin-bottom: rem-calc(20);
  font-size: rem-calc(20);
  font-weight: 800;
  line-height: 1.1;
  color: map-get($color-main, 'darkblue');

  @include breakpoint(medium) {
    font-size: rem-calc(30);
  }
}

.Quiz-resultText {
  margin-top: rem-calc(20);
  margin-bottom: rem-calc(30);
  white-space: break-spaces;
}

.Quiz-resultButton {
  @extend .Button;
  @extend .Button--primary;

  @include breakpoint(small only) {
    width: 100%;
  }
}

.Quiz-resultRelated {
  margin-top: rem-calc(30);
  margin-left: rem-calc(-15);
  margin-right: rem-calc(-15);

  @include breakpoint(medium) {
    margin-left: rem-calc(-40);
    margin-right: rem-calc(-40);
  }

  .Quiz-resultRelatedTitle {
    margin-bottom: rem-calc(15);
  }
}

.Quiz-resultRelatedItem {
  &:not(:last-child) {
    margin-bottom: rem-calc(15);
  }
}


.fade-enter, .fade-leave-to {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity .5s ease(out-quint);
}

.fade-leave-active {
  transition: opacity .35s ease(out-quint);
}

.fade-enter-to, .fade-leave {
  opacity: 1;
}


.slide-enter {
  transform: translateX(10px);
}

.slide-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}

.slide-enter-active {
  transition:
    transform .65s ease(out-quint),
    opacity .65s ease(out-quint);
}

.slide-leave-active {
  transition:
    transform .35s ease(out-quint),
    opacity .35s ease(out-quint);
}

.slide-enter-to, .slide-leave {
  transform: translateX(10);
  opacity: 1;
}


.slide-delay-enter {
  transform: translateX(10px);
  opacity: 0;
}

.slide-delay-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}

.slide-delay-enter-active {
  transition:
    transform .65s ease(out-quint) .35s,
    opacity .65s ease(out-quint) .35s;
}

.slide-delay-leave-active {
  transition:
    transform .35s ease(out-quint),
    opacity .35s ease(out-quint);
}

.slide-delay-enter-to, .slide-delay-leave {
  transform: translateX(10);
  opacity: 1;
}
